'use client'
"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var __decorateClass = (decorators, target, key, kind) => {
  var result2 = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result2 = (kind ? decorator(target, key, result2) : decorator(result2)) || result2;
  if (kind && result2)
    __defProp(target, key, result2);
  return result2;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// src/index.ts
var src_exports = {};
__export(src_exports, {
  AUTH0_API: () => AUTH0_API,
  AWS_KEYS: () => AWS_KEYS,
  CALC_CONST: () => CALC_CONST,
  CATEGORY_CARD_GRID_PROPS: () => CATEGORY_CARD_GRID_PROPS,
  COA_CARD_GRID_PROPS: () => COA_CARD_GRID_PROPS,
  COGNITO_API: () => COGNITO_API,
  COMPANIES_CARD_GRID_PROPS: () => COMPANIES_CARD_GRID_PROPS,
  COMPANY_INFO_CONST: () => COMPANY_INFO_CONST,
  CUSTOMER_DIALOG_CARD_GRID_PROPS: () => CUSTOMER_DIALOG_CARD_GRID_PROPS,
  CUSTOMER_MANAGE_CARD_GRID_PROPS: () => CUSTOMER_MANAGE_CARD_GRID_PROPS,
  DB_CONST: () => DB_CONST,
  FIREBASE_API: () => FIREBASE_API,
  HEADER: () => HEADER,
  ICON: () => ICON,
  INDEX_SCROLL_COM_STYLE: () => INDEX_SCROLL_COM_STYLE,
  INVENTORY_CARD_GRID_PROPS: () => INVENTORY_CARD_GRID_PROPS,
  INVENTORY_HISTORY_CARD_GRID_PROPS: () => INVENTORY_HISTORY_CARD_GRID_PROPS,
  MAP_API: () => MAP_API,
  NAV: () => NAV,
  NAV_CARD_GRID_PROPS: () => NAV_CARD_GRID_PROPS,
  ORDER_HISTORY_CARD_GRID_PROPS: () => ORDER_HISTORY_CARD_GRID_PROPS,
  PATH_AFTER_LOGIN: () => PATH_AFTER_LOGIN,
  PATH_AUTH: () => PATH_AUTH,
  PATH_DASHBOARD: () => PATH_DASHBOARD,
  PATH_PAGE: () => PATH_PAGE,
  PRODUCT_CARD_GRID_PROPS: () => PRODUCT_CARD_GRID_PROPS,
  SUPPLIER_CARD_GRID_PROPS: () => SUPPLIER_CARD_GRID_PROPS,
  SUPPLIER_PRODUCT_MANAGE_CARD_GRID_PROPS: () => SUPPLIER_PRODUCT_MANAGE_CARD_GRID_PROPS,
  VARIANT_CARD_GRID_PROPS: () => VARIANT_CARD_GRID_PROPS,
  asyncDelay: () => asyncDelay,
  bgBlur: () => bgBlur,
  bgGradient: () => bgGradient,
  calculateOrderPrice: () => calculateOrderPrice,
  calculateProfitFromGrossPrice: () => calculateProfitFromGrossPrice,
  calculateReferenceDue: () => calculateReferenceDue,
  calculateTotalDiscountPrice: () => calculateTotalDiscountPrice,
  checkIfServableProduct: () => checkIfServableProduct,
  convertToDBDecimal: () => convertToDBDecimal,
  convertToFEDecimal: () => convertToFEDecimal,
  convertToFEFormDecimal: () => convertToFEFormDecimal,
  convertVatToFEFormat: () => convertVatToFEFormat,
  copyTextToClipboard: () => copyTextToClipboard,
  countryToFlag: () => countryToFlag,
  createDashboardPath: () => createDashboardPath,
  createEmotionCache: () => createEmotionCache,
  ellipsis: () => ellipsis,
  fCurrency: () => fCurrency,
  fData: () => fData,
  fDate: () => fDate,
  fDateTime: () => fDateTime,
  fNumber: () => fNumber,
  fPercent: () => fPercent,
  fShortenNumber: () => fShortenNumber,
  fTimestamp: () => fTimestamp,
  fToNow: () => fToNow,
  filterStyles: () => filterStyles,
  findCountryCode: () => findCountryCode,
  findProductById: () => findProductById,
  findVariantById: () => findVariantById,
  flattenArray: () => flattenArray,
  formatAndFixDecimal: () => formatAndFixDecimal,
  generateAwsS3Url: () => generateAwsS3Url,
  generateCategoryTree: () => generateCategoryTree,
  generateInvoiceUrl: () => generateInvoiceUrl,
  generateRandomId: () => generateRandomId,
  getAllProductVariantImages: () => getAllProductVariantImages,
  getCardColor: () => getCardColor,
  getCookie: () => getCookie,
  getFullCategoriesById: () => getFullCategoriesById,
  getFullImagesByIds: () => getFullImagesByIds,
  getIndexScrollComStyle: () => getIndexScrollComStyle,
  getProductStockQuantity: () => getProductStockQuantity,
  getTypeORMFormattedDateRange: () => getTypeORMFormattedDateRange,
  getVariantStockQuantity: () => getVariantStockQuantity,
  groupPermission: () => groupPermission,
  hideScrollbarX: () => hideScrollbarX,
  hideScrollbarY: () => hideScrollbarY,
  isFloat: () => isFloat,
  isInt: () => isInt,
  isProductVariantStockNotAvailable: () => isProductVariantStockNotAvailable,
  localStorageAvailable: () => localStorageAvailable,
  localizeNumber: () => localizeNumber,
  mapDataToSelect: () => mapDataToSelect,
  mapPeopleDataToSelect: () => mapPeopleDataToSelect,
  mapPeopleDataToSelectSingle: () => mapPeopleDataToSelectSingle,
  path: () => path,
  productVariantStockStatus: () => productVariantStockStatus,
  removeCookie: () => removeCookie,
  separateCategoryId: () => separateCategoryId,
  separateImageId: () => separateImageId,
  setCookie: () => setCookie,
  stockStatusColors: () => stockStatusColors,
  textGradient: () => textGradient,
  toTitleCase: () => toTitleCase,
  transformCountryFE: () => transformCountryFE,
  uuidv4: () => uuidv4
});
module.exports = __toCommonJS(src_exports);

// src/async-delay.ts
var asyncDelay = (ms = 2e3) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

// src/paths.ts
function createDashboardPath(path2) {
  return `${ROOTS_DASHBOARD}${path2}`;
}
function path(root, sublink) {
  return `${root}${sublink}`;
}
var ROOTS_AUTH = "/auth";
var ROOTS_DASHBOARD = "/dashboard";
var PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  logout: path(ROOTS_AUTH, "/logout"),
  register: path(ROOTS_AUTH, "/register"),
  verify: path(ROOTS_AUTH, "/verify"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  newPassword: path(ROOTS_AUTH, "/new-password")
};
var PATH_PAGE = {
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  pricing: "/pricing",
  payment: "/payment",
  about: "/about-us",
  contact: "/contact-us",
  faqs: "/faqs",
  page403: "/403",
  page404: "/404",
  page500: "/500",
  components: "/components"
};
var PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  onboarding: {
    root: path(ROOTS_DASHBOARD, "/onboarding")
  },
  supply: {
    root: path(ROOTS_DASHBOARD, "/supply"),
    checkout: path(ROOTS_DASHBOARD, "/supply/purchase/checkout"),
    history: path(ROOTS_DASHBOARD, "/supply/history"),
    purchase: path(ROOTS_DASHBOARD, "/supply/purchase"),
    suppliers: path(ROOTS_DASHBOARD, "/supply/suppliers")
  },
  products: {
    categories: path(ROOTS_DASHBOARD, "/products/category-management")
  },
  inventory: {
    root: path(ROOTS_DASHBOARD, "/inventory"),
    products: path(ROOTS_DASHBOARD, "/inventory/products"),
    category_management: path(ROOTS_DASHBOARD, "/inventory/category-management"),
    overview: path(ROOTS_DASHBOARD, "/inventory/overview"),
    history: path(ROOTS_DASHBOARD, "/inventory/history")
  },
  counter: {
    root: path(ROOTS_DASHBOARD, "/counter"),
    checkout: path(ROOTS_DASHBOARD, "/counter/checkout")
  },
  reports: {
    root: path(ROOTS_DASHBOARD, "/reports"),
    sale: path(ROOTS_DASHBOARD, "/reports/sale"),
    daily: path(ROOTS_DASHBOARD, "/reports/sale/daily"),
    monthly: path(ROOTS_DASHBOARD, "/reports/sale/monthly"),
    yearly: path(ROOTS_DASHBOARD, "/reports/sale/yearly"),
    profit_and_loss: path(ROOTS_DASHBOARD, "/reports/profit-and-loss"),
    // purchase: path(ROOTS_DASHBOARD, '/reports/purchase'),
    customer_due: path(ROOTS_DASHBOARD, "/reports/customer-due"),
    payable: path(ROOTS_DASHBOARD, "/reports/payable")
  },
  order: {
    root: path(ROOTS_DASHBOARD, "/order"),
    return: path(ROOTS_DASHBOARD, "/order/return"),
    history: path(ROOTS_DASHBOARD, "/order/history"),
    invoice: path(ROOTS_DASHBOARD, "/order/invoice"),
    customers: path(ROOTS_DASHBOARD, "/customer/management")
  },
  permissionDenied: path(ROOTS_DASHBOARD, "/permission-denied"),
  accounting: {
    root: path(ROOTS_DASHBOARD, "/accounting"),
    chart_of_accounts: path(ROOTS_DASHBOARD, "/accounting/chart-of-accounts"),
    register: path(ROOTS_DASHBOARD, "/accounting/register"),
    expense: path(ROOTS_DASHBOARD, "/accounting/expense"),
    drawing: path(ROOTS_DASHBOARD, "/accounting/drawing"),
    transactions: path(ROOTS_DASHBOARD, "/accounting/transactions"),
    cash_transfer: path(ROOTS_DASHBOARD, "/accounting/cash-transfer")
  },
  settings: {
    root: path(ROOTS_DASHBOARD, "/settings"),
    company: path(ROOTS_DASHBOARD, "/settings/companies"),
    user: path(ROOTS_DASHBOARD, "/settings/users"),
    profile: path(ROOTS_DASHBOARD, "/settings/profile"),
    roles: path(ROOTS_DASHBOARD, "/settings/roles"),
    preference: path(ROOTS_DASHBOARD, "/settings/preference"),
    debug: path(ROOTS_DASHBOARD, "/settings/debug"),
    context: path(ROOTS_DASHBOARD, "/settings/context"),
    troubleshooting: path(ROOTS_DASHBOARD, "/settings/troubleshooting"),
    logout: PATH_AUTH.logout
  }
};

// src/config-global.ts
var FIREBASE_API = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APPID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID
};
var AWS_KEYS = {
  awsEndpoint: process.env.NEXT_PUBLIC_AWS_ENDPOINT,
  bucket: process.env.NEXT_PUBLIC_AWS_BUCKET_NAME
};
var COGNITO_API = {
  userPoolId: process.env.AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.AWS_COGNITO_CLIENT_ID
};
var AUTH0_API = {
  clientId: process.env.AUTH0_CLIENT_ID,
  domain: process.env.AUTH0_DOMAIN
};
var MAP_API = process.env.MAPBOX_API;
var PATH_AFTER_LOGIN = PATH_DASHBOARD.counter.root;
var HEADER = {
  H_MOBILE: 0,
  H_MAIN_DESKTOP: 0,
  H_DASHBOARD_DESKTOP: 0,
  H_DASHBOARD_DESKTOP_OFFSET: 0
};
var NAV = {
  W_BASE: 260,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  H_DASHBOARD_ITEM_HORIZONTAL: 32
};
var ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22
};

// src/aws.ts
var generateAwsS3Url = ({ src }) => {
  const { destination, fileName } = src;
  const { awsEndpoint, bucket } = AWS_KEYS;
  return `https://${bucket}.${awsEndpoint}/${destination}/${fileName}`;
};

// src/calc-constants.ts
var CALC_CONST = {
  NUMBER_MULTIPLIER: 100
};

// src/calculate-discount-price.ts
var import_mathjs = require("mathjs");
var calculateTotalDiscountPrice = (items) => {
  return items.reduce((acc, item) => {
    const {
      unitPrice,
      quantity,
      productVariant: { regularSalePrice }
    } = item;
    const calculateTotalDiscount = (0, import_mathjs.chain)(regularSalePrice).subtract(unitPrice).multiply(quantity).add(acc).fix().done();
    return calculateTotalDiscount;
  }, 0);
};

// src/calculate-order-price.ts
var import_mathjs2 = require("mathjs");
var initialValue = {
  orderNetPrice: 0,
  orderGrossPrice: 0,
  orderVat: 0
};
var calculateOrderPrice = (items) => {
  const prices = items.reduce((acc, curr) => {
    const currentItemUnitPrice = (0, import_mathjs2.chain)(curr.unitPrice).add(curr.vatPercent).multiply(curr.quantity).done();
    const currentFullVatPercent = (0, import_mathjs2.chain)(curr.vatPercent).multiply(CALC_CONST.NUMBER_MULTIPLIER).done();
    const priceAndVatPercent = currentFullVatPercent + 100;
    const currentItemVat = (0, import_mathjs2.chain)(currentItemUnitPrice).divide(priceAndVatPercent).multiply(currentFullVatPercent).fix().done();
    const orderNetPrice = (0, import_mathjs2.chain)(acc.orderNetPrice).add(currentItemUnitPrice).subtract(currentItemVat).fix().done();
    const vat = (0, import_mathjs2.chain)(currentItemUnitPrice).subtract(currentItemUnitPrice - currentItemVat).fix().done();
    const orderGrossPrice = (0, import_mathjs2.chain)(acc.orderGrossPrice).add(currentItemUnitPrice).fix().done();
    return {
      orderNetPrice,
      orderGrossPrice,
      orderVat: (0, import_mathjs2.chain)(acc.orderVat).add(vat).fix().done()
    };
  }, initialValue);
  return prices;
};

// src/calculation/profit.ts
var import_mathjs3 = require("mathjs");
var calculateProfitFromGrossPrice = (params) => {
  const { sellPrice: grossSalePrice, purchasePrice, vatPercent } = params;
  const transformedSalePrice = grossSalePrice === "." ? "0." : grossSalePrice;
  const transformedPurchasePrice = purchasePrice === "." ? "0." : purchasePrice;
  const transformedVatPercent = vatPercent === "." ? "0." : vatPercent;
  const grossSalePriceInNumber = Number(transformedSalePrice);
  const purchasePriceInNumber = Number(transformedPurchasePrice);
  const vatPercentInNumber = Number(transformedVatPercent);
  if (isNaN(grossSalePriceInNumber) || isNaN(purchasePriceInNumber) || isNaN(vatPercentInNumber)) {
    throw new Error("Invalid profit calculate input");
  }
  if (vatPercentInNumber < 0 || vatPercentInNumber > 8) {
    throw new Error("Vat percent must be between 0 and 8");
  }
  const vatPercentInFullNumber = (0, import_mathjs3.chain)(vatPercentInNumber).multiply(CALC_CONST.NUMBER_MULTIPLIER).done();
  const netSalePriceEquation = (0, import_mathjs3.parse)("grossSalePrice / (100 + vatPercentInFullNumber) * 100");
  const netSalePriceSimplified = (0, import_mathjs3.simplify)(netSalePriceEquation);
  const netSalePrice = netSalePriceSimplified.evaluate({
    grossSalePrice: grossSalePriceInNumber,
    vatPercentInFullNumber
  });
  const vatAmount = (0, import_mathjs3.chain)(grossSalePriceInNumber).subtract(Number(netSalePrice)).done();
  const profitAmoutAfterCalculationInNumber = (0, import_mathjs3.chain)(netSalePrice).number().subtract(purchasePriceInNumber).fix(2).done();
  const profitPercentage = (0, import_mathjs3.chain)(profitAmoutAfterCalculationInNumber).number().divide(purchasePriceInNumber).multiply(CALC_CONST.NUMBER_MULTIPLIER).fix(2).done();
  return {
    profitAmount: profitAmoutAfterCalculationInNumber,
    profitPercentage,
    vatAmount
  };
};

// src/calculation/reference-due.ts
var calculateReferenceDue = () => {
  return 0;
};

// src/category.ts
var separateCategoryId = (array) => {
  return array == null ? void 0 : array.map((_a) => {
    var _b = _a, { id } = _b, rest = __objRest(_b, ["id"]);
    return id;
  });
};

// src/copy-text-to-clipboard.ts
var copyTextToClipboard = (text) => __async(void 0, null, function* () {
  if ("clipboard" in navigator) {
    return yield navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
});

// src/countries.ts
var countries = [
  { id: "AD", label: "Andorra", phone: "376" },
  {
    id: "AE",
    label: "United Arab Emirates",
    phone: "971"
  },
  { id: "AF", label: "Afghanistan", phone: "93" },
  {
    id: "AG",
    label: "Antigua and Barbuda",
    phone: "1-268"
  },
  { id: "AI", label: "Anguilla", phone: "1-264" },
  { id: "AL", label: "Albania", phone: "355" },
  { id: "AM", label: "Armenia", phone: "374" },
  { id: "AO", label: "Angola", phone: "244" },
  { id: "AQ", label: "Antarctica", phone: "672" },
  { id: "AR", label: "Argentina", phone: "54" },
  { id: "AS", label: "American Samoa", phone: "1-684" },
  { id: "AT", label: "Austria", phone: "43" },
  {
    id: "AU",
    label: "Australia",
    phone: "61"
  },
  { id: "AW", label: "Aruba", phone: "297" },
  { id: "AX", label: "Alland Islands", phone: "358" },
  { id: "AZ", label: "Azerbaijan", phone: "994" },
  {
    id: "BA",
    label: "Bosnia and Herzegovina",
    phone: "387"
  },
  { id: "BB", label: "Barbados", phone: "1-246" },
  { id: "BD", label: "Bangladesh", phone: "880" },
  { id: "BE", label: "Belgium", phone: "32" },
  { id: "BF", label: "Burkina Faso", phone: "226" },
  { id: "BG", label: "Bulgaria", phone: "359" },
  { id: "BH", label: "Bahrain", phone: "973" },
  { id: "BI", label: "Burundi", phone: "257" },
  { id: "BJ", label: "Benin", phone: "229" },
  { id: "BL", label: "Saint Barthelemy", phone: "590" },
  { id: "BM", label: "Bermuda", phone: "1-441" },
  { id: "BN", label: "Brunei Darussalam", phone: "673" },
  { id: "BO", label: "Bolivia", phone: "591" },
  { id: "BR", label: "Brazil", phone: "55" },
  { id: "BS", label: "Bahamas", phone: "1-242" },
  { id: "BT", label: "Bhutan", phone: "975" },
  { id: "BV", label: "Bouvet Island", phone: "47" },
  { id: "BW", label: "Botswana", phone: "267" },
  { id: "BY", label: "Belarus", phone: "375" },
  { id: "BZ", label: "Belize", phone: "501" },
  {
    id: "CA",
    label: "Canada",
    phone: "1"
  },
  {
    id: "CC",
    label: "Cocos (Keeling) Islands",
    phone: "61"
  },
  {
    id: "CD",
    label: "Congo, Democratic Republic of the",
    phone: "243"
  },
  {
    id: "CF",
    label: "Central African Republic",
    phone: "236"
  },
  {
    id: "CG",
    label: "Congo, Republic of the",
    phone: "242"
  },
  { id: "CH", label: "Switzerland", phone: "41" },
  { id: "CI", label: "Cote d'Ivoire", phone: "225" },
  { id: "CK", label: "Cook Islands", phone: "682" },
  { id: "CL", label: "Chile", phone: "56" },
  { id: "CM", label: "Cameroon", phone: "237" },
  { id: "CN", label: "China", phone: "86" },
  { id: "CO", label: "Colombia", phone: "57" },
  { id: "CR", label: "Costa Rica", phone: "506" },
  { id: "CU", label: "Cuba", phone: "53" },
  { id: "CV", label: "Cape Verde", phone: "238" },
  { id: "CW", label: "Curacao", phone: "599" },
  { id: "CX", label: "Christmas Island", phone: "61" },
  { id: "CY", label: "Cyprus", phone: "357" },
  { id: "CZ", label: "Czech Republic", phone: "420" },
  {
    id: "DE",
    label: "Germany",
    phone: "49"
  },
  { id: "DJ", label: "Djibouti", phone: "253" },
  { id: "DK", label: "Denmark", phone: "45" },
  { id: "DM", label: "Dominica", phone: "1-767" },
  {
    id: "DO",
    label: "Dominican Republic",
    phone: "1-809"
  },
  { id: "DZ", label: "Algeria", phone: "213" },
  { id: "EC", label: "Ecuador", phone: "593" },
  { id: "EE", label: "Estonia", phone: "372" },
  { id: "EG", label: "Egypt", phone: "20" },
  { id: "EH", label: "Western Sahara", phone: "212" },
  { id: "ER", label: "Eritrea", phone: "291" },
  { id: "ES", label: "Spain", phone: "34" },
  { id: "ET", label: "Ethiopia", phone: "251" },
  { id: "FI", label: "Finland", phone: "358" },
  { id: "FJ", label: "Fiji", phone: "679" },
  {
    id: "FK",
    label: "Falkland Islands (Malvinas)",
    phone: "500"
  },
  {
    id: "FM",
    label: "Micronesia, Federated States of",
    phone: "691"
  },
  { id: "FO", label: "Faroe Islands", phone: "298" },
  {
    id: "FR",
    label: "France",
    phone: "33"
  },
  { id: "GA", label: "Gabon", phone: "241" },
  { id: "GB", label: "United Kingdom", phone: "44" },
  { id: "GD", label: "Grenada", phone: "1-473" },
  { id: "GE", label: "Georgia", phone: "995" },
  { id: "GF", label: "French Guiana", phone: "594" },
  { id: "GG", label: "Guernsey", phone: "44" },
  { id: "GH", label: "Ghana", phone: "233" },
  { id: "GI", label: "Gibraltar", phone: "350" },
  { id: "GL", label: "Greenland", phone: "299" },
  { id: "GM", label: "Gambia", phone: "220" },
  { id: "GN", label: "Guinea", phone: "224" },
  { id: "GP", label: "Guadeloupe", phone: "590" },
  { id: "GQ", label: "Equatorial Guinea", phone: "240" },
  { id: "GR", label: "Greece", phone: "30" },
  {
    id: "GS",
    label: "South Georgia and the South Sandwich Islands",
    phone: "500"
  },
  { id: "GT", label: "Guatemala", phone: "502" },
  { id: "GU", label: "Guam", phone: "1-671" },
  { id: "GW", label: "Guinea-Bissau", phone: "245" },
  { id: "GY", label: "Guyana", phone: "592" },
  { id: "HK", label: "Hong Kong", phone: "852" },
  {
    id: "HM",
    label: "Heard Island and McDonald Islands",
    phone: "672"
  },
  { id: "HN", label: "Honduras", phone: "504" },
  { id: "HR", label: "Croatia", phone: "385" },
  { id: "HT", label: "Haiti", phone: "509" },
  { id: "HU", label: "Hungary", phone: "36" },
  { id: "ID", label: "Indonesia", phone: "62" },
  { id: "IE", label: "Ireland", phone: "353" },
  { id: "IL", label: "Israel", phone: "972" },
  { id: "IM", label: "Isle of Man", phone: "44" },
  { id: "IN", label: "India", phone: "91" },
  {
    id: "IO",
    label: "British Indian Ocean Territory",
    phone: "246"
  },
  { id: "IQ", label: "Iraq", phone: "964" },
  {
    id: "IR",
    label: "Iran, Islamic Republic of",
    phone: "98"
  },
  { id: "IS", label: "Iceland", phone: "354" },
  { id: "IT", label: "Italy", phone: "39" },
  { id: "JE", label: "Jersey", phone: "44" },
  { id: "JM", label: "Jamaica", phone: "1-876" },
  { id: "JO", label: "Jordan", phone: "962" },
  {
    id: "JP",
    label: "Japan",
    phone: "81"
  },
  { id: "KE", label: "Kenya", phone: "254" },
  { id: "KG", label: "Kyrgyzstan", phone: "996" },
  { id: "KH", label: "Cambodia", phone: "855" },
  { id: "KI", label: "Kiribati", phone: "686" },
  { id: "KM", label: "Comoros", phone: "269" },
  {
    id: "KN",
    label: "Saint Kitts and Nevis",
    phone: "1-869"
  },
  {
    id: "KP",
    label: "Korea, Democratic People's Republic of",
    phone: "850"
  },
  { id: "KR", label: "Korea, Republic of", phone: "82" },
  { id: "KW", label: "Kuwait", phone: "965" },
  { id: "KY", label: "Cayman Islands", phone: "1-345" },
  { id: "KZ", label: "Kazakhstan", phone: "7" },
  {
    id: "LA",
    label: "Lao People's Democratic Republic",
    phone: "856"
  },
  { id: "LB", label: "Lebanon", phone: "961" },
  { id: "LC", label: "Saint Lucia", phone: "1-758" },
  { id: "LI", label: "Liechtenstein", phone: "423" },
  { id: "LK", label: "Sri Lanka", phone: "94" },
  { id: "LR", label: "Liberia", phone: "231" },
  { id: "LS", label: "Lesotho", phone: "266" },
  { id: "LT", label: "Lithuania", phone: "370" },
  { id: "LU", label: "Luxembourg", phone: "352" },
  { id: "LV", label: "Latvia", phone: "371" },
  { id: "LY", label: "Libya", phone: "218" },
  { id: "MA", label: "Morocco", phone: "212" },
  { id: "MC", label: "Monaco", phone: "377" },
  {
    id: "MD",
    label: "Moldova, Republic of",
    phone: "373"
  },
  { id: "ME", label: "Montenegro", phone: "382" },
  {
    id: "MF",
    label: "Saint Martin (French part)",
    phone: "590"
  },
  { id: "MG", label: "Madagascar", phone: "261" },
  { id: "MH", label: "Marshall Islands", phone: "692" },
  {
    id: "MK",
    label: "Macedonia, the Former Yugoslav Republic of",
    phone: "389"
  },
  { id: "ML", label: "Mali", phone: "223" },
  { id: "MM", label: "Myanmar", phone: "95" },
  { id: "MN", label: "Mongolia", phone: "976" },
  { id: "MO", label: "Macao", phone: "853" },
  {
    id: "MP",
    label: "Northern Mariana Islands",
    phone: "1-670"
  },
  { id: "MQ", label: "Martinique", phone: "596" },
  { id: "MR", label: "Mauritania", phone: "222" },
  { id: "MS", label: "Montserrat", phone: "1-664" },
  { id: "MT", label: "Malta", phone: "356" },
  { id: "MU", label: "Mauritius", phone: "230" },
  { id: "MV", label: "Maldives", phone: "960" },
  { id: "MW", label: "Malawi", phone: "265" },
  { id: "MX", label: "Mexico", phone: "52" },
  { id: "MY", label: "Malaysia", phone: "60" },
  { id: "MZ", label: "Mozambique", phone: "258" },
  { id: "NA", label: "Namibia", phone: "264" },
  { id: "NC", label: "New Caledonia", phone: "687" },
  { id: "NE", label: "Niger", phone: "227" },
  { id: "NF", label: "Norfolk Island", phone: "672" },
  { id: "NG", label: "Nigeria", phone: "234" },
  { id: "NI", label: "Nicaragua", phone: "505" },
  { id: "NL", label: "Netherlands", phone: "31" },
  { id: "NO", label: "Norway", phone: "47" },
  { id: "NP", label: "Nepal", phone: "977" },
  { id: "NR", label: "Nauru", phone: "674" },
  { id: "NU", label: "Niue", phone: "683" },
  { id: "NZ", label: "New Zealand", phone: "64" },
  { id: "OM", label: "Oman", phone: "968" },
  { id: "PA", label: "Panama", phone: "507" },
  { id: "PE", label: "Peru", phone: "51" },
  { id: "PF", label: "French Polynesia", phone: "689" },
  { id: "PG", label: "Papua New Guinea", phone: "675" },
  { id: "PH", label: "Philippines", phone: "63" },
  { id: "PK", label: "Pakistan", phone: "92" },
  { id: "PL", label: "Poland", phone: "48" },
  {
    id: "PM",
    label: "Saint Pierre and Miquelon",
    phone: "508"
  },
  { id: "PN", label: "Pitcairn", phone: "870" },
  { id: "PR", label: "Puerto Rico", phone: "1" },
  {
    id: "PS",
    label: "Palestine, State of",
    phone: "970"
  },
  { id: "PT", label: "Portugal", phone: "351" },
  { id: "PW", label: "Palau", phone: "680" },
  { id: "PY", label: "Paraguay", phone: "595" },
  { id: "QA", label: "Qatar", phone: "974" },
  { id: "RE", label: "Reunion", phone: "262" },
  { id: "RO", label: "Romania", phone: "40" },
  { id: "RS", label: "Serbia", phone: "381" },
  { id: "RU", label: "Russian Federation", phone: "7" },
  { id: "RW", label: "Rwanda", phone: "250" },
  { id: "SA", label: "Saudi Arabia", phone: "966" },
  { id: "SB", label: "Solomon Islands", phone: "677" },
  { id: "SC", label: "Seychelles", phone: "248" },
  { id: "SD", label: "Sudan", phone: "249" },
  { id: "SE", label: "Sweden", phone: "46" },
  { id: "SG", label: "Singapore", phone: "65" },
  { id: "SH", label: "Saint Helena", phone: "290" },
  { id: "SI", label: "Slovenia", phone: "386" },
  {
    id: "SJ",
    label: "Svalbard and Jan Mayen",
    phone: "47"
  },
  { id: "SK", label: "Slovakia", phone: "421" },
  { id: "SL", label: "Sierra Leone", phone: "232" },
  { id: "SM", label: "San Marino", phone: "378" },
  { id: "SN", label: "Senegal", phone: "221" },
  { id: "SO", label: "Somalia", phone: "252" },
  { id: "SR", label: "Suriname", phone: "597" },
  { id: "SS", label: "South Sudan", phone: "211" },
  {
    id: "ST",
    label: "Sao Tome and Principe",
    phone: "239"
  },
  { id: "SV", label: "El Salvador", phone: "503" },
  {
    id: "SX",
    label: "Sint Maarten (Dutch part)",
    phone: "1-721"
  },
  {
    id: "SY",
    label: "Syrian Arab Republic",
    phone: "963"
  },
  { id: "SZ", label: "Swaziland", phone: "268" },
  {
    id: "TC",
    label: "Turks and Caicos Islands",
    phone: "1-649"
  },
  { id: "TD", label: "Chad", phone: "235" },
  {
    id: "TF",
    label: "French Southern Territories",
    phone: "262"
  },
  { id: "TG", label: "Togo", phone: "228" },
  { id: "TH", label: "Thailand", phone: "66" },
  { id: "TJ", label: "Tajikistan", phone: "992" },
  { id: "TK", label: "Tokelau", phone: "690" },
  { id: "TL", label: "Timor-Leste", phone: "670" },
  { id: "TM", label: "Turkmenistan", phone: "993" },
  { id: "TN", label: "Tunisia", phone: "216" },
  { id: "TO", label: "Tonga", phone: "676" },
  { id: "TR", label: "Turkey", phone: "90" },
  {
    id: "TT",
    label: "Trinidad and Tobago",
    phone: "1-868"
  },
  { id: "TV", label: "Tuvalu", phone: "688" },
  {
    id: "TW",
    label: "Taiwan, Province of China",
    phone: "886"
  },
  {
    id: "TZ",
    label: "United Republic of Tanzania",
    phone: "255"
  },
  { id: "UA", label: "Ukraine", phone: "380" },
  { id: "UG", label: "Uganda", phone: "256" },
  {
    id: "US",
    label: "United States",
    phone: "1"
  },
  { id: "UY", label: "Uruguay", phone: "598" },
  { id: "UZ", label: "Uzbekistan", phone: "998" },
  {
    id: "VA",
    label: "Holy See (Vatican City State)",
    phone: "379"
  },
  {
    id: "VC",
    label: "Saint Vincent and the Grenadines",
    phone: "1-784"
  },
  { id: "VE", label: "Venezuela", phone: "58" },
  {
    id: "VG",
    label: "British Virgin Islands",
    phone: "1-284"
  },
  {
    id: "VI",
    label: "US Virgin Islands",
    phone: "1-340"
  },
  { id: "VN", label: "Vietnam", phone: "84" },
  { id: "VU", label: "Vanuatu", phone: "678" },
  { id: "WF", label: "Wallis and Futuna", phone: "681" },
  { id: "WS", label: "Samoa", phone: "685" },
  { id: "XK", label: "Kosovo", phone: "383" },
  { id: "YE", label: "Yemen", phone: "967" },
  { id: "YT", label: "Mayotte", phone: "262" },
  { id: "ZA", label: "South Africa", phone: "27" },
  { id: "ZM", label: "Zambia", phone: "260" },
  { id: "ZW", label: "Zimbabwe", phone: "263" }
];

// src/countryToFlag.ts
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined" ? isoCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397)) : isoCode;
}
var transformCountryFE = (country) => {
  return {
    value: country.id,
    label: country.title,
    phone: country.phone
  };
};
var findCountryCode = (country) => {
  const found = countries.find((c) => country === c.label);
  if (!found) {
    return "";
  }
  return found.id;
};

// src/createEmotionCache.ts
var import_cache = __toESM(require("@emotion/cache"));
var isBrowser = typeof document !== "undefined";
function createEmotionCache() {
  let insertionPoint;
  if (isBrowser) {
    const emotionInsertionPoint = document.querySelector('meta[name="emotion-insertion-point"]');
    insertionPoint = emotionInsertionPoint != null ? emotionInsertionPoint : void 0;
  }
  return (0, import_cache.default)({ key: "css", insertionPoint });
}

// ../../node_modules/@mui/utils/esm/formatMuiErrorMessage.js
function formatMuiErrorMessage(code) {
  let url = "https://mui.com/production-error/?code=" + code;
  for (let i = 1; i < arguments.length; i += 1) {
    url += "&args[]=" + encodeURIComponent(arguments[i]);
  }
  return "Minified MUI error #" + code + "; visit " + url + " for the full message.";
}

// ../../node_modules/@mui/system/esm/colorManipulator.js
function clamp(value, min = 0, max = 1) {
  if (process.env.NODE_ENV !== "production") {
    if (value < min || value > max) {
      console.error(`MUI: The value provided ${value} is out of range [${min}, ${max}].`);
    }
  }
  return Math.min(Math.max(min, value), max);
}
function hexToRgb(color) {
  color = color.slice(1);
  const re = new RegExp(`.{1,${color.length >= 6 ? 2 : 1}}`, "g");
  let colors = color.match(re);
  if (colors && colors[0].length === 1) {
    colors = colors.map((n) => n + n);
  }
  return colors ? `rgb${colors.length === 4 ? "a" : ""}(${colors.map((n, index) => {
    return index < 3 ? parseInt(n, 16) : Math.round(parseInt(n, 16) / 255 * 1e3) / 1e3;
  }).join(", ")})` : "";
}
function decomposeColor(color) {
  if (color.type) {
    return color;
  }
  if (color.charAt(0) === "#") {
    return decomposeColor(hexToRgb(color));
  }
  const marker = color.indexOf("(");
  const type = color.substring(0, marker);
  if (["rgb", "rgba", "hsl", "hsla", "color"].indexOf(type) === -1) {
    throw new Error(process.env.NODE_ENV !== "production" ? `MUI: Unsupported \`${color}\` color.
The following formats are supported: #nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla(), color().` : formatMuiErrorMessage(9, color));
  }
  let values = color.substring(marker + 1, color.length - 1);
  let colorSpace;
  if (type === "color") {
    values = values.split(" ");
    colorSpace = values.shift();
    if (values.length === 4 && values[3].charAt(0) === "/") {
      values[3] = values[3].slice(1);
    }
    if (["srgb", "display-p3", "a98-rgb", "prophoto-rgb", "rec-2020"].indexOf(colorSpace) === -1) {
      throw new Error(process.env.NODE_ENV !== "production" ? `MUI: unsupported \`${colorSpace}\` color space.
The following color spaces are supported: srgb, display-p3, a98-rgb, prophoto-rgb, rec-2020.` : formatMuiErrorMessage(10, colorSpace));
    }
  } else {
    values = values.split(",");
  }
  values = values.map((value) => parseFloat(value));
  return {
    type,
    values,
    colorSpace
  };
}
function recomposeColor(color) {
  const {
    type,
    colorSpace
  } = color;
  let {
    values
  } = color;
  if (type.indexOf("rgb") !== -1) {
    values = values.map((n, i) => i < 3 ? parseInt(n, 10) : n);
  } else if (type.indexOf("hsl") !== -1) {
    values[1] = `${values[1]}%`;
    values[2] = `${values[2]}%`;
  }
  if (type.indexOf("color") !== -1) {
    values = `${colorSpace} ${values.join(" ")}`;
  } else {
    values = `${values.join(", ")}`;
  }
  return `${type}(${values})`;
}
function alpha(color, value) {
  color = decomposeColor(color);
  value = clamp(value);
  if (color.type === "rgb" || color.type === "hsl") {
    color.type += "a";
  }
  if (color.type === "color") {
    color.values[3] = `/${value}`;
  } else {
    color.values[3] = value;
  }
  return recomposeColor(color);
}

// src/cssStyles.ts
function bgBlur(props) {
  const color = (props == null ? void 0 : props.color) || "#000000";
  const blur = (props == null ? void 0 : props.blur) || 6;
  const opacity = (props == null ? void 0 : props.opacity) || 0.8;
  const imgUrl = props == null ? void 0 : props.imgUrl;
  if (imgUrl) {
    return {
      position: "relative",
      backgroundImage: `url(${imgUrl})`,
      "&:before": {
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 9,
        content: '""',
        width: "100%",
        height: "100%",
        backdropFilter: `blur(${blur}px)`,
        WebkitBackdropFilter: `blur(${blur}px)`,
        backgroundColor: alpha(color, opacity)
      }
    };
  }
  return {
    backdropFilter: `blur(${blur}px)`,
    WebkitBackdropFilter: `blur(${blur}px)`,
    backgroundColor: alpha(color, opacity)
  };
}
function bgGradient(props) {
  const direction = (props == null ? void 0 : props.direction) || "to bottom";
  const startColor = props == null ? void 0 : props.startColor;
  const endColor = props == null ? void 0 : props.endColor;
  const imgUrl = props == null ? void 0 : props.imgUrl;
  const color = props == null ? void 0 : props.color;
  if (imgUrl) {
    return {
      background: `linear-gradient(${direction}, ${startColor || color}, ${endColor || color}), url(${imgUrl})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center"
    };
  }
  return {
    background: `linear-gradient(${direction}, ${startColor}, ${endColor})`
  };
}
function textGradient(value) {
  return {
    background: `-webkit-linear-gradient(${value})`,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent"
  };
}
function filterStyles(value) {
  return {
    filter: value,
    WebkitFilter: value,
    MozFilter: value
  };
}
var hideScrollbarY = {
  msOverflowStyle: "none",
  scrollbarWidth: "none",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    display: "none"
  }
};
var hideScrollbarX = {
  msOverflowStyle: "none",
  scrollbarWidth: "none",
  overflowX: "scroll",
  "&::-webkit-scrollbar": {
    display: "none"
  }
};

// src/db-constants.ts
var DB_CONST = {
  GENERAL_TEXT_VALIDATION: {
    type: "text",
    width: 255
  },
  GENERAL_NUMBER_COLUMN: { type: "decimal", precision: 10 },
  DECIMAL_NUMBER_COLUMN: { type: "decimal", precision: 10, scale: 2, default: 0 },
  NUMBER_MULTIPLIER: 100
};

// src/design-constant.ts
var INDEX_SCROLL_COM_STYLE = {
  height: `calc(100dvh - 88px)`
};
var getIndexScrollComStyle = (height) => ({
  height: `calc(${height} - 88px)`
});
var ellipsis = {
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis"
};
var NAV_CARD_GRID_PROPS = {
  xs: 6,
  sm: 6,
  md: 3,
  item: true
};
var COA_CARD_GRID_PROPS = {
  xs: 12,
  sm: 4,
  md: 4,
  lg: 3,
  xl: 3,
  item: true
};
var INVENTORY_CARD_GRID_PROPS = {
  xs: 12,
  sm: 6,
  md: 4,
  item: true
};
var CATEGORY_CARD_GRID_PROPS = {
  xs: 12,
  sm: 6,
  md: 3,
  item: true
};
var PRODUCT_CARD_GRID_PROPS = {
  xs: 12,
  sm: 6,
  md: 4,
  lg: 3,
  item: true
};
var INVENTORY_HISTORY_CARD_GRID_PROPS = {
  xs: 12,
  sm: 12,
  md: 6,
  lg: 4,
  item: true
};
var ORDER_HISTORY_CARD_GRID_PROPS = {
  xs: 12,
  sm: 6,
  md: 6,
  lg: 4,
  item: true
};
var CUSTOMER_MANAGE_CARD_GRID_PROPS = {
  xs: 12,
  sm: 4,
  md: 3,
  lg: 2,
  item: true
};
var CUSTOMER_DIALOG_CARD_GRID_PROPS = {
  xs: 12,
  sm: 4,
  md: 4,
  lg: 3,
  item: true
};
var SUPPLIER_PRODUCT_MANAGE_CARD_GRID_PROPS = {
  xs: 12,
  sm: 4,
  md: 4,
  item: true
};
var SUPPLIER_CARD_GRID_PROPS = {
  xs: 12,
  sm: 4,
  md: 4,
  item: true
};
var VARIANT_CARD_GRID_PROPS = {
  xs: 12,
  sm: 4,
  md: 4,
  item: true
};
var COMPANIES_CARD_GRID_PROPS = {
  xs: 12,
  sm: 12,
  md: 6,
  lg: 4,
  item: true
};

// src/flattenArray.ts
function flattenArray(list, key = "children") {
  let children = [];
  const flatten = list == null ? void 0 : list.map((item) => {
    if (item[key] && item[key].length) {
      children = [...children, ...item[key]];
    }
    return item;
  });
  return flatten == null ? void 0 : flatten.concat(children.length ? flattenArray(children, key) : children);
}

// src/formatNumber.ts
var import_numeral = __toESM(require("numeral"));
var import_mathjs4 = require("mathjs");
function fNumber(number) {
  return (0, import_numeral.default)(number).format();
}
function fCurrency(number) {
  const format2 = number ? (0, import_numeral.default)(number).format("$0,0.00") : "";
  return result(format2, ".00");
}
function fPercent(number) {
  const format2 = number ? (0, import_numeral.default)(Number(number) / 100).format("0.0%") : "";
  return result(format2, ".0");
}
function fShortenNumber(number) {
  const format2 = number ? (0, import_numeral.default)(number).format("0.00a") : "";
  return result(format2, ".00");
}
function fData(number) {
  const format2 = number ? (0, import_numeral.default)(number).format("0.0 b") : "";
  return result(format2, ".0");
}
function result(format2, key = ".00") {
  const isInteger = format2.includes(key);
  return isInteger ? format2.replace(key, "") : format2;
}
var convertToDBDecimal = (number) => {
  const numberInNumber = Number(number);
  if (isNaN(numberInNumber)) {
    throw new Error("Invalid input");
  }
  return (0, import_mathjs4.chain)(numberInNumber).multiply(CALC_CONST.NUMBER_MULTIPLIER).fix().done();
};
var convertToFEFormDecimal = (number) => {
  if (!number)
    return 0;
  return number / CALC_CONST.NUMBER_MULTIPLIER;
};
var convertToFEDecimal = (number) => {
  if (!number)
    return "0";
  return (number / CALC_CONST.NUMBER_MULTIPLIER).toFixed(2);
};
var convertVatToFEFormat = (number) => {
  return (0, import_mathjs4.chain)(Number(number)).multiply(CALC_CONST.NUMBER_MULTIPLIER).fix().done();
};
var formatAndFixDecimal = (inputNumber) => {
  const converted = Number(inputNumber);
  if (isNaN(converted)) {
    throw new Error("Invalid input");
  }
  return (0, import_mathjs4.chain)(converted).fix(2).done();
};
var localizeNumber = (param) => {
  const { number, locale = "en-US" } = param;
  return Intl.NumberFormat(locale).format(number);
};

// src/formatTime.ts
var import_date_fns = require("date-fns");
function fDate(date, newFormat) {
  const fm = newFormat || "dd MMM yyyy";
  return date ? (0, import_date_fns.format)(new Date(date), fm) : "";
}
function fDateTime(date, newFormat) {
  const fm = newFormat || "dd MMM yyyy p";
  return date ? (0, import_date_fns.format)(new Date(date), fm) : "";
}
function fTimestamp(date) {
  return date ? (0, import_date_fns.getTime)(new Date(date)) : "";
}
function fToNow(date) {
  return date ? (0, import_date_fns.formatDistanceToNow)(new Date(date), {
    addSuffix: true
  }) : "";
}

// src/generate-invoice-url.ts
var generateInvoiceUrl = (id) => {
  return `${window.origin}${PATH_DASHBOARD.order.invoice}/${id}`;
};

// src/generate-random-id-by-company.ts
var generateRandomId = (uuid) => {
  const prefix = uuid.slice(33, 36).toUpperCase();
  const randomBase = Math.round(Math.random() * Date.now()).toString();
  const rand1 = randomBase.slice(0, 4);
  const rand2 = randomBase.slice(2, 6);
  const rand3 = randomBase.slice(4, 8);
  return `${prefix}-${rand1}-${rand2}-${rand3}`;
};

// src/generateCategoryTree.ts
function generateCategoryTree(flatArray) {
  const map = {};
  const hierarchy = [];
  flatArray.forEach((category) => {
    category.children = [];
    map[category.id] = category;
  });
  flatArray.forEach((category) => {
    const parent = map[category.parentId || ""];
    if (parent == null ? void 0 : parent.children) {
      parent.children.push(category);
    } else {
      hierarchy.push(category);
    }
  });
  return addLevelProperty(hierarchy);
}
function addLevelProperty(categories, level = 0) {
  return categories.map((category) => {
    const updatedCategory = __spreadProps(__spreadValues({}, category), { level });
    if ((category == null ? void 0 : category.children) && category.children.length > 0) {
      updatedCategory.children = addLevelProperty(category == null ? void 0 : category.children, level + 1);
    }
    return updatedCategory;
  });
}

// src/get-all-product-variant-images.ts
var getAllProductVariantImages = (variants) => {
  const allImages = variants.reduce((acc, variant) => {
    if (variant.images.length) {
      acc.push(
        ...variant.images.map((image) => __spreadProps(__spreadValues({}, image), {
          thumbnailBgMode: variant == null ? void 0 : variant.thumbnailBgMode,
          src: generateAwsS3Url({ src: image })
        }))
      );
    }
    return acc;
  }, []);
  return allImages;
};

// src/getCardColor.ts
function getCardColor(index) {
  const colors = ["#659dcc", "#91AABE", "#b4cdd1", "#B0DDE4", "#E8DFE0", "#8D9B6A", "#6DAFFE", "#AECF80"];
  const colorCode = colors[index] || colors[index % colors.length];
  return colorCode;
}

// src/group-permissions.ts
var groupPermission = (permissions) => {
  return permissions.reduce((acc, current) => {
    const entityFound = acc.find((a) => a.entity === current.entity);
    if (!entityFound) {
      return [
        ...acc,
        {
          entity: current.entity,
          actions: [
            {
              id: current.id,
              type: current.action,
              description: current.description
            }
          ]
        }
      ];
    }
    acc = acc.map((sp) => {
      if (sp.entity === current.entity) {
        sp.actions = [
          ...sp.actions,
          {
            id: current.id,
            type: current.action,
            description: current.description
          }
        ];
      }
      return sp;
    });
    return acc;
  }, []);
};

// src/handle-cookie.ts
function getCookie(name) {
  if (typeof document === "undefined") {
    throw new Error(
      "getCookie() is not supported on the server. Fallback to a different value when rendering on the server."
    );
  }
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts[1].split(";").shift();
  }
  return void 0;
}
function setCookie(name, value, exdays = 3) {
  const date = /* @__PURE__ */ new Date();
  date.setTime(date.getTime() + exdays * 24 * 60 * 60 * 1e3);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
}
function removeCookie(name) {
  document.cookie = `${name}=;path=/;max-age=0`;
}

// src/info-constants.ts
var COMPANY_INFO_CONST = {
  SUPPORT_EMAIL: "support@panshilab.com",
  GET_PANSHILAB_DOMAIN: () => window.origin
};

// src/is-product-variant-stock-not-available.ts
var isProductVariantStockNotAvailable = (params) => {
  const { productId, productVariantId, cart, stock } = params;
  const cartItemId = `${productId}::${productVariantId}`;
  const cartItem = cart.find((item) => item.id === cartItemId);
  if (cartItem && cartItem.quantity >= stock || stock <= 0) {
    return true;
  }
  return false;
};

// src/localStorageAvailable.ts
function localStorageAvailable() {
  try {
    const key = "__some_random_key_you_are_not_going_to_use__";
    window.localStorage.setItem(key, key);
    window.localStorage.removeItem(key);
    return true;
  } catch (err) {
    return false;
  }
}

// src/mapPeopleToSelect.ts
var mapPeopleDataToSelect = (inputArray) => {
  return inputArray.map((item) => {
    var _a;
    return {
      label: `${(_a = item.firstName) != null ? _a : ""} ${item.lastName}`,
      value: item.id
    };
  });
};
var mapPeopleDataToSelectSingle = (inputArray) => {
  var _a;
  return {
    label: `${(_a = inputArray.firstName) != null ? _a : ""} ${inputArray.lastName}`,
    value: inputArray.id
  };
};

// src/mapToSelect.ts
var mapDataToSelect = (inputArray) => {
  return inputArray.map((item) => ({
    label: item.title,
    value: item.id
  }));
};

// src/number-type.ts
function isInt(n) {
  return Number(n) === n && n % 1 === 0;
}
function isFloat(n) {
  return Number(n) === n && n % 1 !== 0;
}

// src/product.ts
var findProductById = (props) => {
  const { productId, products } = props;
  const product = products.find((product2) => product2.id === productId);
  return product;
};
var findVariantById = (props) => {
  const { id, variants } = props;
  const variant = variants.find((variant2) => variant2.id === id);
  return variant;
};
var separateImageId = (imageArray) => {
  const imageIds = imageArray == null ? void 0 : imageArray.map(({ id }) => {
    return { id };
  });
  return imageIds;
};
var getFullCategoriesById = ({ arrayRes, ids }) => {
  return ids.map(({ id }) => arrayRes.find((item) => item.id === id));
};
var getFullImagesByIds = ({ arrayRes, ids }) => {
  return ids.map(({ id }) => arrayRes.find((item) => item.id === id));
};

// ../../node_modules/zod/lib/index.mjs
var util;
(function(util2) {
  util2.assertEqual = (val) => val;
  function assertIs(_arg) {
  }
  util2.assertIs = assertIs;
  function assertNever(_x) {
    throw new Error();
  }
  util2.assertNever = assertNever;
  util2.arrayToEnum = (items) => {
    const obj = {};
    for (const item of items) {
      obj[item] = item;
    }
    return obj;
  };
  util2.getValidEnumValues = (obj) => {
    const validKeys = util2.objectKeys(obj).filter((k) => typeof obj[obj[k]] !== "number");
    const filtered = {};
    for (const k of validKeys) {
      filtered[k] = obj[k];
    }
    return util2.objectValues(filtered);
  };
  util2.objectValues = (obj) => {
    return util2.objectKeys(obj).map(function(e) {
      return obj[e];
    });
  };
  util2.objectKeys = typeof Object.keys === "function" ? (obj) => Object.keys(obj) : (object) => {
    const keys = [];
    for (const key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        keys.push(key);
      }
    }
    return keys;
  };
  util2.find = (arr, checker) => {
    for (const item of arr) {
      if (checker(item))
        return item;
    }
    return void 0;
  };
  util2.isInteger = typeof Number.isInteger === "function" ? (val) => Number.isInteger(val) : (val) => typeof val === "number" && isFinite(val) && Math.floor(val) === val;
  function joinValues(array, separator = " | ") {
    return array.map((val) => typeof val === "string" ? `'${val}'` : val).join(separator);
  }
  util2.joinValues = joinValues;
  util2.jsonStringifyReplacer = (_, value) => {
    if (typeof value === "bigint") {
      return value.toString();
    }
    return value;
  };
})(util || (util = {}));
var objectUtil;
(function(objectUtil2) {
  objectUtil2.mergeShapes = (first, second) => {
    return __spreadValues(__spreadValues({}, first), second);
  };
})(objectUtil || (objectUtil = {}));
var ZodParsedType = util.arrayToEnum([
  "string",
  "nan",
  "number",
  "integer",
  "float",
  "boolean",
  "date",
  "bigint",
  "symbol",
  "function",
  "undefined",
  "null",
  "array",
  "object",
  "unknown",
  "promise",
  "void",
  "never",
  "map",
  "set"
]);
var getParsedType = (data) => {
  const t = typeof data;
  switch (t) {
    case "undefined":
      return ZodParsedType.undefined;
    case "string":
      return ZodParsedType.string;
    case "number":
      return isNaN(data) ? ZodParsedType.nan : ZodParsedType.number;
    case "boolean":
      return ZodParsedType.boolean;
    case "function":
      return ZodParsedType.function;
    case "bigint":
      return ZodParsedType.bigint;
    case "symbol":
      return ZodParsedType.symbol;
    case "object":
      if (Array.isArray(data)) {
        return ZodParsedType.array;
      }
      if (data === null) {
        return ZodParsedType.null;
      }
      if (data.then && typeof data.then === "function" && data.catch && typeof data.catch === "function") {
        return ZodParsedType.promise;
      }
      if (typeof Map !== "undefined" && data instanceof Map) {
        return ZodParsedType.map;
      }
      if (typeof Set !== "undefined" && data instanceof Set) {
        return ZodParsedType.set;
      }
      if (typeof Date !== "undefined" && data instanceof Date) {
        return ZodParsedType.date;
      }
      return ZodParsedType.object;
    default:
      return ZodParsedType.unknown;
  }
};
var ZodIssueCode = util.arrayToEnum([
  "invalid_type",
  "invalid_literal",
  "custom",
  "invalid_union",
  "invalid_union_discriminator",
  "invalid_enum_value",
  "unrecognized_keys",
  "invalid_arguments",
  "invalid_return_type",
  "invalid_date",
  "invalid_string",
  "too_small",
  "too_big",
  "invalid_intersection_types",
  "not_multiple_of",
  "not_finite"
]);
var quotelessJson = (obj) => {
  const json = JSON.stringify(obj, null, 2);
  return json.replace(/"([^"]+)":/g, "$1:");
};
var ZodError = class extends Error {
  constructor(issues) {
    super();
    this.issues = [];
    this.addIssue = (sub) => {
      this.issues = [...this.issues, sub];
    };
    this.addIssues = (subs = []) => {
      this.issues = [...this.issues, ...subs];
    };
    const actualProto = new.target.prototype;
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(this, actualProto);
    } else {
      this.__proto__ = actualProto;
    }
    this.name = "ZodError";
    this.issues = issues;
  }
  get errors() {
    return this.issues;
  }
  format(_mapper) {
    const mapper = _mapper || function(issue) {
      return issue.message;
    };
    const fieldErrors = { _errors: [] };
    const processError = (error) => {
      for (const issue of error.issues) {
        if (issue.code === "invalid_union") {
          issue.unionErrors.map(processError);
        } else if (issue.code === "invalid_return_type") {
          processError(issue.returnTypeError);
        } else if (issue.code === "invalid_arguments") {
          processError(issue.argumentsError);
        } else if (issue.path.length === 0) {
          fieldErrors._errors.push(mapper(issue));
        } else {
          let curr = fieldErrors;
          let i = 0;
          while (i < issue.path.length) {
            const el = issue.path[i];
            const terminal = i === issue.path.length - 1;
            if (!terminal) {
              curr[el] = curr[el] || { _errors: [] };
            } else {
              curr[el] = curr[el] || { _errors: [] };
              curr[el]._errors.push(mapper(issue));
            }
            curr = curr[el];
            i++;
          }
        }
      }
    };
    processError(this);
    return fieldErrors;
  }
  toString() {
    return this.message;
  }
  get message() {
    return JSON.stringify(this.issues, util.jsonStringifyReplacer, 2);
  }
  get isEmpty() {
    return this.issues.length === 0;
  }
  flatten(mapper = (issue) => issue.message) {
    const fieldErrors = {};
    const formErrors = [];
    for (const sub of this.issues) {
      if (sub.path.length > 0) {
        fieldErrors[sub.path[0]] = fieldErrors[sub.path[0]] || [];
        fieldErrors[sub.path[0]].push(mapper(sub));
      } else {
        formErrors.push(mapper(sub));
      }
    }
    return { formErrors, fieldErrors };
  }
  get formErrors() {
    return this.flatten();
  }
};
ZodError.create = (issues) => {
  const error = new ZodError(issues);
  return error;
};
var errorMap = (issue, _ctx) => {
  let message;
  switch (issue.code) {
    case ZodIssueCode.invalid_type:
      if (issue.received === ZodParsedType.undefined) {
        message = "Required";
      } else {
        message = `Expected ${issue.expected}, received ${issue.received}`;
      }
      break;
    case ZodIssueCode.invalid_literal:
      message = `Invalid literal value, expected ${JSON.stringify(issue.expected, util.jsonStringifyReplacer)}`;
      break;
    case ZodIssueCode.unrecognized_keys:
      message = `Unrecognized key(s) in object: ${util.joinValues(issue.keys, ", ")}`;
      break;
    case ZodIssueCode.invalid_union:
      message = `Invalid input`;
      break;
    case ZodIssueCode.invalid_union_discriminator:
      message = `Invalid discriminator value. Expected ${util.joinValues(issue.options)}`;
      break;
    case ZodIssueCode.invalid_enum_value:
      message = `Invalid enum value. Expected ${util.joinValues(issue.options)}, received '${issue.received}'`;
      break;
    case ZodIssueCode.invalid_arguments:
      message = `Invalid function arguments`;
      break;
    case ZodIssueCode.invalid_return_type:
      message = `Invalid function return type`;
      break;
    case ZodIssueCode.invalid_date:
      message = `Invalid date`;
      break;
    case ZodIssueCode.invalid_string:
      if (typeof issue.validation === "object") {
        if ("includes" in issue.validation) {
          message = `Invalid input: must include "${issue.validation.includes}"`;
          if (typeof issue.validation.position === "number") {
            message = `${message} at one or more positions greater than or equal to ${issue.validation.position}`;
          }
        } else if ("startsWith" in issue.validation) {
          message = `Invalid input: must start with "${issue.validation.startsWith}"`;
        } else if ("endsWith" in issue.validation) {
          message = `Invalid input: must end with "${issue.validation.endsWith}"`;
        } else {
          util.assertNever(issue.validation);
        }
      } else if (issue.validation !== "regex") {
        message = `Invalid ${issue.validation}`;
      } else {
        message = "Invalid";
      }
      break;
    case ZodIssueCode.too_small:
      if (issue.type === "array")
        message = `Array must contain ${issue.exact ? "exactly" : issue.inclusive ? `at least` : `more than`} ${issue.minimum} element(s)`;
      else if (issue.type === "string")
        message = `String must contain ${issue.exact ? "exactly" : issue.inclusive ? `at least` : `over`} ${issue.minimum} character(s)`;
      else if (issue.type === "number")
        message = `Number must be ${issue.exact ? `exactly equal to ` : issue.inclusive ? `greater than or equal to ` : `greater than `}${issue.minimum}`;
      else if (issue.type === "date")
        message = `Date must be ${issue.exact ? `exactly equal to ` : issue.inclusive ? `greater than or equal to ` : `greater than `}${new Date(Number(issue.minimum))}`;
      else
        message = "Invalid input";
      break;
    case ZodIssueCode.too_big:
      if (issue.type === "array")
        message = `Array must contain ${issue.exact ? `exactly` : issue.inclusive ? `at most` : `less than`} ${issue.maximum} element(s)`;
      else if (issue.type === "string")
        message = `String must contain ${issue.exact ? `exactly` : issue.inclusive ? `at most` : `under`} ${issue.maximum} character(s)`;
      else if (issue.type === "number")
        message = `Number must be ${issue.exact ? `exactly` : issue.inclusive ? `less than or equal to` : `less than`} ${issue.maximum}`;
      else if (issue.type === "bigint")
        message = `BigInt must be ${issue.exact ? `exactly` : issue.inclusive ? `less than or equal to` : `less than`} ${issue.maximum}`;
      else if (issue.type === "date")
        message = `Date must be ${issue.exact ? `exactly` : issue.inclusive ? `smaller than or equal to` : `smaller than`} ${new Date(Number(issue.maximum))}`;
      else
        message = "Invalid input";
      break;
    case ZodIssueCode.custom:
      message = `Invalid input`;
      break;
    case ZodIssueCode.invalid_intersection_types:
      message = `Intersection results could not be merged`;
      break;
    case ZodIssueCode.not_multiple_of:
      message = `Number must be a multiple of ${issue.multipleOf}`;
      break;
    case ZodIssueCode.not_finite:
      message = "Number must be finite";
      break;
    default:
      message = _ctx.defaultError;
      util.assertNever(issue);
  }
  return { message };
};
var overrideErrorMap = errorMap;
function setErrorMap(map) {
  overrideErrorMap = map;
}
function getErrorMap() {
  return overrideErrorMap;
}
var makeIssue = (params) => {
  const { data, path: path2, errorMaps, issueData } = params;
  const fullPath = [...path2, ...issueData.path || []];
  const fullIssue = __spreadProps(__spreadValues({}, issueData), {
    path: fullPath
  });
  let errorMessage = "";
  const maps = errorMaps.filter((m) => !!m).slice().reverse();
  for (const map of maps) {
    errorMessage = map(fullIssue, { data, defaultError: errorMessage }).message;
  }
  return __spreadProps(__spreadValues({}, issueData), {
    path: fullPath,
    message: issueData.message || errorMessage
  });
};
var EMPTY_PATH = [];
function addIssueToContext(ctx, issueData) {
  const issue = makeIssue({
    issueData,
    data: ctx.data,
    path: ctx.path,
    errorMaps: [
      ctx.common.contextualErrorMap,
      ctx.schemaErrorMap,
      getErrorMap(),
      errorMap
      // then global default map
    ].filter((x) => !!x)
  });
  ctx.common.issues.push(issue);
}
var ParseStatus = class {
  constructor() {
    this.value = "valid";
  }
  dirty() {
    if (this.value === "valid")
      this.value = "dirty";
  }
  abort() {
    if (this.value !== "aborted")
      this.value = "aborted";
  }
  static mergeArray(status, results) {
    const arrayValue = [];
    for (const s of results) {
      if (s.status === "aborted")
        return INVALID;
      if (s.status === "dirty")
        status.dirty();
      arrayValue.push(s.value);
    }
    return { status: status.value, value: arrayValue };
  }
  static mergeObjectAsync(status, pairs) {
    return __async(this, null, function* () {
      const syncPairs = [];
      for (const pair of pairs) {
        syncPairs.push({
          key: yield pair.key,
          value: yield pair.value
        });
      }
      return ParseStatus.mergeObjectSync(status, syncPairs);
    });
  }
  static mergeObjectSync(status, pairs) {
    const finalObject = {};
    for (const pair of pairs) {
      const { key, value } = pair;
      if (key.status === "aborted")
        return INVALID;
      if (value.status === "aborted")
        return INVALID;
      if (key.status === "dirty")
        status.dirty();
      if (value.status === "dirty")
        status.dirty();
      if (key.value !== "__proto__" && (typeof value.value !== "undefined" || pair.alwaysSet)) {
        finalObject[key.value] = value.value;
      }
    }
    return { status: status.value, value: finalObject };
  }
};
var INVALID = Object.freeze({
  status: "aborted"
});
var DIRTY = (value) => ({ status: "dirty", value });
var OK = (value) => ({ status: "valid", value });
var isAborted = (x) => x.status === "aborted";
var isDirty = (x) => x.status === "dirty";
var isValid = (x) => x.status === "valid";
var isAsync = (x) => typeof Promise !== "undefined" && x instanceof Promise;
var errorUtil;
(function(errorUtil2) {
  errorUtil2.errToObj = (message) => typeof message === "string" ? { message } : message || {};
  errorUtil2.toString = (message) => typeof message === "string" ? message : message === null || message === void 0 ? void 0 : message.message;
})(errorUtil || (errorUtil = {}));
var ParseInputLazyPath = class {
  constructor(parent, value, path2, key) {
    this._cachedPath = [];
    this.parent = parent;
    this.data = value;
    this._path = path2;
    this._key = key;
  }
  get path() {
    if (!this._cachedPath.length) {
      if (this._key instanceof Array) {
        this._cachedPath.push(...this._path, ...this._key);
      } else {
        this._cachedPath.push(...this._path, this._key);
      }
    }
    return this._cachedPath;
  }
};
var handleResult = (ctx, result2) => {
  if (isValid(result2)) {
    return { success: true, data: result2.value };
  } else {
    if (!ctx.common.issues.length) {
      throw new Error("Validation failed but no issues detected.");
    }
    return {
      success: false,
      get error() {
        if (this._error)
          return this._error;
        const error = new ZodError(ctx.common.issues);
        this._error = error;
        return this._error;
      }
    };
  }
};
function processCreateParams(params) {
  if (!params)
    return {};
  const { errorMap: errorMap2, invalid_type_error, required_error, description } = params;
  if (errorMap2 && (invalid_type_error || required_error)) {
    throw new Error(`Can't use "invalid_type_error" or "required_error" in conjunction with custom error map.`);
  }
  if (errorMap2)
    return { errorMap: errorMap2, description };
  const customMap = (iss, ctx) => {
    if (iss.code !== "invalid_type")
      return { message: ctx.defaultError };
    if (typeof ctx.data === "undefined") {
      return { message: required_error !== null && required_error !== void 0 ? required_error : ctx.defaultError };
    }
    return { message: invalid_type_error !== null && invalid_type_error !== void 0 ? invalid_type_error : ctx.defaultError };
  };
  return { errorMap: customMap, description };
}
var ZodType = class {
  constructor(def) {
    this.spa = this.safeParseAsync;
    this._def = def;
    this.parse = this.parse.bind(this);
    this.safeParse = this.safeParse.bind(this);
    this.parseAsync = this.parseAsync.bind(this);
    this.safeParseAsync = this.safeParseAsync.bind(this);
    this.spa = this.spa.bind(this);
    this.refine = this.refine.bind(this);
    this.refinement = this.refinement.bind(this);
    this.superRefine = this.superRefine.bind(this);
    this.optional = this.optional.bind(this);
    this.nullable = this.nullable.bind(this);
    this.nullish = this.nullish.bind(this);
    this.array = this.array.bind(this);
    this.promise = this.promise.bind(this);
    this.or = this.or.bind(this);
    this.and = this.and.bind(this);
    this.transform = this.transform.bind(this);
    this.brand = this.brand.bind(this);
    this.default = this.default.bind(this);
    this.catch = this.catch.bind(this);
    this.describe = this.describe.bind(this);
    this.pipe = this.pipe.bind(this);
    this.readonly = this.readonly.bind(this);
    this.isNullable = this.isNullable.bind(this);
    this.isOptional = this.isOptional.bind(this);
  }
  get description() {
    return this._def.description;
  }
  _getType(input) {
    return getParsedType(input.data);
  }
  _getOrReturnCtx(input, ctx) {
    return ctx || {
      common: input.parent.common,
      data: input.data,
      parsedType: getParsedType(input.data),
      schemaErrorMap: this._def.errorMap,
      path: input.path,
      parent: input.parent
    };
  }
  _processInputParams(input) {
    return {
      status: new ParseStatus(),
      ctx: {
        common: input.parent.common,
        data: input.data,
        parsedType: getParsedType(input.data),
        schemaErrorMap: this._def.errorMap,
        path: input.path,
        parent: input.parent
      }
    };
  }
  _parseSync(input) {
    const result2 = this._parse(input);
    if (isAsync(result2)) {
      throw new Error("Synchronous parse encountered promise.");
    }
    return result2;
  }
  _parseAsync(input) {
    const result2 = this._parse(input);
    return Promise.resolve(result2);
  }
  parse(data, params) {
    const result2 = this.safeParse(data, params);
    if (result2.success)
      return result2.data;
    throw result2.error;
  }
  safeParse(data, params) {
    var _a;
    const ctx = {
      common: {
        issues: [],
        async: (_a = params === null || params === void 0 ? void 0 : params.async) !== null && _a !== void 0 ? _a : false,
        contextualErrorMap: params === null || params === void 0 ? void 0 : params.errorMap
      },
      path: (params === null || params === void 0 ? void 0 : params.path) || [],
      schemaErrorMap: this._def.errorMap,
      parent: null,
      data,
      parsedType: getParsedType(data)
    };
    const result2 = this._parseSync({ data, path: ctx.path, parent: ctx });
    return handleResult(ctx, result2);
  }
  parseAsync(data, params) {
    return __async(this, null, function* () {
      const result2 = yield this.safeParseAsync(data, params);
      if (result2.success)
        return result2.data;
      throw result2.error;
    });
  }
  safeParseAsync(data, params) {
    return __async(this, null, function* () {
      const ctx = {
        common: {
          issues: [],
          contextualErrorMap: params === null || params === void 0 ? void 0 : params.errorMap,
          async: true
        },
        path: (params === null || params === void 0 ? void 0 : params.path) || [],
        schemaErrorMap: this._def.errorMap,
        parent: null,
        data,
        parsedType: getParsedType(data)
      };
      const maybeAsyncResult = this._parse({ data, path: ctx.path, parent: ctx });
      const result2 = yield isAsync(maybeAsyncResult) ? maybeAsyncResult : Promise.resolve(maybeAsyncResult);
      return handleResult(ctx, result2);
    });
  }
  refine(check, message) {
    const getIssueProperties = (val) => {
      if (typeof message === "string" || typeof message === "undefined") {
        return { message };
      } else if (typeof message === "function") {
        return message(val);
      } else {
        return message;
      }
    };
    return this._refinement((val, ctx) => {
      const result2 = check(val);
      const setError = () => ctx.addIssue(__spreadValues({
        code: ZodIssueCode.custom
      }, getIssueProperties(val)));
      if (typeof Promise !== "undefined" && result2 instanceof Promise) {
        return result2.then((data) => {
          if (!data) {
            setError();
            return false;
          } else {
            return true;
          }
        });
      }
      if (!result2) {
        setError();
        return false;
      } else {
        return true;
      }
    });
  }
  refinement(check, refinementData) {
    return this._refinement((val, ctx) => {
      if (!check(val)) {
        ctx.addIssue(typeof refinementData === "function" ? refinementData(val, ctx) : refinementData);
        return false;
      } else {
        return true;
      }
    });
  }
  _refinement(refinement) {
    return new ZodEffects({
      schema: this,
      typeName: ZodFirstPartyTypeKind.ZodEffects,
      effect: { type: "refinement", refinement }
    });
  }
  superRefine(refinement) {
    return this._refinement(refinement);
  }
  optional() {
    return ZodOptional.create(this, this._def);
  }
  nullable() {
    return ZodNullable.create(this, this._def);
  }
  nullish() {
    return this.nullable().optional();
  }
  array() {
    return ZodArray.create(this, this._def);
  }
  promise() {
    return ZodPromise.create(this, this._def);
  }
  or(option) {
    return ZodUnion.create([this, option], this._def);
  }
  and(incoming) {
    return ZodIntersection.create(this, incoming, this._def);
  }
  transform(transform) {
    return new ZodEffects(__spreadProps(__spreadValues({}, processCreateParams(this._def)), {
      schema: this,
      typeName: ZodFirstPartyTypeKind.ZodEffects,
      effect: { type: "transform", transform }
    }));
  }
  default(def) {
    const defaultValueFunc = typeof def === "function" ? def : () => def;
    return new ZodDefault(__spreadProps(__spreadValues({}, processCreateParams(this._def)), {
      innerType: this,
      defaultValue: defaultValueFunc,
      typeName: ZodFirstPartyTypeKind.ZodDefault
    }));
  }
  brand() {
    return new ZodBranded(__spreadValues({
      typeName: ZodFirstPartyTypeKind.ZodBranded,
      type: this
    }, processCreateParams(this._def)));
  }
  catch(def) {
    const catchValueFunc = typeof def === "function" ? def : () => def;
    return new ZodCatch(__spreadProps(__spreadValues({}, processCreateParams(this._def)), {
      innerType: this,
      catchValue: catchValueFunc,
      typeName: ZodFirstPartyTypeKind.ZodCatch
    }));
  }
  describe(description) {
    const This = this.constructor;
    return new This(__spreadProps(__spreadValues({}, this._def), {
      description
    }));
  }
  pipe(target) {
    return ZodPipeline.create(this, target);
  }
  readonly() {
    return ZodReadonly.create(this);
  }
  isOptional() {
    return this.safeParse(void 0).success;
  }
  isNullable() {
    return this.safeParse(null).success;
  }
};
var cuidRegex = /^c[^\s-]{8,}$/i;
var cuid2Regex = /^[a-z][a-z0-9]*$/;
var ulidRegex = /^[0-9A-HJKMNP-TV-Z]{26}$/;
var uuidRegex = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i;
var emailRegex = /^(?!\.)(?!.*\.\.)([A-Z0-9_+-\.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9\-]*\.)+[A-Z]{2,}$/i;
var _emojiRegex = `^(\\p{Extended_Pictographic}|\\p{Emoji_Component})+$`;
var emojiRegex;
var ipv4Regex = /^(((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\.){3}((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))$/;
var ipv6Regex = /^(([a-f0-9]{1,4}:){7}|::([a-f0-9]{1,4}:){0,6}|([a-f0-9]{1,4}:){1}:([a-f0-9]{1,4}:){0,5}|([a-f0-9]{1,4}:){2}:([a-f0-9]{1,4}:){0,4}|([a-f0-9]{1,4}:){3}:([a-f0-9]{1,4}:){0,3}|([a-f0-9]{1,4}:){4}:([a-f0-9]{1,4}:){0,2}|([a-f0-9]{1,4}:){5}:([a-f0-9]{1,4}:){0,1})([a-f0-9]{1,4}|(((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\.){3}((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2})))$/;
var datetimeRegex = (args) => {
  if (args.precision) {
    if (args.offset) {
      return new RegExp(`^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}\\.\\d{${args.precision}}(([+-]\\d{2}(:?\\d{2})?)|Z)$`);
    } else {
      return new RegExp(`^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}\\.\\d{${args.precision}}Z$`);
    }
  } else if (args.precision === 0) {
    if (args.offset) {
      return new RegExp(`^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}(([+-]\\d{2}(:?\\d{2})?)|Z)$`);
    } else {
      return new RegExp(`^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}Z$`);
    }
  } else {
    if (args.offset) {
      return new RegExp(`^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}(\\.\\d+)?(([+-]\\d{2}(:?\\d{2})?)|Z)$`);
    } else {
      return new RegExp(`^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}(\\.\\d+)?Z$`);
    }
  }
};
function isValidIP(ip, version) {
  if ((version === "v4" || !version) && ipv4Regex.test(ip)) {
    return true;
  }
  if ((version === "v6" || !version) && ipv6Regex.test(ip)) {
    return true;
  }
  return false;
}
var ZodString = class extends ZodType {
  _parse(input) {
    if (this._def.coerce) {
      input.data = String(input.data);
    }
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.string) {
      const ctx2 = this._getOrReturnCtx(input);
      addIssueToContext(
        ctx2,
        {
          code: ZodIssueCode.invalid_type,
          expected: ZodParsedType.string,
          received: ctx2.parsedType
        }
        //
      );
      return INVALID;
    }
    const status = new ParseStatus();
    let ctx = void 0;
    for (const check of this._def.checks) {
      if (check.kind === "min") {
        if (input.data.length < check.value) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.too_small,
            minimum: check.value,
            type: "string",
            inclusive: true,
            exact: false,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "max") {
        if (input.data.length > check.value) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.too_big,
            maximum: check.value,
            type: "string",
            inclusive: true,
            exact: false,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "length") {
        const tooBig = input.data.length > check.value;
        const tooSmall = input.data.length < check.value;
        if (tooBig || tooSmall) {
          ctx = this._getOrReturnCtx(input, ctx);
          if (tooBig) {
            addIssueToContext(ctx, {
              code: ZodIssueCode.too_big,
              maximum: check.value,
              type: "string",
              inclusive: true,
              exact: true,
              message: check.message
            });
          } else if (tooSmall) {
            addIssueToContext(ctx, {
              code: ZodIssueCode.too_small,
              minimum: check.value,
              type: "string",
              inclusive: true,
              exact: true,
              message: check.message
            });
          }
          status.dirty();
        }
      } else if (check.kind === "email") {
        if (!emailRegex.test(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "email",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "emoji") {
        if (!emojiRegex) {
          emojiRegex = new RegExp(_emojiRegex, "u");
        }
        if (!emojiRegex.test(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "emoji",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "uuid") {
        if (!uuidRegex.test(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "uuid",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "cuid") {
        if (!cuidRegex.test(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "cuid",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "cuid2") {
        if (!cuid2Regex.test(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "cuid2",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "ulid") {
        if (!ulidRegex.test(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "ulid",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "url") {
        try {
          new URL(input.data);
        } catch (_a) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "url",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "regex") {
        check.regex.lastIndex = 0;
        const testResult = check.regex.test(input.data);
        if (!testResult) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "regex",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "trim") {
        input.data = input.data.trim();
      } else if (check.kind === "includes") {
        if (!input.data.includes(check.value, check.position)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.invalid_string,
            validation: { includes: check.value, position: check.position },
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "toLowerCase") {
        input.data = input.data.toLowerCase();
      } else if (check.kind === "toUpperCase") {
        input.data = input.data.toUpperCase();
      } else if (check.kind === "startsWith") {
        if (!input.data.startsWith(check.value)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.invalid_string,
            validation: { startsWith: check.value },
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "endsWith") {
        if (!input.data.endsWith(check.value)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.invalid_string,
            validation: { endsWith: check.value },
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "datetime") {
        const regex = datetimeRegex(check);
        if (!regex.test(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.invalid_string,
            validation: "datetime",
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "ip") {
        if (!isValidIP(input.data, check.version)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "ip",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else {
        util.assertNever(check);
      }
    }
    return { status: status.value, value: input.data };
  }
  _regex(regex, validation, message) {
    return this.refinement((data) => regex.test(data), __spreadValues({
      validation,
      code: ZodIssueCode.invalid_string
    }, errorUtil.errToObj(message)));
  }
  _addCheck(check) {
    return new ZodString(__spreadProps(__spreadValues({}, this._def), {
      checks: [...this._def.checks, check]
    }));
  }
  email(message) {
    return this._addCheck(__spreadValues({ kind: "email" }, errorUtil.errToObj(message)));
  }
  url(message) {
    return this._addCheck(__spreadValues({ kind: "url" }, errorUtil.errToObj(message)));
  }
  emoji(message) {
    return this._addCheck(__spreadValues({ kind: "emoji" }, errorUtil.errToObj(message)));
  }
  uuid(message) {
    return this._addCheck(__spreadValues({ kind: "uuid" }, errorUtil.errToObj(message)));
  }
  cuid(message) {
    return this._addCheck(__spreadValues({ kind: "cuid" }, errorUtil.errToObj(message)));
  }
  cuid2(message) {
    return this._addCheck(__spreadValues({ kind: "cuid2" }, errorUtil.errToObj(message)));
  }
  ulid(message) {
    return this._addCheck(__spreadValues({ kind: "ulid" }, errorUtil.errToObj(message)));
  }
  ip(options) {
    return this._addCheck(__spreadValues({ kind: "ip" }, errorUtil.errToObj(options)));
  }
  datetime(options) {
    var _a;
    if (typeof options === "string") {
      return this._addCheck({
        kind: "datetime",
        precision: null,
        offset: false,
        message: options
      });
    }
    return this._addCheck(__spreadValues({
      kind: "datetime",
      precision: typeof (options === null || options === void 0 ? void 0 : options.precision) === "undefined" ? null : options === null || options === void 0 ? void 0 : options.precision,
      offset: (_a = options === null || options === void 0 ? void 0 : options.offset) !== null && _a !== void 0 ? _a : false
    }, errorUtil.errToObj(options === null || options === void 0 ? void 0 : options.message)));
  }
  regex(regex, message) {
    return this._addCheck(__spreadValues({
      kind: "regex",
      regex
    }, errorUtil.errToObj(message)));
  }
  includes(value, options) {
    return this._addCheck(__spreadValues({
      kind: "includes",
      value,
      position: options === null || options === void 0 ? void 0 : options.position
    }, errorUtil.errToObj(options === null || options === void 0 ? void 0 : options.message)));
  }
  startsWith(value, message) {
    return this._addCheck(__spreadValues({
      kind: "startsWith",
      value
    }, errorUtil.errToObj(message)));
  }
  endsWith(value, message) {
    return this._addCheck(__spreadValues({
      kind: "endsWith",
      value
    }, errorUtil.errToObj(message)));
  }
  min(minLength, message) {
    return this._addCheck(__spreadValues({
      kind: "min",
      value: minLength
    }, errorUtil.errToObj(message)));
  }
  max(maxLength, message) {
    return this._addCheck(__spreadValues({
      kind: "max",
      value: maxLength
    }, errorUtil.errToObj(message)));
  }
  length(len, message) {
    return this._addCheck(__spreadValues({
      kind: "length",
      value: len
    }, errorUtil.errToObj(message)));
  }
  /**
   * @deprecated Use z.string().min(1) instead.
   * @see {@link ZodString.min}
   */
  nonempty(message) {
    return this.min(1, errorUtil.errToObj(message));
  }
  trim() {
    return new ZodString(__spreadProps(__spreadValues({}, this._def), {
      checks: [...this._def.checks, { kind: "trim" }]
    }));
  }
  toLowerCase() {
    return new ZodString(__spreadProps(__spreadValues({}, this._def), {
      checks: [...this._def.checks, { kind: "toLowerCase" }]
    }));
  }
  toUpperCase() {
    return new ZodString(__spreadProps(__spreadValues({}, this._def), {
      checks: [...this._def.checks, { kind: "toUpperCase" }]
    }));
  }
  get isDatetime() {
    return !!this._def.checks.find((ch) => ch.kind === "datetime");
  }
  get isEmail() {
    return !!this._def.checks.find((ch) => ch.kind === "email");
  }
  get isURL() {
    return !!this._def.checks.find((ch) => ch.kind === "url");
  }
  get isEmoji() {
    return !!this._def.checks.find((ch) => ch.kind === "emoji");
  }
  get isUUID() {
    return !!this._def.checks.find((ch) => ch.kind === "uuid");
  }
  get isCUID() {
    return !!this._def.checks.find((ch) => ch.kind === "cuid");
  }
  get isCUID2() {
    return !!this._def.checks.find((ch) => ch.kind === "cuid2");
  }
  get isULID() {
    return !!this._def.checks.find((ch) => ch.kind === "ulid");
  }
  get isIP() {
    return !!this._def.checks.find((ch) => ch.kind === "ip");
  }
  get minLength() {
    let min = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "min") {
        if (min === null || ch.value > min)
          min = ch.value;
      }
    }
    return min;
  }
  get maxLength() {
    let max = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "max") {
        if (max === null || ch.value < max)
          max = ch.value;
      }
    }
    return max;
  }
};
ZodString.create = (params) => {
  var _a;
  return new ZodString(__spreadValues({
    checks: [],
    typeName: ZodFirstPartyTypeKind.ZodString,
    coerce: (_a = params === null || params === void 0 ? void 0 : params.coerce) !== null && _a !== void 0 ? _a : false
  }, processCreateParams(params)));
};
function floatSafeRemainder(val, step) {
  const valDecCount = (val.toString().split(".")[1] || "").length;
  const stepDecCount = (step.toString().split(".")[1] || "").length;
  const decCount = valDecCount > stepDecCount ? valDecCount : stepDecCount;
  const valInt = parseInt(val.toFixed(decCount).replace(".", ""));
  const stepInt = parseInt(step.toFixed(decCount).replace(".", ""));
  return valInt % stepInt / Math.pow(10, decCount);
}
var ZodNumber = class extends ZodType {
  constructor() {
    super(...arguments);
    this.min = this.gte;
    this.max = this.lte;
    this.step = this.multipleOf;
  }
  _parse(input) {
    if (this._def.coerce) {
      input.data = Number(input.data);
    }
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.number) {
      const ctx2 = this._getOrReturnCtx(input);
      addIssueToContext(ctx2, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.number,
        received: ctx2.parsedType
      });
      return INVALID;
    }
    let ctx = void 0;
    const status = new ParseStatus();
    for (const check of this._def.checks) {
      if (check.kind === "int") {
        if (!util.isInteger(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.invalid_type,
            expected: "integer",
            received: "float",
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "min") {
        const tooSmall = check.inclusive ? input.data < check.value : input.data <= check.value;
        if (tooSmall) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.too_small,
            minimum: check.value,
            type: "number",
            inclusive: check.inclusive,
            exact: false,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "max") {
        const tooBig = check.inclusive ? input.data > check.value : input.data >= check.value;
        if (tooBig) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.too_big,
            maximum: check.value,
            type: "number",
            inclusive: check.inclusive,
            exact: false,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "multipleOf") {
        if (floatSafeRemainder(input.data, check.value) !== 0) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.not_multiple_of,
            multipleOf: check.value,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "finite") {
        if (!Number.isFinite(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.not_finite,
            message: check.message
          });
          status.dirty();
        }
      } else {
        util.assertNever(check);
      }
    }
    return { status: status.value, value: input.data };
  }
  gte(value, message) {
    return this.setLimit("min", value, true, errorUtil.toString(message));
  }
  gt(value, message) {
    return this.setLimit("min", value, false, errorUtil.toString(message));
  }
  lte(value, message) {
    return this.setLimit("max", value, true, errorUtil.toString(message));
  }
  lt(value, message) {
    return this.setLimit("max", value, false, errorUtil.toString(message));
  }
  setLimit(kind, value, inclusive, message) {
    return new ZodNumber(__spreadProps(__spreadValues({}, this._def), {
      checks: [
        ...this._def.checks,
        {
          kind,
          value,
          inclusive,
          message: errorUtil.toString(message)
        }
      ]
    }));
  }
  _addCheck(check) {
    return new ZodNumber(__spreadProps(__spreadValues({}, this._def), {
      checks: [...this._def.checks, check]
    }));
  }
  int(message) {
    return this._addCheck({
      kind: "int",
      message: errorUtil.toString(message)
    });
  }
  positive(message) {
    return this._addCheck({
      kind: "min",
      value: 0,
      inclusive: false,
      message: errorUtil.toString(message)
    });
  }
  negative(message) {
    return this._addCheck({
      kind: "max",
      value: 0,
      inclusive: false,
      message: errorUtil.toString(message)
    });
  }
  nonpositive(message) {
    return this._addCheck({
      kind: "max",
      value: 0,
      inclusive: true,
      message: errorUtil.toString(message)
    });
  }
  nonnegative(message) {
    return this._addCheck({
      kind: "min",
      value: 0,
      inclusive: true,
      message: errorUtil.toString(message)
    });
  }
  multipleOf(value, message) {
    return this._addCheck({
      kind: "multipleOf",
      value,
      message: errorUtil.toString(message)
    });
  }
  finite(message) {
    return this._addCheck({
      kind: "finite",
      message: errorUtil.toString(message)
    });
  }
  safe(message) {
    return this._addCheck({
      kind: "min",
      inclusive: true,
      value: Number.MIN_SAFE_INTEGER,
      message: errorUtil.toString(message)
    })._addCheck({
      kind: "max",
      inclusive: true,
      value: Number.MAX_SAFE_INTEGER,
      message: errorUtil.toString(message)
    });
  }
  get minValue() {
    let min = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "min") {
        if (min === null || ch.value > min)
          min = ch.value;
      }
    }
    return min;
  }
  get maxValue() {
    let max = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "max") {
        if (max === null || ch.value < max)
          max = ch.value;
      }
    }
    return max;
  }
  get isInt() {
    return !!this._def.checks.find((ch) => ch.kind === "int" || ch.kind === "multipleOf" && util.isInteger(ch.value));
  }
  get isFinite() {
    let max = null, min = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "finite" || ch.kind === "int" || ch.kind === "multipleOf") {
        return true;
      } else if (ch.kind === "min") {
        if (min === null || ch.value > min)
          min = ch.value;
      } else if (ch.kind === "max") {
        if (max === null || ch.value < max)
          max = ch.value;
      }
    }
    return Number.isFinite(min) && Number.isFinite(max);
  }
};
ZodNumber.create = (params) => {
  return new ZodNumber(__spreadValues({
    checks: [],
    typeName: ZodFirstPartyTypeKind.ZodNumber,
    coerce: (params === null || params === void 0 ? void 0 : params.coerce) || false
  }, processCreateParams(params)));
};
var ZodBigInt = class extends ZodType {
  constructor() {
    super(...arguments);
    this.min = this.gte;
    this.max = this.lte;
  }
  _parse(input) {
    if (this._def.coerce) {
      input.data = BigInt(input.data);
    }
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.bigint) {
      const ctx2 = this._getOrReturnCtx(input);
      addIssueToContext(ctx2, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.bigint,
        received: ctx2.parsedType
      });
      return INVALID;
    }
    let ctx = void 0;
    const status = new ParseStatus();
    for (const check of this._def.checks) {
      if (check.kind === "min") {
        const tooSmall = check.inclusive ? input.data < check.value : input.data <= check.value;
        if (tooSmall) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.too_small,
            type: "bigint",
            minimum: check.value,
            inclusive: check.inclusive,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "max") {
        const tooBig = check.inclusive ? input.data > check.value : input.data >= check.value;
        if (tooBig) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.too_big,
            type: "bigint",
            maximum: check.value,
            inclusive: check.inclusive,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "multipleOf") {
        if (input.data % check.value !== BigInt(0)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.not_multiple_of,
            multipleOf: check.value,
            message: check.message
          });
          status.dirty();
        }
      } else {
        util.assertNever(check);
      }
    }
    return { status: status.value, value: input.data };
  }
  gte(value, message) {
    return this.setLimit("min", value, true, errorUtil.toString(message));
  }
  gt(value, message) {
    return this.setLimit("min", value, false, errorUtil.toString(message));
  }
  lte(value, message) {
    return this.setLimit("max", value, true, errorUtil.toString(message));
  }
  lt(value, message) {
    return this.setLimit("max", value, false, errorUtil.toString(message));
  }
  setLimit(kind, value, inclusive, message) {
    return new ZodBigInt(__spreadProps(__spreadValues({}, this._def), {
      checks: [
        ...this._def.checks,
        {
          kind,
          value,
          inclusive,
          message: errorUtil.toString(message)
        }
      ]
    }));
  }
  _addCheck(check) {
    return new ZodBigInt(__spreadProps(__spreadValues({}, this._def), {
      checks: [...this._def.checks, check]
    }));
  }
  positive(message) {
    return this._addCheck({
      kind: "min",
      value: BigInt(0),
      inclusive: false,
      message: errorUtil.toString(message)
    });
  }
  negative(message) {
    return this._addCheck({
      kind: "max",
      value: BigInt(0),
      inclusive: false,
      message: errorUtil.toString(message)
    });
  }
  nonpositive(message) {
    return this._addCheck({
      kind: "max",
      value: BigInt(0),
      inclusive: true,
      message: errorUtil.toString(message)
    });
  }
  nonnegative(message) {
    return this._addCheck({
      kind: "min",
      value: BigInt(0),
      inclusive: true,
      message: errorUtil.toString(message)
    });
  }
  multipleOf(value, message) {
    return this._addCheck({
      kind: "multipleOf",
      value,
      message: errorUtil.toString(message)
    });
  }
  get minValue() {
    let min = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "min") {
        if (min === null || ch.value > min)
          min = ch.value;
      }
    }
    return min;
  }
  get maxValue() {
    let max = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "max") {
        if (max === null || ch.value < max)
          max = ch.value;
      }
    }
    return max;
  }
};
ZodBigInt.create = (params) => {
  var _a;
  return new ZodBigInt(__spreadValues({
    checks: [],
    typeName: ZodFirstPartyTypeKind.ZodBigInt,
    coerce: (_a = params === null || params === void 0 ? void 0 : params.coerce) !== null && _a !== void 0 ? _a : false
  }, processCreateParams(params)));
};
var ZodBoolean = class extends ZodType {
  _parse(input) {
    if (this._def.coerce) {
      input.data = Boolean(input.data);
    }
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.boolean) {
      const ctx = this._getOrReturnCtx(input);
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.boolean,
        received: ctx.parsedType
      });
      return INVALID;
    }
    return OK(input.data);
  }
};
ZodBoolean.create = (params) => {
  return new ZodBoolean(__spreadValues({
    typeName: ZodFirstPartyTypeKind.ZodBoolean,
    coerce: (params === null || params === void 0 ? void 0 : params.coerce) || false
  }, processCreateParams(params)));
};
var ZodDate = class extends ZodType {
  _parse(input) {
    if (this._def.coerce) {
      input.data = new Date(input.data);
    }
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.date) {
      const ctx2 = this._getOrReturnCtx(input);
      addIssueToContext(ctx2, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.date,
        received: ctx2.parsedType
      });
      return INVALID;
    }
    if (isNaN(input.data.getTime())) {
      const ctx2 = this._getOrReturnCtx(input);
      addIssueToContext(ctx2, {
        code: ZodIssueCode.invalid_date
      });
      return INVALID;
    }
    const status = new ParseStatus();
    let ctx = void 0;
    for (const check of this._def.checks) {
      if (check.kind === "min") {
        if (input.data.getTime() < check.value) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.too_small,
            message: check.message,
            inclusive: true,
            exact: false,
            minimum: check.value,
            type: "date"
          });
          status.dirty();
        }
      } else if (check.kind === "max") {
        if (input.data.getTime() > check.value) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.too_big,
            message: check.message,
            inclusive: true,
            exact: false,
            maximum: check.value,
            type: "date"
          });
          status.dirty();
        }
      } else {
        util.assertNever(check);
      }
    }
    return {
      status: status.value,
      value: new Date(input.data.getTime())
    };
  }
  _addCheck(check) {
    return new ZodDate(__spreadProps(__spreadValues({}, this._def), {
      checks: [...this._def.checks, check]
    }));
  }
  min(minDate, message) {
    return this._addCheck({
      kind: "min",
      value: minDate.getTime(),
      message: errorUtil.toString(message)
    });
  }
  max(maxDate, message) {
    return this._addCheck({
      kind: "max",
      value: maxDate.getTime(),
      message: errorUtil.toString(message)
    });
  }
  get minDate() {
    let min = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "min") {
        if (min === null || ch.value > min)
          min = ch.value;
      }
    }
    return min != null ? new Date(min) : null;
  }
  get maxDate() {
    let max = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "max") {
        if (max === null || ch.value < max)
          max = ch.value;
      }
    }
    return max != null ? new Date(max) : null;
  }
};
ZodDate.create = (params) => {
  return new ZodDate(__spreadValues({
    checks: [],
    coerce: (params === null || params === void 0 ? void 0 : params.coerce) || false,
    typeName: ZodFirstPartyTypeKind.ZodDate
  }, processCreateParams(params)));
};
var ZodSymbol = class extends ZodType {
  _parse(input) {
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.symbol) {
      const ctx = this._getOrReturnCtx(input);
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.symbol,
        received: ctx.parsedType
      });
      return INVALID;
    }
    return OK(input.data);
  }
};
ZodSymbol.create = (params) => {
  return new ZodSymbol(__spreadValues({
    typeName: ZodFirstPartyTypeKind.ZodSymbol
  }, processCreateParams(params)));
};
var ZodUndefined = class extends ZodType {
  _parse(input) {
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.undefined) {
      const ctx = this._getOrReturnCtx(input);
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.undefined,
        received: ctx.parsedType
      });
      return INVALID;
    }
    return OK(input.data);
  }
};
ZodUndefined.create = (params) => {
  return new ZodUndefined(__spreadValues({
    typeName: ZodFirstPartyTypeKind.ZodUndefined
  }, processCreateParams(params)));
};
var ZodNull = class extends ZodType {
  _parse(input) {
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.null) {
      const ctx = this._getOrReturnCtx(input);
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.null,
        received: ctx.parsedType
      });
      return INVALID;
    }
    return OK(input.data);
  }
};
ZodNull.create = (params) => {
  return new ZodNull(__spreadValues({
    typeName: ZodFirstPartyTypeKind.ZodNull
  }, processCreateParams(params)));
};
var ZodAny = class extends ZodType {
  constructor() {
    super(...arguments);
    this._any = true;
  }
  _parse(input) {
    return OK(input.data);
  }
};
ZodAny.create = (params) => {
  return new ZodAny(__spreadValues({
    typeName: ZodFirstPartyTypeKind.ZodAny
  }, processCreateParams(params)));
};
var ZodUnknown = class extends ZodType {
  constructor() {
    super(...arguments);
    this._unknown = true;
  }
  _parse(input) {
    return OK(input.data);
  }
};
ZodUnknown.create = (params) => {
  return new ZodUnknown(__spreadValues({
    typeName: ZodFirstPartyTypeKind.ZodUnknown
  }, processCreateParams(params)));
};
var ZodNever = class extends ZodType {
  _parse(input) {
    const ctx = this._getOrReturnCtx(input);
    addIssueToContext(ctx, {
      code: ZodIssueCode.invalid_type,
      expected: ZodParsedType.never,
      received: ctx.parsedType
    });
    return INVALID;
  }
};
ZodNever.create = (params) => {
  return new ZodNever(__spreadValues({
    typeName: ZodFirstPartyTypeKind.ZodNever
  }, processCreateParams(params)));
};
var ZodVoid = class extends ZodType {
  _parse(input) {
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.undefined) {
      const ctx = this._getOrReturnCtx(input);
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.void,
        received: ctx.parsedType
      });
      return INVALID;
    }
    return OK(input.data);
  }
};
ZodVoid.create = (params) => {
  return new ZodVoid(__spreadValues({
    typeName: ZodFirstPartyTypeKind.ZodVoid
  }, processCreateParams(params)));
};
var ZodArray = class extends ZodType {
  _parse(input) {
    const { ctx, status } = this._processInputParams(input);
    const def = this._def;
    if (ctx.parsedType !== ZodParsedType.array) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.array,
        received: ctx.parsedType
      });
      return INVALID;
    }
    if (def.exactLength !== null) {
      const tooBig = ctx.data.length > def.exactLength.value;
      const tooSmall = ctx.data.length < def.exactLength.value;
      if (tooBig || tooSmall) {
        addIssueToContext(ctx, {
          code: tooBig ? ZodIssueCode.too_big : ZodIssueCode.too_small,
          minimum: tooSmall ? def.exactLength.value : void 0,
          maximum: tooBig ? def.exactLength.value : void 0,
          type: "array",
          inclusive: true,
          exact: true,
          message: def.exactLength.message
        });
        status.dirty();
      }
    }
    if (def.minLength !== null) {
      if (ctx.data.length < def.minLength.value) {
        addIssueToContext(ctx, {
          code: ZodIssueCode.too_small,
          minimum: def.minLength.value,
          type: "array",
          inclusive: true,
          exact: false,
          message: def.minLength.message
        });
        status.dirty();
      }
    }
    if (def.maxLength !== null) {
      if (ctx.data.length > def.maxLength.value) {
        addIssueToContext(ctx, {
          code: ZodIssueCode.too_big,
          maximum: def.maxLength.value,
          type: "array",
          inclusive: true,
          exact: false,
          message: def.maxLength.message
        });
        status.dirty();
      }
    }
    if (ctx.common.async) {
      return Promise.all([...ctx.data].map((item, i) => {
        return def.type._parseAsync(new ParseInputLazyPath(ctx, item, ctx.path, i));
      })).then((result3) => {
        return ParseStatus.mergeArray(status, result3);
      });
    }
    const result2 = [...ctx.data].map((item, i) => {
      return def.type._parseSync(new ParseInputLazyPath(ctx, item, ctx.path, i));
    });
    return ParseStatus.mergeArray(status, result2);
  }
  get element() {
    return this._def.type;
  }
  min(minLength, message) {
    return new ZodArray(__spreadProps(__spreadValues({}, this._def), {
      minLength: { value: minLength, message: errorUtil.toString(message) }
    }));
  }
  max(maxLength, message) {
    return new ZodArray(__spreadProps(__spreadValues({}, this._def), {
      maxLength: { value: maxLength, message: errorUtil.toString(message) }
    }));
  }
  length(len, message) {
    return new ZodArray(__spreadProps(__spreadValues({}, this._def), {
      exactLength: { value: len, message: errorUtil.toString(message) }
    }));
  }
  nonempty(message) {
    return this.min(1, message);
  }
};
ZodArray.create = (schema, params) => {
  return new ZodArray(__spreadValues({
    type: schema,
    minLength: null,
    maxLength: null,
    exactLength: null,
    typeName: ZodFirstPartyTypeKind.ZodArray
  }, processCreateParams(params)));
};
function deepPartialify(schema) {
  if (schema instanceof ZodObject) {
    const newShape = {};
    for (const key in schema.shape) {
      const fieldSchema = schema.shape[key];
      newShape[key] = ZodOptional.create(deepPartialify(fieldSchema));
    }
    return new ZodObject(__spreadProps(__spreadValues({}, schema._def), {
      shape: () => newShape
    }));
  } else if (schema instanceof ZodArray) {
    return new ZodArray(__spreadProps(__spreadValues({}, schema._def), {
      type: deepPartialify(schema.element)
    }));
  } else if (schema instanceof ZodOptional) {
    return ZodOptional.create(deepPartialify(schema.unwrap()));
  } else if (schema instanceof ZodNullable) {
    return ZodNullable.create(deepPartialify(schema.unwrap()));
  } else if (schema instanceof ZodTuple) {
    return ZodTuple.create(schema.items.map((item) => deepPartialify(item)));
  } else {
    return schema;
  }
}
var ZodObject = class extends ZodType {
  constructor() {
    super(...arguments);
    this._cached = null;
    this.nonstrict = this.passthrough;
    this.augment = this.extend;
  }
  _getCached() {
    if (this._cached !== null)
      return this._cached;
    const shape = this._def.shape();
    const keys = util.objectKeys(shape);
    return this._cached = { shape, keys };
  }
  _parse(input) {
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.object) {
      const ctx2 = this._getOrReturnCtx(input);
      addIssueToContext(ctx2, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.object,
        received: ctx2.parsedType
      });
      return INVALID;
    }
    const { status, ctx } = this._processInputParams(input);
    const { shape, keys: shapeKeys } = this._getCached();
    const extraKeys = [];
    if (!(this._def.catchall instanceof ZodNever && this._def.unknownKeys === "strip")) {
      for (const key in ctx.data) {
        if (!shapeKeys.includes(key)) {
          extraKeys.push(key);
        }
      }
    }
    const pairs = [];
    for (const key of shapeKeys) {
      const keyValidator = shape[key];
      const value = ctx.data[key];
      pairs.push({
        key: { status: "valid", value: key },
        value: keyValidator._parse(new ParseInputLazyPath(ctx, value, ctx.path, key)),
        alwaysSet: key in ctx.data
      });
    }
    if (this._def.catchall instanceof ZodNever) {
      const unknownKeys = this._def.unknownKeys;
      if (unknownKeys === "passthrough") {
        for (const key of extraKeys) {
          pairs.push({
            key: { status: "valid", value: key },
            value: { status: "valid", value: ctx.data[key] }
          });
        }
      } else if (unknownKeys === "strict") {
        if (extraKeys.length > 0) {
          addIssueToContext(ctx, {
            code: ZodIssueCode.unrecognized_keys,
            keys: extraKeys
          });
          status.dirty();
        }
      } else if (unknownKeys === "strip")
        ;
      else {
        throw new Error(`Internal ZodObject error: invalid unknownKeys value.`);
      }
    } else {
      const catchall = this._def.catchall;
      for (const key of extraKeys) {
        const value = ctx.data[key];
        pairs.push({
          key: { status: "valid", value: key },
          value: catchall._parse(
            new ParseInputLazyPath(ctx, value, ctx.path, key)
            //, ctx.child(key), value, getParsedType(value)
          ),
          alwaysSet: key in ctx.data
        });
      }
    }
    if (ctx.common.async) {
      return Promise.resolve().then(() => __async(this, null, function* () {
        const syncPairs = [];
        for (const pair of pairs) {
          const key = yield pair.key;
          syncPairs.push({
            key,
            value: yield pair.value,
            alwaysSet: pair.alwaysSet
          });
        }
        return syncPairs;
      })).then((syncPairs) => {
        return ParseStatus.mergeObjectSync(status, syncPairs);
      });
    } else {
      return ParseStatus.mergeObjectSync(status, pairs);
    }
  }
  get shape() {
    return this._def.shape();
  }
  strict(message) {
    errorUtil.errToObj;
    return new ZodObject(__spreadValues(__spreadProps(__spreadValues({}, this._def), {
      unknownKeys: "strict"
    }), message !== void 0 ? {
      errorMap: (issue, ctx) => {
        var _a, _b, _c, _d;
        const defaultError = (_c = (_b = (_a = this._def).errorMap) === null || _b === void 0 ? void 0 : _b.call(_a, issue, ctx).message) !== null && _c !== void 0 ? _c : ctx.defaultError;
        if (issue.code === "unrecognized_keys")
          return {
            message: (_d = errorUtil.errToObj(message).message) !== null && _d !== void 0 ? _d : defaultError
          };
        return {
          message: defaultError
        };
      }
    } : {}));
  }
  strip() {
    return new ZodObject(__spreadProps(__spreadValues({}, this._def), {
      unknownKeys: "strip"
    }));
  }
  passthrough() {
    return new ZodObject(__spreadProps(__spreadValues({}, this._def), {
      unknownKeys: "passthrough"
    }));
  }
  // const AugmentFactory =
  //   <Def extends ZodObjectDef>(def: Def) =>
  //   <Augmentation extends ZodRawShape>(
  //     augmentation: Augmentation
  //   ): ZodObject<
  //     extendShape<ReturnType<Def["shape"]>, Augmentation>,
  //     Def["unknownKeys"],
  //     Def["catchall"]
  //   > => {
  //     return new ZodObject({
  //       ...def,
  //       shape: () => ({
  //         ...def.shape(),
  //         ...augmentation,
  //       }),
  //     }) as any;
  //   };
  extend(augmentation) {
    return new ZodObject(__spreadProps(__spreadValues({}, this._def), {
      shape: () => __spreadValues(__spreadValues({}, this._def.shape()), augmentation)
    }));
  }
  /**
   * Prior to zod@1.0.12 there was a bug in the
   * inferred type of merged objects. Please
   * upgrade if you are experiencing issues.
   */
  merge(merging) {
    const merged = new ZodObject({
      unknownKeys: merging._def.unknownKeys,
      catchall: merging._def.catchall,
      shape: () => __spreadValues(__spreadValues({}, this._def.shape()), merging._def.shape()),
      typeName: ZodFirstPartyTypeKind.ZodObject
    });
    return merged;
  }
  // merge<
  //   Incoming extends AnyZodObject,
  //   Augmentation extends Incoming["shape"],
  //   NewOutput extends {
  //     [k in keyof Augmentation | keyof Output]: k extends keyof Augmentation
  //       ? Augmentation[k]["_output"]
  //       : k extends keyof Output
  //       ? Output[k]
  //       : never;
  //   },
  //   NewInput extends {
  //     [k in keyof Augmentation | keyof Input]: k extends keyof Augmentation
  //       ? Augmentation[k]["_input"]
  //       : k extends keyof Input
  //       ? Input[k]
  //       : never;
  //   }
  // >(
  //   merging: Incoming
  // ): ZodObject<
  //   extendShape<T, ReturnType<Incoming["_def"]["shape"]>>,
  //   Incoming["_def"]["unknownKeys"],
  //   Incoming["_def"]["catchall"],
  //   NewOutput,
  //   NewInput
  // > {
  //   const merged: any = new ZodObject({
  //     unknownKeys: merging._def.unknownKeys,
  //     catchall: merging._def.catchall,
  //     shape: () =>
  //       objectUtil.mergeShapes(this._def.shape(), merging._def.shape()),
  //     typeName: ZodFirstPartyTypeKind.ZodObject,
  //   }) as any;
  //   return merged;
  // }
  setKey(key, schema) {
    return this.augment({ [key]: schema });
  }
  // merge<Incoming extends AnyZodObject>(
  //   merging: Incoming
  // ): //ZodObject<T & Incoming["_shape"], UnknownKeys, Catchall> = (merging) => {
  // ZodObject<
  //   extendShape<T, ReturnType<Incoming["_def"]["shape"]>>,
  //   Incoming["_def"]["unknownKeys"],
  //   Incoming["_def"]["catchall"]
  // > {
  //   // const mergedShape = objectUtil.mergeShapes(
  //   //   this._def.shape(),
  //   //   merging._def.shape()
  //   // );
  //   const merged: any = new ZodObject({
  //     unknownKeys: merging._def.unknownKeys,
  //     catchall: merging._def.catchall,
  //     shape: () =>
  //       objectUtil.mergeShapes(this._def.shape(), merging._def.shape()),
  //     typeName: ZodFirstPartyTypeKind.ZodObject,
  //   }) as any;
  //   return merged;
  // }
  catchall(index) {
    return new ZodObject(__spreadProps(__spreadValues({}, this._def), {
      catchall: index
    }));
  }
  pick(mask) {
    const shape = {};
    util.objectKeys(mask).forEach((key) => {
      if (mask[key] && this.shape[key]) {
        shape[key] = this.shape[key];
      }
    });
    return new ZodObject(__spreadProps(__spreadValues({}, this._def), {
      shape: () => shape
    }));
  }
  omit(mask) {
    const shape = {};
    util.objectKeys(this.shape).forEach((key) => {
      if (!mask[key]) {
        shape[key] = this.shape[key];
      }
    });
    return new ZodObject(__spreadProps(__spreadValues({}, this._def), {
      shape: () => shape
    }));
  }
  /**
   * @deprecated
   */
  deepPartial() {
    return deepPartialify(this);
  }
  partial(mask) {
    const newShape = {};
    util.objectKeys(this.shape).forEach((key) => {
      const fieldSchema = this.shape[key];
      if (mask && !mask[key]) {
        newShape[key] = fieldSchema;
      } else {
        newShape[key] = fieldSchema.optional();
      }
    });
    return new ZodObject(__spreadProps(__spreadValues({}, this._def), {
      shape: () => newShape
    }));
  }
  required(mask) {
    const newShape = {};
    util.objectKeys(this.shape).forEach((key) => {
      if (mask && !mask[key]) {
        newShape[key] = this.shape[key];
      } else {
        const fieldSchema = this.shape[key];
        let newField = fieldSchema;
        while (newField instanceof ZodOptional) {
          newField = newField._def.innerType;
        }
        newShape[key] = newField;
      }
    });
    return new ZodObject(__spreadProps(__spreadValues({}, this._def), {
      shape: () => newShape
    }));
  }
  keyof() {
    return createZodEnum(util.objectKeys(this.shape));
  }
};
ZodObject.create = (shape, params) => {
  return new ZodObject(__spreadValues({
    shape: () => shape,
    unknownKeys: "strip",
    catchall: ZodNever.create(),
    typeName: ZodFirstPartyTypeKind.ZodObject
  }, processCreateParams(params)));
};
ZodObject.strictCreate = (shape, params) => {
  return new ZodObject(__spreadValues({
    shape: () => shape,
    unknownKeys: "strict",
    catchall: ZodNever.create(),
    typeName: ZodFirstPartyTypeKind.ZodObject
  }, processCreateParams(params)));
};
ZodObject.lazycreate = (shape, params) => {
  return new ZodObject(__spreadValues({
    shape,
    unknownKeys: "strip",
    catchall: ZodNever.create(),
    typeName: ZodFirstPartyTypeKind.ZodObject
  }, processCreateParams(params)));
};
var ZodUnion = class extends ZodType {
  _parse(input) {
    const { ctx } = this._processInputParams(input);
    const options = this._def.options;
    function handleResults(results) {
      for (const result2 of results) {
        if (result2.result.status === "valid") {
          return result2.result;
        }
      }
      for (const result2 of results) {
        if (result2.result.status === "dirty") {
          ctx.common.issues.push(...result2.ctx.common.issues);
          return result2.result;
        }
      }
      const unionErrors = results.map((result2) => new ZodError(result2.ctx.common.issues));
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_union,
        unionErrors
      });
      return INVALID;
    }
    if (ctx.common.async) {
      return Promise.all(options.map((option) => __async(this, null, function* () {
        const childCtx = __spreadProps(__spreadValues({}, ctx), {
          common: __spreadProps(__spreadValues({}, ctx.common), {
            issues: []
          }),
          parent: null
        });
        return {
          result: yield option._parseAsync({
            data: ctx.data,
            path: ctx.path,
            parent: childCtx
          }),
          ctx: childCtx
        };
      }))).then(handleResults);
    } else {
      let dirty = void 0;
      const issues = [];
      for (const option of options) {
        const childCtx = __spreadProps(__spreadValues({}, ctx), {
          common: __spreadProps(__spreadValues({}, ctx.common), {
            issues: []
          }),
          parent: null
        });
        const result2 = option._parseSync({
          data: ctx.data,
          path: ctx.path,
          parent: childCtx
        });
        if (result2.status === "valid") {
          return result2;
        } else if (result2.status === "dirty" && !dirty) {
          dirty = { result: result2, ctx: childCtx };
        }
        if (childCtx.common.issues.length) {
          issues.push(childCtx.common.issues);
        }
      }
      if (dirty) {
        ctx.common.issues.push(...dirty.ctx.common.issues);
        return dirty.result;
      }
      const unionErrors = issues.map((issues2) => new ZodError(issues2));
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_union,
        unionErrors
      });
      return INVALID;
    }
  }
  get options() {
    return this._def.options;
  }
};
ZodUnion.create = (types, params) => {
  return new ZodUnion(__spreadValues({
    options: types,
    typeName: ZodFirstPartyTypeKind.ZodUnion
  }, processCreateParams(params)));
};
var getDiscriminator = (type) => {
  if (type instanceof ZodLazy) {
    return getDiscriminator(type.schema);
  } else if (type instanceof ZodEffects) {
    return getDiscriminator(type.innerType());
  } else if (type instanceof ZodLiteral) {
    return [type.value];
  } else if (type instanceof ZodEnum) {
    return type.options;
  } else if (type instanceof ZodNativeEnum) {
    return Object.keys(type.enum);
  } else if (type instanceof ZodDefault) {
    return getDiscriminator(type._def.innerType);
  } else if (type instanceof ZodUndefined) {
    return [void 0];
  } else if (type instanceof ZodNull) {
    return [null];
  } else {
    return null;
  }
};
var ZodDiscriminatedUnion = class extends ZodType {
  _parse(input) {
    const { ctx } = this._processInputParams(input);
    if (ctx.parsedType !== ZodParsedType.object) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.object,
        received: ctx.parsedType
      });
      return INVALID;
    }
    const discriminator = this.discriminator;
    const discriminatorValue = ctx.data[discriminator];
    const option = this.optionsMap.get(discriminatorValue);
    if (!option) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_union_discriminator,
        options: Array.from(this.optionsMap.keys()),
        path: [discriminator]
      });
      return INVALID;
    }
    if (ctx.common.async) {
      return option._parseAsync({
        data: ctx.data,
        path: ctx.path,
        parent: ctx
      });
    } else {
      return option._parseSync({
        data: ctx.data,
        path: ctx.path,
        parent: ctx
      });
    }
  }
  get discriminator() {
    return this._def.discriminator;
  }
  get options() {
    return this._def.options;
  }
  get optionsMap() {
    return this._def.optionsMap;
  }
  /**
   * The constructor of the discriminated union schema. Its behaviour is very similar to that of the normal z.union() constructor.
   * However, it only allows a union of objects, all of which need to share a discriminator property. This property must
   * have a different value for each object in the union.
   * @param discriminator the name of the discriminator property
   * @param types an array of object schemas
   * @param params
   */
  static create(discriminator, options, params) {
    const optionsMap = /* @__PURE__ */ new Map();
    for (const type of options) {
      const discriminatorValues = getDiscriminator(type.shape[discriminator]);
      if (!discriminatorValues) {
        throw new Error(`A discriminator value for key \`${discriminator}\` could not be extracted from all schema options`);
      }
      for (const value of discriminatorValues) {
        if (optionsMap.has(value)) {
          throw new Error(`Discriminator property ${String(discriminator)} has duplicate value ${String(value)}`);
        }
        optionsMap.set(value, type);
      }
    }
    return new ZodDiscriminatedUnion(__spreadValues({
      typeName: ZodFirstPartyTypeKind.ZodDiscriminatedUnion,
      discriminator,
      options,
      optionsMap
    }, processCreateParams(params)));
  }
};
function mergeValues(a, b) {
  const aType = getParsedType(a);
  const bType = getParsedType(b);
  if (a === b) {
    return { valid: true, data: a };
  } else if (aType === ZodParsedType.object && bType === ZodParsedType.object) {
    const bKeys = util.objectKeys(b);
    const sharedKeys = util.objectKeys(a).filter((key) => bKeys.indexOf(key) !== -1);
    const newObj = __spreadValues(__spreadValues({}, a), b);
    for (const key of sharedKeys) {
      const sharedValue = mergeValues(a[key], b[key]);
      if (!sharedValue.valid) {
        return { valid: false };
      }
      newObj[key] = sharedValue.data;
    }
    return { valid: true, data: newObj };
  } else if (aType === ZodParsedType.array && bType === ZodParsedType.array) {
    if (a.length !== b.length) {
      return { valid: false };
    }
    const newArray = [];
    for (let index = 0; index < a.length; index++) {
      const itemA = a[index];
      const itemB = b[index];
      const sharedValue = mergeValues(itemA, itemB);
      if (!sharedValue.valid) {
        return { valid: false };
      }
      newArray.push(sharedValue.data);
    }
    return { valid: true, data: newArray };
  } else if (aType === ZodParsedType.date && bType === ZodParsedType.date && +a === +b) {
    return { valid: true, data: a };
  } else {
    return { valid: false };
  }
}
var ZodIntersection = class extends ZodType {
  _parse(input) {
    const { status, ctx } = this._processInputParams(input);
    const handleParsed = (parsedLeft, parsedRight) => {
      if (isAborted(parsedLeft) || isAborted(parsedRight)) {
        return INVALID;
      }
      const merged = mergeValues(parsedLeft.value, parsedRight.value);
      if (!merged.valid) {
        addIssueToContext(ctx, {
          code: ZodIssueCode.invalid_intersection_types
        });
        return INVALID;
      }
      if (isDirty(parsedLeft) || isDirty(parsedRight)) {
        status.dirty();
      }
      return { status: status.value, value: merged.data };
    };
    if (ctx.common.async) {
      return Promise.all([
        this._def.left._parseAsync({
          data: ctx.data,
          path: ctx.path,
          parent: ctx
        }),
        this._def.right._parseAsync({
          data: ctx.data,
          path: ctx.path,
          parent: ctx
        })
      ]).then(([left, right]) => handleParsed(left, right));
    } else {
      return handleParsed(this._def.left._parseSync({
        data: ctx.data,
        path: ctx.path,
        parent: ctx
      }), this._def.right._parseSync({
        data: ctx.data,
        path: ctx.path,
        parent: ctx
      }));
    }
  }
};
ZodIntersection.create = (left, right, params) => {
  return new ZodIntersection(__spreadValues({
    left,
    right,
    typeName: ZodFirstPartyTypeKind.ZodIntersection
  }, processCreateParams(params)));
};
var ZodTuple = class extends ZodType {
  _parse(input) {
    const { status, ctx } = this._processInputParams(input);
    if (ctx.parsedType !== ZodParsedType.array) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.array,
        received: ctx.parsedType
      });
      return INVALID;
    }
    if (ctx.data.length < this._def.items.length) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.too_small,
        minimum: this._def.items.length,
        inclusive: true,
        exact: false,
        type: "array"
      });
      return INVALID;
    }
    const rest = this._def.rest;
    if (!rest && ctx.data.length > this._def.items.length) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.too_big,
        maximum: this._def.items.length,
        inclusive: true,
        exact: false,
        type: "array"
      });
      status.dirty();
    }
    const items = [...ctx.data].map((item, itemIndex) => {
      const schema = this._def.items[itemIndex] || this._def.rest;
      if (!schema)
        return null;
      return schema._parse(new ParseInputLazyPath(ctx, item, ctx.path, itemIndex));
    }).filter((x) => !!x);
    if (ctx.common.async) {
      return Promise.all(items).then((results) => {
        return ParseStatus.mergeArray(status, results);
      });
    } else {
      return ParseStatus.mergeArray(status, items);
    }
  }
  get items() {
    return this._def.items;
  }
  rest(rest) {
    return new ZodTuple(__spreadProps(__spreadValues({}, this._def), {
      rest
    }));
  }
};
ZodTuple.create = (schemas, params) => {
  if (!Array.isArray(schemas)) {
    throw new Error("You must pass an array of schemas to z.tuple([ ... ])");
  }
  return new ZodTuple(__spreadValues({
    items: schemas,
    typeName: ZodFirstPartyTypeKind.ZodTuple,
    rest: null
  }, processCreateParams(params)));
};
var ZodRecord = class extends ZodType {
  get keySchema() {
    return this._def.keyType;
  }
  get valueSchema() {
    return this._def.valueType;
  }
  _parse(input) {
    const { status, ctx } = this._processInputParams(input);
    if (ctx.parsedType !== ZodParsedType.object) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.object,
        received: ctx.parsedType
      });
      return INVALID;
    }
    const pairs = [];
    const keyType = this._def.keyType;
    const valueType = this._def.valueType;
    for (const key in ctx.data) {
      pairs.push({
        key: keyType._parse(new ParseInputLazyPath(ctx, key, ctx.path, key)),
        value: valueType._parse(new ParseInputLazyPath(ctx, ctx.data[key], ctx.path, key))
      });
    }
    if (ctx.common.async) {
      return ParseStatus.mergeObjectAsync(status, pairs);
    } else {
      return ParseStatus.mergeObjectSync(status, pairs);
    }
  }
  get element() {
    return this._def.valueType;
  }
  static create(first, second, third) {
    if (second instanceof ZodType) {
      return new ZodRecord(__spreadValues({
        keyType: first,
        valueType: second,
        typeName: ZodFirstPartyTypeKind.ZodRecord
      }, processCreateParams(third)));
    }
    return new ZodRecord(__spreadValues({
      keyType: ZodString.create(),
      valueType: first,
      typeName: ZodFirstPartyTypeKind.ZodRecord
    }, processCreateParams(second)));
  }
};
var ZodMap = class extends ZodType {
  get keySchema() {
    return this._def.keyType;
  }
  get valueSchema() {
    return this._def.valueType;
  }
  _parse(input) {
    const { status, ctx } = this._processInputParams(input);
    if (ctx.parsedType !== ZodParsedType.map) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.map,
        received: ctx.parsedType
      });
      return INVALID;
    }
    const keyType = this._def.keyType;
    const valueType = this._def.valueType;
    const pairs = [...ctx.data.entries()].map(([key, value], index) => {
      return {
        key: keyType._parse(new ParseInputLazyPath(ctx, key, ctx.path, [index, "key"])),
        value: valueType._parse(new ParseInputLazyPath(ctx, value, ctx.path, [index, "value"]))
      };
    });
    if (ctx.common.async) {
      const finalMap = /* @__PURE__ */ new Map();
      return Promise.resolve().then(() => __async(this, null, function* () {
        for (const pair of pairs) {
          const key = yield pair.key;
          const value = yield pair.value;
          if (key.status === "aborted" || value.status === "aborted") {
            return INVALID;
          }
          if (key.status === "dirty" || value.status === "dirty") {
            status.dirty();
          }
          finalMap.set(key.value, value.value);
        }
        return { status: status.value, value: finalMap };
      }));
    } else {
      const finalMap = /* @__PURE__ */ new Map();
      for (const pair of pairs) {
        const key = pair.key;
        const value = pair.value;
        if (key.status === "aborted" || value.status === "aborted") {
          return INVALID;
        }
        if (key.status === "dirty" || value.status === "dirty") {
          status.dirty();
        }
        finalMap.set(key.value, value.value);
      }
      return { status: status.value, value: finalMap };
    }
  }
};
ZodMap.create = (keyType, valueType, params) => {
  return new ZodMap(__spreadValues({
    valueType,
    keyType,
    typeName: ZodFirstPartyTypeKind.ZodMap
  }, processCreateParams(params)));
};
var ZodSet = class extends ZodType {
  _parse(input) {
    const { status, ctx } = this._processInputParams(input);
    if (ctx.parsedType !== ZodParsedType.set) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.set,
        received: ctx.parsedType
      });
      return INVALID;
    }
    const def = this._def;
    if (def.minSize !== null) {
      if (ctx.data.size < def.minSize.value) {
        addIssueToContext(ctx, {
          code: ZodIssueCode.too_small,
          minimum: def.minSize.value,
          type: "set",
          inclusive: true,
          exact: false,
          message: def.minSize.message
        });
        status.dirty();
      }
    }
    if (def.maxSize !== null) {
      if (ctx.data.size > def.maxSize.value) {
        addIssueToContext(ctx, {
          code: ZodIssueCode.too_big,
          maximum: def.maxSize.value,
          type: "set",
          inclusive: true,
          exact: false,
          message: def.maxSize.message
        });
        status.dirty();
      }
    }
    const valueType = this._def.valueType;
    function finalizeSet(elements2) {
      const parsedSet = /* @__PURE__ */ new Set();
      for (const element of elements2) {
        if (element.status === "aborted")
          return INVALID;
        if (element.status === "dirty")
          status.dirty();
        parsedSet.add(element.value);
      }
      return { status: status.value, value: parsedSet };
    }
    const elements = [...ctx.data.values()].map((item, i) => valueType._parse(new ParseInputLazyPath(ctx, item, ctx.path, i)));
    if (ctx.common.async) {
      return Promise.all(elements).then((elements2) => finalizeSet(elements2));
    } else {
      return finalizeSet(elements);
    }
  }
  min(minSize, message) {
    return new ZodSet(__spreadProps(__spreadValues({}, this._def), {
      minSize: { value: minSize, message: errorUtil.toString(message) }
    }));
  }
  max(maxSize, message) {
    return new ZodSet(__spreadProps(__spreadValues({}, this._def), {
      maxSize: { value: maxSize, message: errorUtil.toString(message) }
    }));
  }
  size(size, message) {
    return this.min(size, message).max(size, message);
  }
  nonempty(message) {
    return this.min(1, message);
  }
};
ZodSet.create = (valueType, params) => {
  return new ZodSet(__spreadValues({
    valueType,
    minSize: null,
    maxSize: null,
    typeName: ZodFirstPartyTypeKind.ZodSet
  }, processCreateParams(params)));
};
var ZodFunction = class extends ZodType {
  constructor() {
    super(...arguments);
    this.validate = this.implement;
  }
  _parse(input) {
    const { ctx } = this._processInputParams(input);
    if (ctx.parsedType !== ZodParsedType.function) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.function,
        received: ctx.parsedType
      });
      return INVALID;
    }
    function makeArgsIssue(args, error) {
      return makeIssue({
        data: args,
        path: ctx.path,
        errorMaps: [
          ctx.common.contextualErrorMap,
          ctx.schemaErrorMap,
          getErrorMap(),
          errorMap
        ].filter((x) => !!x),
        issueData: {
          code: ZodIssueCode.invalid_arguments,
          argumentsError: error
        }
      });
    }
    function makeReturnsIssue(returns, error) {
      return makeIssue({
        data: returns,
        path: ctx.path,
        errorMaps: [
          ctx.common.contextualErrorMap,
          ctx.schemaErrorMap,
          getErrorMap(),
          errorMap
        ].filter((x) => !!x),
        issueData: {
          code: ZodIssueCode.invalid_return_type,
          returnTypeError: error
        }
      });
    }
    const params = { errorMap: ctx.common.contextualErrorMap };
    const fn = ctx.data;
    if (this._def.returns instanceof ZodPromise) {
      const me = this;
      return OK(function(...args) {
        return __async(this, null, function* () {
          const error = new ZodError([]);
          const parsedArgs = yield me._def.args.parseAsync(args, params).catch((e) => {
            error.addIssue(makeArgsIssue(args, e));
            throw error;
          });
          const result2 = yield Reflect.apply(fn, this, parsedArgs);
          const parsedReturns = yield me._def.returns._def.type.parseAsync(result2, params).catch((e) => {
            error.addIssue(makeReturnsIssue(result2, e));
            throw error;
          });
          return parsedReturns;
        });
      });
    } else {
      const me = this;
      return OK(function(...args) {
        const parsedArgs = me._def.args.safeParse(args, params);
        if (!parsedArgs.success) {
          throw new ZodError([makeArgsIssue(args, parsedArgs.error)]);
        }
        const result2 = Reflect.apply(fn, this, parsedArgs.data);
        const parsedReturns = me._def.returns.safeParse(result2, params);
        if (!parsedReturns.success) {
          throw new ZodError([makeReturnsIssue(result2, parsedReturns.error)]);
        }
        return parsedReturns.data;
      });
    }
  }
  parameters() {
    return this._def.args;
  }
  returnType() {
    return this._def.returns;
  }
  args(...items) {
    return new ZodFunction(__spreadProps(__spreadValues({}, this._def), {
      args: ZodTuple.create(items).rest(ZodUnknown.create())
    }));
  }
  returns(returnType) {
    return new ZodFunction(__spreadProps(__spreadValues({}, this._def), {
      returns: returnType
    }));
  }
  implement(func) {
    const validatedFunc = this.parse(func);
    return validatedFunc;
  }
  strictImplement(func) {
    const validatedFunc = this.parse(func);
    return validatedFunc;
  }
  static create(args, returns, params) {
    return new ZodFunction(__spreadValues({
      args: args ? args : ZodTuple.create([]).rest(ZodUnknown.create()),
      returns: returns || ZodUnknown.create(),
      typeName: ZodFirstPartyTypeKind.ZodFunction
    }, processCreateParams(params)));
  }
};
var ZodLazy = class extends ZodType {
  get schema() {
    return this._def.getter();
  }
  _parse(input) {
    const { ctx } = this._processInputParams(input);
    const lazySchema = this._def.getter();
    return lazySchema._parse({ data: ctx.data, path: ctx.path, parent: ctx });
  }
};
ZodLazy.create = (getter, params) => {
  return new ZodLazy(__spreadValues({
    getter,
    typeName: ZodFirstPartyTypeKind.ZodLazy
  }, processCreateParams(params)));
};
var ZodLiteral = class extends ZodType {
  _parse(input) {
    if (input.data !== this._def.value) {
      const ctx = this._getOrReturnCtx(input);
      addIssueToContext(ctx, {
        received: ctx.data,
        code: ZodIssueCode.invalid_literal,
        expected: this._def.value
      });
      return INVALID;
    }
    return { status: "valid", value: input.data };
  }
  get value() {
    return this._def.value;
  }
};
ZodLiteral.create = (value, params) => {
  return new ZodLiteral(__spreadValues({
    value,
    typeName: ZodFirstPartyTypeKind.ZodLiteral
  }, processCreateParams(params)));
};
function createZodEnum(values, params) {
  return new ZodEnum(__spreadValues({
    values,
    typeName: ZodFirstPartyTypeKind.ZodEnum
  }, processCreateParams(params)));
}
var ZodEnum = class extends ZodType {
  _parse(input) {
    if (typeof input.data !== "string") {
      const ctx = this._getOrReturnCtx(input);
      const expectedValues = this._def.values;
      addIssueToContext(ctx, {
        expected: util.joinValues(expectedValues),
        received: ctx.parsedType,
        code: ZodIssueCode.invalid_type
      });
      return INVALID;
    }
    if (this._def.values.indexOf(input.data) === -1) {
      const ctx = this._getOrReturnCtx(input);
      const expectedValues = this._def.values;
      addIssueToContext(ctx, {
        received: ctx.data,
        code: ZodIssueCode.invalid_enum_value,
        options: expectedValues
      });
      return INVALID;
    }
    return OK(input.data);
  }
  get options() {
    return this._def.values;
  }
  get enum() {
    const enumValues = {};
    for (const val of this._def.values) {
      enumValues[val] = val;
    }
    return enumValues;
  }
  get Values() {
    const enumValues = {};
    for (const val of this._def.values) {
      enumValues[val] = val;
    }
    return enumValues;
  }
  get Enum() {
    const enumValues = {};
    for (const val of this._def.values) {
      enumValues[val] = val;
    }
    return enumValues;
  }
  extract(values) {
    return ZodEnum.create(values);
  }
  exclude(values) {
    return ZodEnum.create(this.options.filter((opt) => !values.includes(opt)));
  }
};
ZodEnum.create = createZodEnum;
var ZodNativeEnum = class extends ZodType {
  _parse(input) {
    const nativeEnumValues = util.getValidEnumValues(this._def.values);
    const ctx = this._getOrReturnCtx(input);
    if (ctx.parsedType !== ZodParsedType.string && ctx.parsedType !== ZodParsedType.number) {
      const expectedValues = util.objectValues(nativeEnumValues);
      addIssueToContext(ctx, {
        expected: util.joinValues(expectedValues),
        received: ctx.parsedType,
        code: ZodIssueCode.invalid_type
      });
      return INVALID;
    }
    if (nativeEnumValues.indexOf(input.data) === -1) {
      const expectedValues = util.objectValues(nativeEnumValues);
      addIssueToContext(ctx, {
        received: ctx.data,
        code: ZodIssueCode.invalid_enum_value,
        options: expectedValues
      });
      return INVALID;
    }
    return OK(input.data);
  }
  get enum() {
    return this._def.values;
  }
};
ZodNativeEnum.create = (values, params) => {
  return new ZodNativeEnum(__spreadValues({
    values,
    typeName: ZodFirstPartyTypeKind.ZodNativeEnum
  }, processCreateParams(params)));
};
var ZodPromise = class extends ZodType {
  unwrap() {
    return this._def.type;
  }
  _parse(input) {
    const { ctx } = this._processInputParams(input);
    if (ctx.parsedType !== ZodParsedType.promise && ctx.common.async === false) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.promise,
        received: ctx.parsedType
      });
      return INVALID;
    }
    const promisified = ctx.parsedType === ZodParsedType.promise ? ctx.data : Promise.resolve(ctx.data);
    return OK(promisified.then((data) => {
      return this._def.type.parseAsync(data, {
        path: ctx.path,
        errorMap: ctx.common.contextualErrorMap
      });
    }));
  }
};
ZodPromise.create = (schema, params) => {
  return new ZodPromise(__spreadValues({
    type: schema,
    typeName: ZodFirstPartyTypeKind.ZodPromise
  }, processCreateParams(params)));
};
var ZodEffects = class extends ZodType {
  innerType() {
    return this._def.schema;
  }
  sourceType() {
    return this._def.schema._def.typeName === ZodFirstPartyTypeKind.ZodEffects ? this._def.schema.sourceType() : this._def.schema;
  }
  _parse(input) {
    const { status, ctx } = this._processInputParams(input);
    const effect = this._def.effect || null;
    const checkCtx = {
      addIssue: (arg) => {
        addIssueToContext(ctx, arg);
        if (arg.fatal) {
          status.abort();
        } else {
          status.dirty();
        }
      },
      get path() {
        return ctx.path;
      }
    };
    checkCtx.addIssue = checkCtx.addIssue.bind(checkCtx);
    if (effect.type === "preprocess") {
      const processed = effect.transform(ctx.data, checkCtx);
      if (ctx.common.issues.length) {
        return {
          status: "dirty",
          value: ctx.data
        };
      }
      if (ctx.common.async) {
        return Promise.resolve(processed).then((processed2) => {
          return this._def.schema._parseAsync({
            data: processed2,
            path: ctx.path,
            parent: ctx
          });
        });
      } else {
        return this._def.schema._parseSync({
          data: processed,
          path: ctx.path,
          parent: ctx
        });
      }
    }
    if (effect.type === "refinement") {
      const executeRefinement = (acc) => {
        const result2 = effect.refinement(acc, checkCtx);
        if (ctx.common.async) {
          return Promise.resolve(result2);
        }
        if (result2 instanceof Promise) {
          throw new Error("Async refinement encountered during synchronous parse operation. Use .parseAsync instead.");
        }
        return acc;
      };
      if (ctx.common.async === false) {
        const inner = this._def.schema._parseSync({
          data: ctx.data,
          path: ctx.path,
          parent: ctx
        });
        if (inner.status === "aborted")
          return INVALID;
        if (inner.status === "dirty")
          status.dirty();
        executeRefinement(inner.value);
        return { status: status.value, value: inner.value };
      } else {
        return this._def.schema._parseAsync({ data: ctx.data, path: ctx.path, parent: ctx }).then((inner) => {
          if (inner.status === "aborted")
            return INVALID;
          if (inner.status === "dirty")
            status.dirty();
          return executeRefinement(inner.value).then(() => {
            return { status: status.value, value: inner.value };
          });
        });
      }
    }
    if (effect.type === "transform") {
      if (ctx.common.async === false) {
        const base = this._def.schema._parseSync({
          data: ctx.data,
          path: ctx.path,
          parent: ctx
        });
        if (!isValid(base))
          return base;
        const result2 = effect.transform(base.value, checkCtx);
        if (result2 instanceof Promise) {
          throw new Error(`Asynchronous transform encountered during synchronous parse operation. Use .parseAsync instead.`);
        }
        return { status: status.value, value: result2 };
      } else {
        return this._def.schema._parseAsync({ data: ctx.data, path: ctx.path, parent: ctx }).then((base) => {
          if (!isValid(base))
            return base;
          return Promise.resolve(effect.transform(base.value, checkCtx)).then((result2) => ({ status: status.value, value: result2 }));
        });
      }
    }
    util.assertNever(effect);
  }
};
ZodEffects.create = (schema, effect, params) => {
  return new ZodEffects(__spreadValues({
    schema,
    typeName: ZodFirstPartyTypeKind.ZodEffects,
    effect
  }, processCreateParams(params)));
};
ZodEffects.createWithPreprocess = (preprocess, schema, params) => {
  return new ZodEffects(__spreadValues({
    schema,
    effect: { type: "preprocess", transform: preprocess },
    typeName: ZodFirstPartyTypeKind.ZodEffects
  }, processCreateParams(params)));
};
var ZodOptional = class extends ZodType {
  _parse(input) {
    const parsedType = this._getType(input);
    if (parsedType === ZodParsedType.undefined) {
      return OK(void 0);
    }
    return this._def.innerType._parse(input);
  }
  unwrap() {
    return this._def.innerType;
  }
};
ZodOptional.create = (type, params) => {
  return new ZodOptional(__spreadValues({
    innerType: type,
    typeName: ZodFirstPartyTypeKind.ZodOptional
  }, processCreateParams(params)));
};
var ZodNullable = class extends ZodType {
  _parse(input) {
    const parsedType = this._getType(input);
    if (parsedType === ZodParsedType.null) {
      return OK(null);
    }
    return this._def.innerType._parse(input);
  }
  unwrap() {
    return this._def.innerType;
  }
};
ZodNullable.create = (type, params) => {
  return new ZodNullable(__spreadValues({
    innerType: type,
    typeName: ZodFirstPartyTypeKind.ZodNullable
  }, processCreateParams(params)));
};
var ZodDefault = class extends ZodType {
  _parse(input) {
    const { ctx } = this._processInputParams(input);
    let data = ctx.data;
    if (ctx.parsedType === ZodParsedType.undefined) {
      data = this._def.defaultValue();
    }
    return this._def.innerType._parse({
      data,
      path: ctx.path,
      parent: ctx
    });
  }
  removeDefault() {
    return this._def.innerType;
  }
};
ZodDefault.create = (type, params) => {
  return new ZodDefault(__spreadValues({
    innerType: type,
    typeName: ZodFirstPartyTypeKind.ZodDefault,
    defaultValue: typeof params.default === "function" ? params.default : () => params.default
  }, processCreateParams(params)));
};
var ZodCatch = class extends ZodType {
  _parse(input) {
    const { ctx } = this._processInputParams(input);
    const newCtx = __spreadProps(__spreadValues({}, ctx), {
      common: __spreadProps(__spreadValues({}, ctx.common), {
        issues: []
      })
    });
    const result2 = this._def.innerType._parse({
      data: newCtx.data,
      path: newCtx.path,
      parent: __spreadValues({}, newCtx)
    });
    if (isAsync(result2)) {
      return result2.then((result3) => {
        return {
          status: "valid",
          value: result3.status === "valid" ? result3.value : this._def.catchValue({
            get error() {
              return new ZodError(newCtx.common.issues);
            },
            input: newCtx.data
          })
        };
      });
    } else {
      return {
        status: "valid",
        value: result2.status === "valid" ? result2.value : this._def.catchValue({
          get error() {
            return new ZodError(newCtx.common.issues);
          },
          input: newCtx.data
        })
      };
    }
  }
  removeCatch() {
    return this._def.innerType;
  }
};
ZodCatch.create = (type, params) => {
  return new ZodCatch(__spreadValues({
    innerType: type,
    typeName: ZodFirstPartyTypeKind.ZodCatch,
    catchValue: typeof params.catch === "function" ? params.catch : () => params.catch
  }, processCreateParams(params)));
};
var ZodNaN = class extends ZodType {
  _parse(input) {
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.nan) {
      const ctx = this._getOrReturnCtx(input);
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.nan,
        received: ctx.parsedType
      });
      return INVALID;
    }
    return { status: "valid", value: input.data };
  }
};
ZodNaN.create = (params) => {
  return new ZodNaN(__spreadValues({
    typeName: ZodFirstPartyTypeKind.ZodNaN
  }, processCreateParams(params)));
};
var BRAND = Symbol("zod_brand");
var ZodBranded = class extends ZodType {
  _parse(input) {
    const { ctx } = this._processInputParams(input);
    const data = ctx.data;
    return this._def.type._parse({
      data,
      path: ctx.path,
      parent: ctx
    });
  }
  unwrap() {
    return this._def.type;
  }
};
var ZodPipeline = class extends ZodType {
  _parse(input) {
    const { status, ctx } = this._processInputParams(input);
    if (ctx.common.async) {
      const handleAsync = () => __async(this, null, function* () {
        const inResult = yield this._def.in._parseAsync({
          data: ctx.data,
          path: ctx.path,
          parent: ctx
        });
        if (inResult.status === "aborted")
          return INVALID;
        if (inResult.status === "dirty") {
          status.dirty();
          return DIRTY(inResult.value);
        } else {
          return this._def.out._parseAsync({
            data: inResult.value,
            path: ctx.path,
            parent: ctx
          });
        }
      });
      return handleAsync();
    } else {
      const inResult = this._def.in._parseSync({
        data: ctx.data,
        path: ctx.path,
        parent: ctx
      });
      if (inResult.status === "aborted")
        return INVALID;
      if (inResult.status === "dirty") {
        status.dirty();
        return {
          status: "dirty",
          value: inResult.value
        };
      } else {
        return this._def.out._parseSync({
          data: inResult.value,
          path: ctx.path,
          parent: ctx
        });
      }
    }
  }
  static create(a, b) {
    return new ZodPipeline({
      in: a,
      out: b,
      typeName: ZodFirstPartyTypeKind.ZodPipeline
    });
  }
};
var ZodReadonly = class extends ZodType {
  _parse(input) {
    const result2 = this._def.innerType._parse(input);
    if (isValid(result2)) {
      result2.value = Object.freeze(result2.value);
    }
    return result2;
  }
};
ZodReadonly.create = (type, params) => {
  return new ZodReadonly(__spreadValues({
    innerType: type,
    typeName: ZodFirstPartyTypeKind.ZodReadonly
  }, processCreateParams(params)));
};
var custom = (check, params = {}, fatal) => {
  if (check)
    return ZodAny.create().superRefine((data, ctx) => {
      var _a, _b;
      if (!check(data)) {
        const p = typeof params === "function" ? params(data) : typeof params === "string" ? { message: params } : params;
        const _fatal = (_b = (_a = p.fatal) !== null && _a !== void 0 ? _a : fatal) !== null && _b !== void 0 ? _b : true;
        const p2 = typeof p === "string" ? { message: p } : p;
        ctx.addIssue(__spreadProps(__spreadValues({ code: "custom" }, p2), { fatal: _fatal }));
      }
    });
  return ZodAny.create();
};
var late = {
  object: ZodObject.lazycreate
};
var ZodFirstPartyTypeKind;
(function(ZodFirstPartyTypeKind2) {
  ZodFirstPartyTypeKind2["ZodString"] = "ZodString";
  ZodFirstPartyTypeKind2["ZodNumber"] = "ZodNumber";
  ZodFirstPartyTypeKind2["ZodNaN"] = "ZodNaN";
  ZodFirstPartyTypeKind2["ZodBigInt"] = "ZodBigInt";
  ZodFirstPartyTypeKind2["ZodBoolean"] = "ZodBoolean";
  ZodFirstPartyTypeKind2["ZodDate"] = "ZodDate";
  ZodFirstPartyTypeKind2["ZodSymbol"] = "ZodSymbol";
  ZodFirstPartyTypeKind2["ZodUndefined"] = "ZodUndefined";
  ZodFirstPartyTypeKind2["ZodNull"] = "ZodNull";
  ZodFirstPartyTypeKind2["ZodAny"] = "ZodAny";
  ZodFirstPartyTypeKind2["ZodUnknown"] = "ZodUnknown";
  ZodFirstPartyTypeKind2["ZodNever"] = "ZodNever";
  ZodFirstPartyTypeKind2["ZodVoid"] = "ZodVoid";
  ZodFirstPartyTypeKind2["ZodArray"] = "ZodArray";
  ZodFirstPartyTypeKind2["ZodObject"] = "ZodObject";
  ZodFirstPartyTypeKind2["ZodUnion"] = "ZodUnion";
  ZodFirstPartyTypeKind2["ZodDiscriminatedUnion"] = "ZodDiscriminatedUnion";
  ZodFirstPartyTypeKind2["ZodIntersection"] = "ZodIntersection";
  ZodFirstPartyTypeKind2["ZodTuple"] = "ZodTuple";
  ZodFirstPartyTypeKind2["ZodRecord"] = "ZodRecord";
  ZodFirstPartyTypeKind2["ZodMap"] = "ZodMap";
  ZodFirstPartyTypeKind2["ZodSet"] = "ZodSet";
  ZodFirstPartyTypeKind2["ZodFunction"] = "ZodFunction";
  ZodFirstPartyTypeKind2["ZodLazy"] = "ZodLazy";
  ZodFirstPartyTypeKind2["ZodLiteral"] = "ZodLiteral";
  ZodFirstPartyTypeKind2["ZodEnum"] = "ZodEnum";
  ZodFirstPartyTypeKind2["ZodEffects"] = "ZodEffects";
  ZodFirstPartyTypeKind2["ZodNativeEnum"] = "ZodNativeEnum";
  ZodFirstPartyTypeKind2["ZodOptional"] = "ZodOptional";
  ZodFirstPartyTypeKind2["ZodNullable"] = "ZodNullable";
  ZodFirstPartyTypeKind2["ZodDefault"] = "ZodDefault";
  ZodFirstPartyTypeKind2["ZodCatch"] = "ZodCatch";
  ZodFirstPartyTypeKind2["ZodPromise"] = "ZodPromise";
  ZodFirstPartyTypeKind2["ZodBranded"] = "ZodBranded";
  ZodFirstPartyTypeKind2["ZodPipeline"] = "ZodPipeline";
  ZodFirstPartyTypeKind2["ZodReadonly"] = "ZodReadonly";
})(ZodFirstPartyTypeKind || (ZodFirstPartyTypeKind = {}));
var instanceOfType = (cls, params = {
  message: `Input not instance of ${cls.name}`
}) => custom((data) => data instanceof cls, params);
var stringType = ZodString.create;
var numberType = ZodNumber.create;
var nanType = ZodNaN.create;
var bigIntType = ZodBigInt.create;
var booleanType = ZodBoolean.create;
var dateType = ZodDate.create;
var symbolType = ZodSymbol.create;
var undefinedType = ZodUndefined.create;
var nullType = ZodNull.create;
var anyType = ZodAny.create;
var unknownType = ZodUnknown.create;
var neverType = ZodNever.create;
var voidType = ZodVoid.create;
var arrayType = ZodArray.create;
var objectType = ZodObject.create;
var strictObjectType = ZodObject.strictCreate;
var unionType = ZodUnion.create;
var discriminatedUnionType = ZodDiscriminatedUnion.create;
var intersectionType = ZodIntersection.create;
var tupleType = ZodTuple.create;
var recordType = ZodRecord.create;
var mapType = ZodMap.create;
var setType = ZodSet.create;
var functionType = ZodFunction.create;
var lazyType = ZodLazy.create;
var literalType = ZodLiteral.create;
var enumType = ZodEnum.create;
var nativeEnumType = ZodNativeEnum.create;
var promiseType = ZodPromise.create;
var effectsType = ZodEffects.create;
var optionalType = ZodOptional.create;
var nullableType = ZodNullable.create;
var preprocessType = ZodEffects.createWithPreprocess;
var pipelineType = ZodPipeline.create;
var ostring = () => stringType().optional();
var onumber = () => numberType().optional();
var oboolean = () => booleanType().optional();
var coerce = {
  string: (arg) => ZodString.create(__spreadProps(__spreadValues({}, arg), { coerce: true })),
  number: (arg) => ZodNumber.create(__spreadProps(__spreadValues({}, arg), { coerce: true })),
  boolean: (arg) => ZodBoolean.create(__spreadProps(__spreadValues({}, arg), {
    coerce: true
  })),
  bigint: (arg) => ZodBigInt.create(__spreadProps(__spreadValues({}, arg), { coerce: true })),
  date: (arg) => ZodDate.create(__spreadProps(__spreadValues({}, arg), { coerce: true }))
};
var NEVER = INVALID;
var z = /* @__PURE__ */ Object.freeze({
  __proto__: null,
  defaultErrorMap: errorMap,
  setErrorMap,
  getErrorMap,
  makeIssue,
  EMPTY_PATH,
  addIssueToContext,
  ParseStatus,
  INVALID,
  DIRTY,
  OK,
  isAborted,
  isDirty,
  isValid,
  isAsync,
  get util() {
    return util;
  },
  get objectUtil() {
    return objectUtil;
  },
  ZodParsedType,
  getParsedType,
  ZodType,
  ZodString,
  ZodNumber,
  ZodBigInt,
  ZodBoolean,
  ZodDate,
  ZodSymbol,
  ZodUndefined,
  ZodNull,
  ZodAny,
  ZodUnknown,
  ZodNever,
  ZodVoid,
  ZodArray,
  ZodObject,
  ZodUnion,
  ZodDiscriminatedUnion,
  ZodIntersection,
  ZodTuple,
  ZodRecord,
  ZodMap,
  ZodSet,
  ZodFunction,
  ZodLazy,
  ZodLiteral,
  ZodEnum,
  ZodNativeEnum,
  ZodPromise,
  ZodEffects,
  ZodTransformer: ZodEffects,
  ZodOptional,
  ZodNullable,
  ZodDefault,
  ZodCatch,
  ZodNaN,
  BRAND,
  ZodBranded,
  ZodPipeline,
  ZodReadonly,
  custom,
  Schema: ZodType,
  ZodSchema: ZodType,
  late,
  get ZodFirstPartyTypeKind() {
    return ZodFirstPartyTypeKind;
  },
  coerce,
  any: anyType,
  array: arrayType,
  bigint: bigIntType,
  boolean: booleanType,
  date: dateType,
  discriminatedUnion: discriminatedUnionType,
  effect: effectsType,
  "enum": enumType,
  "function": functionType,
  "instanceof": instanceOfType,
  intersection: intersectionType,
  lazy: lazyType,
  literal: literalType,
  map: mapType,
  nan: nanType,
  nativeEnum: nativeEnumType,
  never: neverType,
  "null": nullType,
  nullable: nullableType,
  number: numberType,
  object: objectType,
  oboolean,
  onumber,
  optional: optionalType,
  ostring,
  pipeline: pipelineType,
  preprocess: preprocessType,
  promise: promiseType,
  record: recordType,
  set: setType,
  strictObject: strictObjectType,
  string: stringType,
  symbol: symbolType,
  transformer: effectsType,
  tuple: tupleType,
  "undefined": undefinedType,
  union: unionType,
  unknown: unknownType,
  "void": voidType,
  NEVER,
  ZodIssueCode,
  quotelessJson,
  ZodError
});

// ../schemas/auth/login.ts
var ValidEmailSchema = z.string().email();

// ../schemas/accounting/chart-of-accounts/base.ts
var ChartOfAccountTypeList = ["ASSET", "LIABILITY", "REVENUE", "EXPENSE", "DRAWING"];
var ChartOfAccountTypeEnumObject = z.enum(ChartOfAccountTypeList);
var ChartOfAccountTypeEnum = ChartOfAccountTypeEnumObject.enum;
var accountTypeIds = {
  [ChartOfAccountTypeEnum.ASSET]: 1,
  [ChartOfAccountTypeEnum.LIABILITY]: 2,
  [ChartOfAccountTypeEnum.EXPENSE]: 5,
  [ChartOfAccountTypeEnum.REVENUE]: 4,
  [ChartOfAccountTypeEnum.DRAWING]: 3
};
var ChartOfAccount = objectType({
  id: stringType().min(1, "id is required"),
  isDefault: booleanType().default(false),
  paymentPossible: booleanType().default(false),
  isCashAccount: booleanType().default(false),
  accountNumber: stringType().min(1, "account number is required"),
  accountName: stringType().min(1, "account name is required"),
  description: stringType().optional().nullable(),
  balance: coerce.number().min(0, "balance can not be less than 0"),
  active: booleanType(),
  createdBy: stringType().min(1, "created by is required"),
  accountType: ChartOfAccountTypeEnumObject,
  createdAt: stringType().min(1, "created date is required"),
  updatedAt: stringType().min(1, "updated date is required")
});

// ../schemas/accounting/chart-of-accounts/fe-schema.ts
var CreateChartOfAccountsSchemaFE = ChartOfAccount.pick({
  accountName: true,
  active: true,
  description: true,
  paymentPossible: true,
  isCashAccount: true
}).extend({
  accountType: objectType({
    label: stringType().min(1, "Account type label is required"),
    value: ChartOfAccountTypeEnumObject
  })
});
var UpdateChartOfAccountsSchemaFE = ChartOfAccount.pick({
  accountName: true,
  active: true,
  paymentPossible: true,
  description: true,
  isCashAccount: true
}).partial();
var chartOfAccountDefaultValues = {
  accountName: "",
  accountType: { label: "Asset", value: ChartOfAccountTypeEnum.ASSET },
  active: true,
  paymentPossible: false,
  isCashAccount: true,
  // balance: 0,
  description: ""
};

// ../schemas/accounting/chart-of-accounts/be-schema.ts
var CreateChartOfAccountsSchemaBE = ChartOfAccount.pick({
  accountName: true,
  accountType: true,
  active: true,
  isCashAccount: true
}).extend({
  balance: numberType().optional(),
  isCashAccount: booleanType().optional()
});

// ../schemas/accounting/transactions/base.ts
var TransactionSchema = objectType({
  id: stringType().min(1, "id is required"),
  company: objectType({
    id: stringType().min(1, "id is required"),
    name: stringType().min(1, "name is required"),
    currency: stringType().min(1, "currency is required")
  }),
  createdBy: objectType({
    id: stringType().min(1, "id is required"),
    firstName: stringType().min(1, "first Name is required"),
    lastName: stringType().min(1, "last Name is required"),
    email: stringType().email()
  }),
  description: stringType().min(1, "description is required"),
  debitAccount: ChartOfAccount,
  debitAccountBalance: numberType().min(1, "Debit account balance is required"),
  creditAccount: ChartOfAccount,
  creditAccountBalance: numberType().min(1, "Credit account balance is required"),
  amount: coerce.number().min(1, "amount is required"),
  ref: stringType().optional().nullable(),
  createdAt: stringType().min(1, "created date is required"),
  transactionDate: stringType().min(1, "transaction date is required")
});

// ../schemas/accounting/transactions/fe-schema.ts
var AccountObjectSchema = objectType({
  value: stringType().min(1, "id is required"),
  label: stringType().min(1, "account name is require"),
  phone: stringType().min(1, "account number is required")
});
var CreateTransactionSchemaFE = TransactionSchema.pick({
  transactionDate: true,
  description: true,
  amount: true
}).extend({
  debitAccount: AccountObjectSchema,
  creditAccount: AccountObjectSchema
});
var EditTransactionSchemaFE = CreateTransactionSchemaFE.extend({
  id: stringType().min(1, "id is required")
});
var CreateCounterTransactionEntrySchemaFE = CreateTransactionSchemaFE.pick({
  description: true,
  amount: true,
  transactionDate: true
}).partial();
var accountObjectDefaultValues = {
  value: "",
  label: "",
  phone: ""
};
var transactionSchemaDefaultValues = {
  transactionDate: (/* @__PURE__ */ new Date()).toISOString(),
  description: "",
  amount: 0,
  debitAccount: accountObjectDefaultValues,
  creditAccount: accountObjectDefaultValues
};

// ../schemas/accounting/transactions/be-schema.ts
var CreateTransactionSchemaBE = CreateTransactionSchemaFE.omit({
  debitAccount: true,
  creditAccount: true
}).extend({
  debitAccount: stringType().min(1, "debit account is required"),
  credit: stringType().min(1, "credit account is required")
});
var UpdateTransactionSchemaBE = CreateTransactionSchemaBE.omit({
  debitAccount: true
}).partial();

// ../schemas/accounting/drawing/fe-schema.ts
var TransactionDrawingSchema = objectType({
  description: stringType().min(1, "description is required"),
  debitAccountId: stringType().uuid(),
  creditAccountId: stringType().uuid(),
  amount: coerce.number().min(1, "amount is required")
});

// ../schemas/accounting/expense/fe-schema.ts
var TransactionExpanseSchema = objectType({
  description: stringType().min(1, "description is required"),
  debitAccountId: stringType().uuid(),
  creditAccountId: stringType().uuid(),
  amount: coerce.number().min(1, "amount is required")
});

// ../schemas/base-default-values.ts
var baseAddressValue = {
  road: "",
  zip: "",
  propertyNumber: "",
  city: "",
  country: {
    label: "",
    phone: "",
    value: ""
  }
};

// ../../node_modules/class-validator/esm5/metadata/ValidationMetadata.js
var ValidationMetadata = (
  /** @class */
  function() {
    function ValidationMetadata2(args) {
      this.groups = [];
      this.each = false;
      this.context = void 0;
      this.type = args.type;
      this.name = args.name;
      this.target = args.target;
      this.propertyName = args.propertyName;
      this.constraints = args === null || args === void 0 ? void 0 : args.constraints;
      this.constraintCls = args.constraintCls;
      this.validationTypeOptions = args.validationTypeOptions;
      if (args.validationOptions) {
        this.message = args.validationOptions.message;
        this.groups = args.validationOptions.groups;
        this.always = args.validationOptions.always;
        this.each = args.validationOptions.each;
        this.context = args.validationOptions.context;
      }
    }
    return ValidationMetadata2;
  }()
);

// ../../node_modules/class-validator/esm5/validation-schema/ValidationSchemaToMetadataTransformer.js
var ValidationSchemaToMetadataTransformer = (
  /** @class */
  function() {
    function ValidationSchemaToMetadataTransformer2() {
    }
    ValidationSchemaToMetadataTransformer2.prototype.transform = function(schema) {
      var metadatas = [];
      Object.keys(schema.properties).forEach(function(property) {
        schema.properties[property].forEach(function(validation) {
          var validationOptions = {
            message: validation.message,
            groups: validation.groups,
            always: validation.always,
            each: validation.each
          };
          var args = {
            type: validation.type,
            name: validation.name,
            target: schema.name,
            propertyName: property,
            constraints: validation.constraints,
            validationTypeOptions: validation.options,
            validationOptions
          };
          metadatas.push(new ValidationMetadata(args));
        });
      });
      return metadatas;
    };
    return ValidationSchemaToMetadataTransformer2;
  }()
);

// ../../node_modules/class-validator/esm5/utils/get-global.util.js
function getGlobal() {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof self !== "undefined") {
    return self;
  }
}

// ../../node_modules/class-validator/esm5/metadata/MetadataStorage.js
var __values = function(o) {
  var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
  if (m)
    return m.call(o);
  if (o && typeof o.length === "number")
    return {
      next: function() {
        if (o && i >= o.length)
          o = void 0;
        return { value: o && o[i++], done: !o };
      }
    };
  throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = function(o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m)
    return o;
  var i = m.call(o), r, ar = [], e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done)
      ar.push(r.value);
  } catch (error) {
    e = { error };
  } finally {
    try {
      if (r && !r.done && (m = i["return"]))
        m.call(i);
    } finally {
      if (e)
        throw e.error;
    }
  }
  return ar;
};
var __spreadArray = function(to, from, pack) {
  if (pack || arguments.length === 2)
    for (var i = 0, l = from.length, ar; i < l; i++) {
      if (ar || !(i in from)) {
        if (!ar)
          ar = Array.prototype.slice.call(from, 0, i);
        ar[i] = from[i];
      }
    }
  return to.concat(ar || Array.prototype.slice.call(from));
};
var MetadataStorage = (
  /** @class */
  function() {
    function MetadataStorage2() {
      this.validationMetadatas = /* @__PURE__ */ new Map();
      this.constraintMetadatas = /* @__PURE__ */ new Map();
    }
    Object.defineProperty(MetadataStorage2.prototype, "hasValidationMetaData", {
      get: function() {
        return !!this.validationMetadatas.size;
      },
      enumerable: false,
      configurable: true
    });
    MetadataStorage2.prototype.addValidationSchema = function(schema) {
      var _this = this;
      var validationMetadatas = new ValidationSchemaToMetadataTransformer().transform(schema);
      validationMetadatas.forEach(function(validationMetadata) {
        return _this.addValidationMetadata(validationMetadata);
      });
    };
    MetadataStorage2.prototype.addValidationMetadata = function(metadata) {
      var existingMetadata = this.validationMetadatas.get(metadata.target);
      if (existingMetadata) {
        existingMetadata.push(metadata);
      } else {
        this.validationMetadatas.set(metadata.target, [metadata]);
      }
    };
    MetadataStorage2.prototype.addConstraintMetadata = function(metadata) {
      var existingMetadata = this.constraintMetadatas.get(metadata.target);
      if (existingMetadata) {
        existingMetadata.push(metadata);
      } else {
        this.constraintMetadatas.set(metadata.target, [metadata]);
      }
    };
    MetadataStorage2.prototype.groupByPropertyName = function(metadata) {
      var grouped = {};
      metadata.forEach(function(metadata2) {
        if (!grouped[metadata2.propertyName])
          grouped[metadata2.propertyName] = [];
        grouped[metadata2.propertyName].push(metadata2);
      });
      return grouped;
    };
    MetadataStorage2.prototype.getTargetValidationMetadatas = function(targetConstructor, targetSchema, always, strictGroups, groups) {
      var e_1, _a;
      var includeMetadataBecauseOfAlwaysOption = function(metadata) {
        if (typeof metadata.always !== "undefined")
          return metadata.always;
        if (metadata.groups && metadata.groups.length)
          return false;
        return always;
      };
      var excludeMetadataBecauseOfStrictGroupsOption = function(metadata) {
        if (strictGroups) {
          if (!groups || !groups.length) {
            if (metadata.groups && metadata.groups.length)
              return true;
          }
        }
        return false;
      };
      var filteredForOriginalMetadatasSearch = this.validationMetadatas.get(targetConstructor) || [];
      var originalMetadatas = filteredForOriginalMetadatasSearch.filter(function(metadata) {
        if (metadata.target !== targetConstructor && metadata.target !== targetSchema)
          return false;
        if (includeMetadataBecauseOfAlwaysOption(metadata))
          return true;
        if (excludeMetadataBecauseOfStrictGroupsOption(metadata))
          return false;
        if (groups && groups.length > 0)
          return metadata.groups && !!metadata.groups.find(function(group) {
            return groups.indexOf(group) !== -1;
          });
        return true;
      });
      var filteredForInheritedMetadatasSearch = [];
      try {
        for (var _b = __values(this.validationMetadatas.entries()), _c = _b.next(); !_c.done; _c = _b.next()) {
          var _d = __read(_c.value, 2), key = _d[0], value = _d[1];
          if (targetConstructor.prototype instanceof key) {
            filteredForInheritedMetadatasSearch.push.apply(filteredForInheritedMetadatasSearch, __spreadArray([], __read(value), false));
          }
        }
      } catch (e_1_1) {
        e_1 = { error: e_1_1 };
      } finally {
        try {
          if (_c && !_c.done && (_a = _b.return))
            _a.call(_b);
        } finally {
          if (e_1)
            throw e_1.error;
        }
      }
      var inheritedMetadatas = filteredForInheritedMetadatasSearch.filter(function(metadata) {
        if (typeof metadata.target === "string")
          return false;
        if (metadata.target === targetConstructor)
          return false;
        if (metadata.target instanceof Function && !(targetConstructor.prototype instanceof metadata.target))
          return false;
        if (includeMetadataBecauseOfAlwaysOption(metadata))
          return true;
        if (excludeMetadataBecauseOfStrictGroupsOption(metadata))
          return false;
        if (groups && groups.length > 0)
          return metadata.groups && !!metadata.groups.find(function(group) {
            return groups.indexOf(group) !== -1;
          });
        return true;
      });
      var uniqueInheritedMetadatas = inheritedMetadatas.filter(function(inheritedMetadata) {
        return !originalMetadatas.find(function(originalMetadata) {
          return originalMetadata.propertyName === inheritedMetadata.propertyName && originalMetadata.type === inheritedMetadata.type;
        });
      });
      return originalMetadatas.concat(uniqueInheritedMetadatas);
    };
    MetadataStorage2.prototype.getTargetValidatorConstraints = function(target) {
      return this.constraintMetadatas.get(target) || [];
    };
    return MetadataStorage2;
  }()
);
function getMetadataStorage() {
  var global2 = getGlobal();
  if (!global2.classValidatorMetadataStorage) {
    global2.classValidatorMetadataStorage = new MetadataStorage();
  }
  return global2.classValidatorMetadataStorage;
}

// ../../node_modules/class-validator/esm5/validation/ValidationTypes.js
var ValidationTypes = (
  /** @class */
  function() {
    function ValidationTypes2() {
    }
    ValidationTypes2.isValid = function(type) {
      var _this = this;
      return type !== "isValid" && type !== "getMessage" && Object.keys(this).map(function(key) {
        return _this[key];
      }).indexOf(type) !== -1;
    };
    ValidationTypes2.CUSTOM_VALIDATION = "customValidation";
    ValidationTypes2.NESTED_VALIDATION = "nestedValidation";
    ValidationTypes2.PROMISE_VALIDATION = "promiseValidation";
    ValidationTypes2.CONDITIONAL_VALIDATION = "conditionalValidation";
    ValidationTypes2.WHITELIST = "whitelistValidation";
    ValidationTypes2.IS_DEFINED = "isDefined";
    return ValidationTypes2;
  }()
);

// ../../node_modules/class-validator/esm5/container.js
var defaultContainer = new /** @class */
(function() {
  function class_1() {
    this.instances = [];
  }
  class_1.prototype.get = function(someClass) {
    var instance = this.instances.find(function(instance2) {
      return instance2.type === someClass;
    });
    if (!instance) {
      instance = { type: someClass, object: new someClass() };
      this.instances.push(instance);
    }
    return instance.object;
  };
  return class_1;
}())();
var userContainer;
var userContainerOptions;
function getFromContainer(someClass) {
  if (userContainer) {
    try {
      var instance = userContainer.get(someClass);
      if (instance)
        return instance;
      if (!userContainerOptions || !userContainerOptions.fallback)
        return instance;
    } catch (error) {
      if (!userContainerOptions || !userContainerOptions.fallbackOnErrors)
        throw error;
    }
  }
  return defaultContainer.get(someClass);
}

// ../../node_modules/class-validator/esm5/metadata/ConstraintMetadata.js
var ConstraintMetadata = (
  /** @class */
  function() {
    function ConstraintMetadata2(target, name, async) {
      if (async === void 0) {
        async = false;
      }
      this.target = target;
      this.name = name;
      this.async = async;
    }
    Object.defineProperty(ConstraintMetadata2.prototype, "instance", {
      // -------------------------------------------------------------------------
      // Accessors
      // -------------------------------------------------------------------------
      /**
       * Instance of the target custom validation class which performs validation.
       */
      get: function() {
        return getFromContainer(this.target);
      },
      enumerable: false,
      configurable: true
    });
    return ConstraintMetadata2;
  }()
);

// ../../node_modules/class-validator/esm5/register-decorator.js
function registerDecorator(options) {
  var constraintCls;
  if (options.validator instanceof Function) {
    constraintCls = options.validator;
    var constraintClasses = getFromContainer(MetadataStorage).getTargetValidatorConstraints(options.validator);
    if (constraintClasses.length > 1) {
      throw "More than one implementation of ValidatorConstraintInterface found for validator on: ".concat(options.target.name, ":").concat(options.propertyName);
    }
  } else {
    var validator_1 = options.validator;
    constraintCls = /** @class */
    function() {
      function CustomConstraint() {
      }
      CustomConstraint.prototype.validate = function(value, validationArguments) {
        return validator_1.validate(value, validationArguments);
      };
      CustomConstraint.prototype.defaultMessage = function(validationArguments) {
        if (validator_1.defaultMessage) {
          return validator_1.defaultMessage(validationArguments);
        }
        return "";
      };
      return CustomConstraint;
    }();
    getMetadataStorage().addConstraintMetadata(new ConstraintMetadata(constraintCls, options.name, options.async));
  }
  var validationMetadataArgs = {
    type: options.name && ValidationTypes.isValid(options.name) ? options.name : ValidationTypes.CUSTOM_VALIDATION,
    name: options.name,
    target: options.target,
    propertyName: options.propertyName,
    validationOptions: options.options,
    constraintCls,
    constraints: options.constraints
  };
  getMetadataStorage().addValidationMetadata(new ValidationMetadata(validationMetadataArgs));
}

// ../../node_modules/class-validator/esm5/decorator/common/ValidateBy.js
function buildMessage(impl, validationOptions) {
  return function(validationArguments) {
    var eachPrefix = validationOptions && validationOptions.each ? "each value in " : "";
    return impl(eachPrefix, validationArguments);
  };
}
function ValidateBy(options, validationOptions) {
  return function(object, propertyName) {
    registerDecorator({
      name: options.name,
      target: object.constructor,
      propertyName,
      options: validationOptions,
      constraints: options.constraints,
      validator: options.validator
    });
  };
}

// ../../node_modules/class-validator/esm5/decorator/common/IsOptional.js
function IsOptional(validationOptions) {
  return function(object, propertyName) {
    var args = {
      type: ValidationTypes.CONDITIONAL_VALIDATION,
      target: object.constructor,
      propertyName,
      constraints: [
        function(object2, value) {
          return object2[propertyName] !== null && object2[propertyName] !== void 0;
        }
      ],
      validationOptions
    };
    getMetadataStorage().addValidationMetadata(new ValidationMetadata(args));
  };
}

// ../../node_modules/class-validator/esm5/decorator/common/IsNotEmpty.js
var IS_NOT_EMPTY = "isNotEmpty";
function isNotEmpty(value) {
  return value !== "" && value !== null && value !== void 0;
}
function IsNotEmpty(validationOptions) {
  return ValidateBy({
    name: IS_NOT_EMPTY,
    validator: {
      validate: function(value, args) {
        return isNotEmpty(value);
      },
      defaultMessage: buildMessage(function(eachPrefix) {
        return eachPrefix + "$property should not be empty";
      }, validationOptions)
    }
  }, validationOptions);
}

// ../../node_modules/class-validator/esm5/decorator/typechecker/IsString.js
var IS_STRING = "isString";
function isString(value) {
  return value instanceof String || typeof value === "string";
}
function IsString(validationOptions) {
  return ValidateBy({
    name: IS_STRING,
    validator: {
      validate: function(value, args) {
        return isString(value);
      },
      defaultMessage: buildMessage(function(eachPrefix) {
        return eachPrefix + "$property must be a string";
      }, validationOptions)
    }
  }, validationOptions);
}

// ../schemas/country-schema.ts
var CountryInfoSchema = class {
};
__decorateClass([
  IsString(),
  IsNotEmpty({ message: "Please select the country" })
], CountryInfoSchema.prototype, "label", 2);
__decorateClass([
  IsString(),
  IsOptional()
], CountryInfoSchema.prototype, "code", 2);
__decorateClass([
  IsString(),
  IsNotEmpty({ message: "Please select the country" })
], CountryInfoSchema.prototype, "phone", 2);
var CountryInfoSchemaFE = z.object({
  value: stringType().min(1, "Country is required"),
  label: stringType().min(1, "Country id is required"),
  phone: stringType().min(1, "Country is required")
});
var CountryInfoResponseBE = z.object({
  id: stringType().min(1, "Country is required"),
  title: stringType().min(1, "Country id is required"),
  phone: stringType().min(1, "Country is required")
});
var CountryInfoSchemaBE = z.string().min(1, "Country id is required");

// ../schemas/basic-schemas.ts
var AddressFieldSchemaFE = objectType({
  road: stringType().nullable().optional(),
  propertyNumber: stringType().nullable().optional(),
  zip: stringType().nullable().optional(),
  city: stringType().min(3, "City is required"),
  country: CountryInfoSchemaFE
});
var AddressFieldResponseSchemaBE = objectType({
  road: stringType().nullable(),
  propertyNumber: stringType().nullable(),
  zip: stringType().nullable(),
  city: stringType().min(3, "City is required"),
  country: CountryInfoResponseBE
});
var PriceFieldSchema = coerce.number().gt(0, "price should be greater than 0").max(9999999999, "price should be max 9999999999");
var GetPriceFieldSchema = (required = false) => {
  if (required) {
    return PriceFieldSchema;
  }
  return coerce.number();
};
var DropdownSchema = objectType({
  label: stringType().min(1, "Label is required"),
  value: stringType().min(1, "Value is required")
});
var AutoCompleteSchemaFE = arrayType(
  objectType({
    label: stringType().min(1, "Label is required"),
    value: stringType().min(1, "Value is required")
  })
);
var AutoCompleteSchemaArrayFE = (required = false) => {
  if (required) {
    return arrayType(
      objectType({
        label: stringType().min(1, "Label is required"),
        value: stringType().min(1, "Value is required")
      })
    );
  }
  return arrayType(
    objectType({
      label: stringType(),
      value: stringType()
    })
  ).optional().nullable();
};
var AutoCompleteSingleSchemaFE = (required = false) => {
  if (required) {
    return objectType({
      label: stringType().min(1, "Label is required"),
      value: stringType().min(1, "Value is required")
    }).required();
  }
  return objectType({
    label: stringType(),
    value: stringType()
  }).optional();
};
var AutoCompleteSingleRequiredSchemaFE = objectType({
  label: stringType().min(1, "Label is required"),
  value: stringType().min(1, "Value is required")
}).required();

// ../schemas/bank/fe-schema.ts
var CreateBankAccountFormSchemaFE = z.object({
  bankName: z.string().min(1, "Bank name is required"),
  accountName: z.string().min(1, "Account name is required"),
  accountNumber: z.string().min(1, "Account number is required"),
  currency: z.string().min(1, "Currency is required"),
  enabled: z.boolean().default(true)
}).merge(AddressFieldSchemaFE);
var bankAccountFormDefaultValues = __spreadValues({
  bankName: "",
  accountName: "",
  accountNumber: "",
  currency: "",
  enabled: true
}, baseAddressValue);

// ../schemas/bank/be-schema.ts
var CreateBankAccountFormSchemaBE = CreateBankAccountFormSchemaFE.omit({
  country: true
}).extend({
  company: stringType().min(1, "Company id is required"),
  country: stringType().min(1, "country id is required")
});
var UpdateBankAccountFormSchemaBE = CreateBankAccountFormSchemaBE.omit({
  country: true
}).partial();

// ../schemas/company/fe-schema.ts
var CreateCompanyInfoFormSchemaFE = z.object({
  title: stringType().min(3, "Title is required"),
  currency: stringType().min(1, "Currency is required"),
  logo: stringType().optional()
}).merge(AddressFieldSchemaFE);
var UpdateCompanyInfoFormSchemaFE = CreateCompanyInfoFormSchemaFE.omit({
  country: true,
  currency: true
}).partial();
var companyInfoFormDefaultValues = __spreadValues({
  title: "",
  currency: "",
  logo: ""
}, baseAddressValue);

// ../schemas/company/be-schema.ts
var CreateCompanyInfoFormSchemaBE = CreateCompanyInfoFormSchemaFE.omit({
  country: true
}).extend({
  country: CountryInfoSchemaBE
});
var UpdateCompanyInfoFormSchemaBE = CreateCompanyInfoFormSchemaBE.extend({
  userIds: z.array(z.string())
}).partial();

// ../schemas/customer/base.ts
var CustomerDue = objectType({
  id: stringType().uuid(),
  amount: coerce.number().min(1),
  store: objectType({
    id: stringType().uuid(),
    title: stringType().min(1)
  }),
  createdAt: stringType(),
  updatedAt: stringType()
});
var Customer = objectType({
  id: stringType().uuid(),
  firstName: stringType(),
  lastName: stringType(),
  email: stringType().email().optional().nullable(),
  phoneNumber: stringType().optional(),
  createdAt: stringType(),
  updatedAt: stringType(),
  customerRefId: stringType().optional().nullable(),
  dues: CustomerDue.array(),
  description: stringType().optional().nullable(),
  isReferable: booleanType().default(false),
  referredBy: objectType({
    id: stringType(),
    firstName: stringType(),
    lastName: stringType()
  })
}).merge(AddressFieldResponseSchemaBE);

// ../schemas/customer/fe-schema.ts
var CreateCustomerInfoFormSchemaFE = Customer.omit({
  id: true,
  createdAt: true,
  updatedAt: true,
  country: true,
  firstName: true,
  email: true,
  phoneNumber: true,
  dues: true,
  referredBy: true
}).extend({
  country: CountryInfoSchemaFE,
  firstName: stringType().optional().nullable(),
  email: stringType().email().optional().nullable(),
  phoneNumber: stringType(),
  referredById: AutoCompleteSingleSchemaFE().optional().nullable()
});
var UpdateCustomerInfoFormSchemaFE = CreateCustomerInfoFormSchemaFE.partial();
var customerInfoFormDefaultValues = __spreadValues({
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  description: "",
  referredById: { label: "", value: "" },
  isReferable: false
}, baseAddressValue);

// ../schemas/customer/be-schema.ts
var CreateCustomerInfoFormSchemaBE = CreateCustomerInfoFormSchemaFE.omit({
  country: true,
  referredById: true
}).extend({
  country: stringType(),
  referredById: stringType().optional()
});
var GetReferralMetricsSchemaBE = objectType({
  totalReferrals: coerce.number(),
  totalDueAmount: coerce.number(),
  referredBy: stringType()
});
var UpdateCustomerInfoFormSchemaBE = CreateCustomerInfoFormSchemaBE.partial();

// ../schemas/customer/customer-type.ts
var CustomerTypeEnumObject = z.enum(["PAID", "DUE", "REFERENCE"]);
var CustomerTypeEnum = CustomerTypeEnumObject.enum;

// ../schemas/inventory/fe-schema.ts
var CreateInventoryFormSchemaFE = z.object({
  title: stringType().min(1, "Title is required")
}).merge(AddressFieldSchemaFE);
var UpdateInventoryFormSchemaFE = CreateInventoryFormSchemaFE.pick({
  title: true,
  road: true,
  zip: true,
  city: true,
  propertyNumber: true
}).partial();
var inventoryFormValuesDefaultValues = __spreadProps(__spreadValues({}, baseAddressValue), {
  title: ""
});

// ../schemas/inventory/be-schema.ts
var CreateInventoryFormSchemaBE = CreateInventoryFormSchemaFE.omit({
  country: true
}).extend({
  country: CountryInfoSchemaBE
});
var UpdateInventoryFormSchemaBE = CreateInventoryFormSchemaBE.partial();

// ../schemas/store/fe-schema.ts
var PaymentMethodSchema = objectType({
  id: stringType().uuid("Payment method id is required"),
  title: stringType().min(1, "Payment Method name is required"),
  chartOfAccount: stringType().uuid("Chart of account is required"),
  isDefault: booleanType(),
  isDisabled: booleanType(),
  isReceivable: booleanType()
});
var CreateStoreFormSchemaFE = objectType({
  title: stringType().min(1, "Title is required"),
  logo: stringType().optional()
}).merge(AddressFieldSchemaFE);
var UpdateStoreFormSchemaFE = CreateStoreFormSchemaFE.pick({
  title: true,
  road: true,
  zip: true,
  city: true,
  propertyNumber: true
}).partial();
var storeFormValuesDefaultValues = __spreadValues({
  title: "",
  logo: ""
}, baseAddressValue);

// ../schemas/store/be-schema.ts
var CreateStoreFormSchemaBE = CreateStoreFormSchemaFE.omit({ country: true }).extend({
  country: CountryInfoSchemaBE
});
var UpdateStoreFormSchemaBE = CreateStoreFormSchemaBE.extend({
  userIds: z.array(z.string())
}).partial();

// ../schemas/user/fe-schema.ts
var userTypeEnumValues = ["STAFF", "PARTNER", "OWNER", "SUPER_ADMIN", "SUPPORT"];
var UserTypeEnumObj = z.enum(userTypeEnumValues);
var UserTypeEnum = z.enum(userTypeEnumValues).enum;
var BaseSchema = objectType({
  firstName: stringType().min(1, "First name is required"),
  lastName: stringType().min(1, "Last name is required"),
  phoneNo: stringType().optional().nullable(),
  userType: UserTypeEnumObj,
  role: AutoCompleteSingleSchemaFE(),
  storesAssigned: AutoCompleteSchemaArrayFE(),
  inventoriesAssigned: AutoCompleteSchemaArrayFE(),
  email: stringType().email().nullable().optional(),
  pictureId: stringType().nullable().optional()
}).merge(AddressFieldSchemaFE);
var CreateUserFormSchemaFE = BaseSchema.superRefine((schema, ctx) => {
  var _a;
  const { userType } = schema;
  if (userType === UserTypeEnum.STAFF && !((_a = schema.role) == null ? void 0 : _a.value)) {
    return ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ["role"],
      message: "Role is required"
    });
  } else if (userType === UserTypeEnum.PARTNER && !schema.email) {
    return ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ["email"],
      message: "Email is required"
    });
  }
});
var UpdateUserFormSchemaFE = BaseSchema.partial();
var userFormDefaultValues = __spreadProps(__spreadValues({
  firstName: "",
  lastName: "",
  phoneNo: "",
  email: "",
  pictureId: "",
  userType: UserTypeEnum.STAFF
}, baseAddressValue), {
  role: {
    label: "",
    value: ""
  },
  storesAssigned: [],
  inventoriesAssigned: []
});

// ../schemas/user/be-schema.ts
var CreateUserFormSchemaBE = BaseSchema.omit({
  country: true,
  role: true,
  storesAssigned: true,
  inventoriesAssigned: true,
  phoneNo: true
}).extend({
  role: stringType().optional(),
  country: stringType(),
  storesAssigned: arrayType(stringType()).optional().nullable(),
  inventoriesAssigned: arrayType(stringType()).optional().nullable(),
  phoneNo: stringType().optional().nullable()
});
var UpdateUserFormSchemaBE = CreateUserFormSchemaBE.partial();

// ../schemas/role/fe-schema.ts
var CreateRoleFormSchemaFE = objectType({
  title: stringType().nonempty("Title is required"),
  description: stringType().nonempty("Description is required"),
  permissions: arrayType(stringType()).min(1, "At least one permission is required")
});
var UpdateRoleFormSchemaFE = CreateRoleFormSchemaFE.partial();

// ../schemas/role/be-schema.ts
var CreateRoleFormSchemaBE = CreateRoleFormSchemaFE;
var UpdateRoleFormSchemaBE = CreateRoleFormSchemaBE.partial();

// ../schemas/product/unit-enum.ts
var UnitEnumObject = z.enum(["KG", "L", "PCS", "M", "CM", "MM", "G"]);
var UnitEnum = UnitEnumObject.enum;

// ../schemas/product/count-type-enum.ts
var CountTypeEnumObject = z.enum(["INT", "FLOAT"]);
var CountTypeEnum = CountTypeEnumObject.enum;

// ../schemas/product-category/base.ts
var ProductCategory = objectType({
  id: stringType().uuid(),
  title: stringType().min(2).max(100),
  description: stringType().max(255).nullable().optional(),
  parentId: stringType().uuid().nullable().optional(),
  count: numberType().optional(),
  createdAt: stringType(),
  updatedAt: stringType()
});
var ProductCategoryTree = ProductCategory.extend({
  level: numberType(),
  children: z.lazy(() => ProductCategoryTree.array())
});

// ../schemas/product/fe-schema.ts
var ProductTypeValues = ["DEFAULT", "SERVABLE", "INGREDIENT"];
var ProductTypeEnumObject = z.enum(ProductTypeValues);
var ProductTypeEnum = ProductTypeEnumObject.enum;
var AttributeSchema = recordType(stringType(), stringType());
var ImagesSchema = objectType({
  id: stringType(),
  type: stringType(),
  destination: stringType(),
  fileName: stringType(),
  userId: stringType().uuid(),
  companyId: stringType().uuid().nullable().optional(),
  createdAt: stringType(),
  updatedAt: stringType(),
  thumbnailBgMode: stringType().optional()
});
var S3ImageSchema = ImagesSchema.extend({
  src: stringType()
});
var BarcodeSchema = objectType({
  barcode: stringType().min(1, "Barcode is required")
});
var ProductVariant = objectType({
  id: stringType().uuid(),
  title: stringType().min(1, "Title is required"),
  attributes: AttributeSchema,
  purchasePrice: PriceFieldSchema,
  minimumSalePrice: PriceFieldSchema,
  regularSalePrice: PriceFieldSchema,
  images: arrayType(ImagesSchema).max(5, "One variant may contain max 5 images"),
  lowStockThreshold: coerce.number().min(1, "Low stock number should be at least 1"),
  barcodes: arrayType(BarcodeSchema).optional(),
  isActive: booleanType().default(true),
  thumbnailBgMode: stringType().optional(),
  inventoryStock: arrayType(
    objectType({
      id: stringType().uuid(),
      quantity: coerce.number().min(0, "Quantity should be at least 0")
    })
  )
});
var Product = objectType({
  id: stringType().uuid(),
  title: stringType().min(1, "Title is required"),
  description: stringType().optional().nullable(),
  unit: stringType().min(1, "Unit is required"),
  vatPercent: coerce.number().min(0, "VAT should be at least 0").max(1e3, "VAT should be max 1000%"),
  variants: arrayType(ProductVariant).min(0, "At least one variant is required"),
  categories: arrayType(ProductCategory).min(0, "Category is required"),
  barcodes: arrayType(BarcodeSchema).optional(),
  productType: objectType({
    id: ProductTypeEnumObject
  }).default({ id: ProductTypeEnum.DEFAULT })
});
var ProductVariantFormSchemaFE = ProductVariant.omit({
  id: true,
  thumbnailBgMode: true,
  inventoryStock: true,
  images: true
}).extend({
  id: stringType().optional(),
  thumbnailBgMode: AutoCompleteSingleSchemaFE(),
  images: arrayType(objectType({ id: stringType() })).max(5, "One variant may contain max 5 images")
});
var CreateProductFormSchemaFE = Product.omit({ variants: true, id: true, productType: true }).extend({
  variants: arrayType(ProductVariantFormSchemaFE).min(0, "At least one variant is required"),
  productType: stringType().nullable().default(null).optional()
});
var UpdateProductFormSchemaFE = CreateProductFormSchemaFE.partial();

// ../schemas/product/be-schema.ts
var ProductVariantFormSchemaBE = ProductVariant.omit({
  id: true,
  images: true,
  inventoryStock: true
}).extend({
  id: stringType().optional(),
  images: arrayType(objectType({ id: stringType() })).max(5, "One variant may contain max 5 images")
});
var ProductTypeSchema = objectType({
  id: stringType().min(1, "id is required"),
  product: Product.optional()
});
var CreateProductFormSchemaBE = CreateProductFormSchemaFE.omit({
  categories: true,
  variants: true
}).extend({
  categories: arrayType(stringType()),
  variants: arrayType(ProductVariantFormSchemaBE)
});
var UpdateProductFormSchemaBE = CreateProductFormSchemaBE.extend({
  id: stringType().min(1, "Product ID is required"),
  company: stringType().min(1, "Company ID is required"),
  variants: arrayType(ProductVariantFormSchemaBE)
}).partial();

// ../schemas/product/thumbnail-bg-mode.ts
var ThumbnailBgModeEnumArray = ["CONTAIN", "COVER", "AUTO"];
var ThumbnailBgModeEnumObject = z.enum(ThumbnailBgModeEnumArray);
var ThumbnailBgModeEnum = ThumbnailBgModeEnumObject.enum;

// ../schemas/product/unit-label-map.ts
var unitLabelMap = /* @__PURE__ */ new Map([
  [UnitEnum.KG, "Kilogram"],
  [UnitEnum.L, "Liter"],
  [UnitEnum.PCS, "Piece"],
  [UnitEnum.M, "Meter"],
  [UnitEnum.CM, "Centimeter"],
  [UnitEnum.MM, "Millimeter"],
  [UnitEnum.G, "Gram"]
]);

// ../schemas/profile/fe-schema.ts
var UpdateProfileFormSchemaFE = objectType({
  firstName: stringType().nonempty("First name is required"),
  lastName: stringType().nonempty("Last name is required"),
  phoneNo: stringType().optional().nullable(),
  country: objectType({
    value: stringType().nonempty("Country is required"),
    label: stringType().nonempty("Country is required"),
    phone: stringType().nonempty("Country is required")
  }),
  city: stringType().nonempty("city is required"),
  pictureId: stringType().nullable().optional()
});
var profileDefaultValues = {
  firstName: "",
  lastName: "",
  phoneNo: "",
  pictureId: "",
  city: baseAddressValue.city,
  country: baseAddressValue.country
};

// ../schemas/profile/be-schema.ts
var UpdateProfileFormSchemaBE = UpdateProfileFormSchemaFE.omit({
  country: true
}).extend({
  country: stringType().nonempty("Country is required")
});

// ../schemas/product-category/fe-schema.ts
var CreateProductCategoryFormSchemaFE = ProductCategory.omit({
  id: true
});

// ../schemas/product-category/be-schema.ts
var CreateProductCategoryFormSchemaBE = CreateProductCategoryFormSchemaFE.omit({
  createdAt: true,
  updatedAt: true
}).extend({
  parentId: stringType().uuid().nullable().optional()
});
var UpdateProductCategoryFormSchemaBE = CreateProductCategoryFormSchemaFE.partial();

// ../schemas/supplier/base.ts
var SupplierProductSchema = z.object({ id: stringType().min(1, "Please enter the product id") });
var SupplierDue = objectType({
  id: stringType().uuid(),
  amount: coerce.number().min(1),
  store: objectType({
    id: stringType().uuid(),
    title: stringType().min(1)
  }),
  createdAt: stringType(),
  updatedAt: stringType()
});
var Supplier = objectType({
  id: stringType().min(1, "Supplier id is required"),
  title: stringType().min(1, "Title is required"),
  email: stringType().email().optional().nullable(),
  phoneNumber: stringType().min(1, "Phone Number Name is required"),
  products: arrayType(SupplierProductSchema).min(1, "At least one product is required"),
  dues: arrayType(SupplierDue),
  createdAt: stringType(),
  updatedAt: stringType()
}).merge(AddressFieldResponseSchemaBE);

// ../schemas/supplier/fe-schema.ts
var CreateSupplierInfoFormSchemaFE = Supplier.omit({
  id: true,
  country: true,
  createdAt: true,
  updatedAt: true,
  dues: true
}).extend({
  email: stringType().optional().nullable()
}).merge(AddressFieldSchemaFE);
var UpdateSupplierInfoFormSchemaFE = CreateSupplierInfoFormSchemaFE.partial();
var supplierInfoFormDefaultValues = __spreadValues({
  title: "",
  email: "",
  phoneNumber: "",
  products: []
}, baseAddressValue);

// ../schemas/supplier/be-schema.ts
var CreateSupplierInfoFormSchemaBE = CreateSupplierInfoFormSchemaFE.omit({
  country: true
}).extend({
  country: stringType()
});
var UpdateSupplierInfoFormSchemaBE = CreateSupplierInfoFormSchemaBE.partial();

// ../schemas/add-to-inventory/fe-schema.ts
var AddProductToInventorySchemaFE = objectType({
  quantity: coerce.number().positive("Quantity should be positive"),
  variantId: stringType().min(1, "Selecting a variant is required").uuid("Invalid variant id"),
  pricePerUnit: PriceFieldSchema,
  supplier: AutoCompleteSingleSchemaFE(),
  financedAccount: AutoCompleteSingleRequiredSchemaFE,
  inventory: AutoCompleteSingleRequiredSchemaFE,
  minimumSalePrice: GetPriceFieldSchema(),
  regularSalePrice: GetPriceFieldSchema()
});

// ../schemas/add-to-inventory/be-schema.ts
var AddProductToInventoryFormSchemaBE = AddProductToInventorySchemaFE.omit({
  supplier: true,
  inventory: true,
  financedAccount: true
}).extend({
  supplierId: stringType().optional(),
  variantId: stringType().uuid().min(1),
  financedAccountId: stringType().uuid().min(1)
});

// ../schemas/inventory-history/base.ts
var IHReduceReasonEnumValues = [
  "SALES",
  "THEFT_OR_LOSS",
  "DAMAGE_OR_SPOILAGE",
  "RETURNS",
  "SHRINKAGE",
  "ADJUSTMENTS",
  "TRANSFERS",
  "EXCESS_INVENTORY_REDUCTION",
  "RECALLS",
  "RETURNS_TO_VENDOR",
  "KITTING_OR_BUNDLING"
];
var IHChangeReasonEnumValues = [...IHReduceReasonEnumValues, "PURCHASES"];
var IHChangeReasonEnumObject = z.enum(IHChangeReasonEnumValues);
var IHReduceReasonEnumObject = z.enum(IHReduceReasonEnumValues);
var IHChangeReasonEnum = IHChangeReasonEnumObject.enum;
var IHReduceReasonEnum = IHReduceReasonEnumObject.enum;
var historyFilterSchema = objectType({
  inventoryId: stringType().optional(),
  from: stringType().optional(),
  to: stringType().optional(),
  page: numberType().optional(),
  perPage: numberType().optional(),
  range: stringType().optional(),
  reason: z.enum(IHChangeReasonEnumValues).optional()
});

// ../schemas/order/price-type.ts
var PriceTypeEnumObj = z.enum(["REGULAR_SELL_PRICE", "DISCOUNT_PRICE"]);
var PriceTypeEnum = PriceTypeEnumObj.enum;

// ../schemas/order/base.ts
var orderReturnReasons = [
  "WRONG ITEM",
  "DAMAGED ITEM",
  "WRONG SIZE",
  "WRONG COLOR",
  "WRONG QUANTITY",
  "MISTAKE",
  "OTHER"
];
var issueRefundOptions = ["INSTANT REFUND"];
var OrderStatusZodEnumObject = z.enum([
  "PENDING",
  "PARTIALLY_RETURNED",
  "RETURNED",
  "CANCELLED",
  "PENDING_DELIVERY",
  "DELIVERY_IN_PROGRESS",
  "DELIVERED",
  "COMPLETED"
]);
var SupplyStatusZodEnumObject = z.enum([
  "PENDING",
  "PAID",
  "PARTIALLY_RETURNED",
  "RETURNED",
  "CANCELLED",
  "PENDING_DELIVERY",
  "DELIVERY_IN_PROGRESS",
  "DELIVERED",
  "COMPLETED"
]);
var PaymentStatusZodEnumObject = z.enum([
  "PENDING",
  "COMPLETED",
  "FAILED",
  "REFUNDED",
  "PARTIALLY_REFUNDED",
  "DUE"
]);
var OrderReturnReasonsObject = z.enum(orderReturnReasons);
var issueRefundObject = z.enum(issueRefundOptions);
var PaymentStatusEnum = PaymentStatusZodEnumObject.enum;
var OrderStatusEnum = OrderStatusZodEnumObject.enum;
var EOrderReturnReasonsEnum = OrderReturnReasonsObject.enum;
var ESupplyOrderStatusEnum = SupplyStatusZodEnumObject.enum;
var EIssueRefundEnum = issueRefundObject.enum;
var OrderItem = objectType({
  id: stringType().uuid(),
  quantity: numberType(),
  unitPrice: numberType(),
  vatPercent: numberType(),
  netPrice: numberType(),
  grossPrice: numberType(),
  createdAt: stringType(),
  updatedAt: stringType(),
  productVariant: objectType({
    title: stringType(),
    regularSalePrice: numberType(),
    product: objectType({
      title: stringType()
    })
  }),
  inventoryHistory: objectType({
    id: stringType(),
    quantity: coerce.number(),
    reason: IHChangeReasonEnumObject
  }).array()
});
var Order = objectType({
  id: stringType().uuid(),
  netPrice: numberType(),
  grossPrice: numberType(),
  orderStatus: OrderStatusZodEnumObject,
  paymentStatus: PaymentStatusZodEnumObject,
  paymentMethod: objectType({
    id: stringType().min(1, "payment method id is required"),
    title: stringType()
  }),
  totalVat: numberType(),
  createdAt: stringType(),
  updatedAt: stringType(),
  orderItems: arrayType(OrderItem),
  customer: Customer.nullable(),
  dueAmount: numberType(),
  transactions: arrayType(TransactionSchema),
  createdBy: objectType({
    id: stringType().uuid(),
    firstName: stringType(),
    lastName: stringType()
  })
});

// ../schemas/order/fe-schema.ts
var OrderItemFromSchema = objectType({
  productVariantId: stringType().uuid(),
  quantity: numberType(),
  price: numberType().optional(),
  priceType: PriceTypeEnumObj.optional()
});
var CreateOrderFE = objectType({
  orderItems: arrayType(OrderItemFromSchema),
  customer: DropdownSchema.optional(),
  paymentMethodId: stringType(),
  isCompleted: booleanType().optional(),
  isIngredient: booleanType().optional(),
  paidAmount: coerce.number().min(0, "Paid amount should be greater than 0").optional(),
  isSendInvoice: booleanType().optional()
});
var UpdateOrderFE = CreateOrderFE.extend({
  paymentStatus: PaymentStatusZodEnumObject.optional()
}).partial();

// ../schemas/order/be-schema.ts
var CreateOrderBE = CreateOrderFE.omit({ customer: true }).extend({
  customerId: stringType().uuid().optional()
});
var UpdateOrderBE = CreateOrderFE.omit({
  customer: true
}).extend({
  customerId: stringType().uuid().optional(),
  paymentStatus: PaymentStatusZodEnumObject.optional(),
  paymentMethodId: stringType().min(1, "payment method id is required")
}).partial();

// ../schemas/stock-status/base.ts
var StockStatusEnumObject = z.enum(["ALL", "IN_STOCK", "OUT_OF_STOCK", "LOW_STOCK"]);
var StockStatusEnum = StockStatusEnumObject.enum;

// ../schemas/purchase-order/base.ts
var PurchaseOrderItemBaseSchema = objectType({
  id: stringType().min(1, "supply item is required"),
  productVariant: ProductVariant.required(),
  quantity: numberType().min(0, "quantity is required"),
  purchasePrice: numberType().min(0, "price is required"),
  regularSalePrice: numberType().min(0, "regular sale price is required"),
  minimumSalePrice: numberType().min(0, "minimum sale price is required"),
  createdAt: dateType().min(/* @__PURE__ */ new Date(), "created at is required")
});
var PurchaseOrderBaseSchema = objectType({
  id: stringType().uuid(),
  paymentMethod: stringType(),
  createdAt: stringType().datetime(),
  paymentStatus: PaymentStatusZodEnumObject,
  supplyStatus: SupplyStatusZodEnumObject,
  purchaseOrderItems: arrayType(PurchaseOrderItemBaseSchema).min(0, "Please select at least one supply item"),
  payableAmount: coerce.number().min(0),
  createdBy: objectType({
    id: stringType().uuid().min(0, "user id is required"),
    firstName: stringType().min(1, "user first name is required"),
    lastName: stringType().min(1, "user last name is required")
  }).required(),
  supplier: Supplier.required(),
  transactions: arrayType(TransactionSchema)
});

// ../schemas/purchase-order/be-schema.ts
var PurchaseOrderItemSchemaBE = PurchaseOrderItemBaseSchema.omit({ id: true });
var CreatePurchaseOrderSchemaBE = PurchaseOrderBaseSchema.pick({
  paymentStatus: true,
  supplyStatus: true
}).extend({
  purchaseOrderItems: arrayType(
    PurchaseOrderItemSchemaBE.pick({
      quantity: true,
      purchasePrice: true,
      regularSalePrice: true,
      minimumSalePrice: true
    }).extend({
      productVariantId: stringType().min(1, "product variant id is required")
    })
  ).min(0, "Please select atleast one supply items"),
  inventoryId: stringType().min(1, "inventory id is required"),
  paidById: stringType().min(1, "Please select one payment method"),
  supplierId: stringType().min(1, "Please select the supplier")
});

// ../schemas/types-to-migrate/company.ts
var defaultChartOfAccountsObject = z.enum([
  "cashbox",
  "purchase",
  "cogs",
  "receivable",
  "vat",
  "payable",
  "liabilities",
  "revenue"
]);
var DefaultChartOfAccountsObjectEnum = defaultChartOfAccountsObject.enum;

// ../schemas/report/daily-report.ts
var ReportTimeTypeEnumObject = z.enum(["daily", "monthly", "yearly"]);
var ReportTimeTypeEnum = ReportTimeTypeEnumObject.enum;
var reportBaseSchema = z.object({
  id: z.string(),
  netPrice: z.number(),
  purchasePrice: z.number(),
  vat: z.number(),
  grossPrice: z.number(),
  profit: z.number(),
  paidInReceivable: z.number(),
  paidInCash: z.number(),
  paidInOthers: z.number(),
  reportDate: z.string(),
  createdAt: z.string().optional(),
  updatedAt: z.string().optional()
});

// ../schemas/report/receivable-report.ts
var DailyLiveReceivableDueSchema = objectType({
  createdAt: stringType().datetime().min(1, "created at is required"),
  customerId: stringType().uuid().min(1, "customer id is required"),
  dueAmount: numberType().min(1, "due amount is required"),
  firstName: stringType().min(1, "first name is required"),
  lastName: stringType().min(1, "last name is required")
});
var DailyLiveReceivablesReportSchema = objectType({
  totalReceivables: numberType().min(1, "total receivables is required"),
  totalReceivableCustomers: numberType().min(1, "total receivable customers is required"),
  dues: DailyLiveReceivableDueSchema.array()
});

// src/product-stock-calculate.ts
var import_mathjs5 = require("mathjs");
var getVariantStockQuantity = (variant) => {
  var _a;
  return (_a = variant.inventoryStock) == null ? void 0 : _a.reduce((acc, stock) => (0, import_mathjs5.chain)(acc).add(stock.quantity).done(), 0);
};
var getProductStockQuantity = (product) => {
  var _a;
  return (_a = product.variants) == null ? void 0 : _a.reduce((acc, variant) => {
    const variantQuantity = getVariantStockQuantity(variant);
    return (0, import_mathjs5.chain)(acc).add(variantQuantity).done();
  }, 0);
};
var checkIfServableProduct = (product) => product.productType.id === ProductTypeEnum.SERVABLE;

// src/product-variant-stock-status.ts
var stockStatusColors = {
  OUT_OF_STOCK: "error",
  LOW_STOCK: "warning",
  IN_STOCK: "success"
};
var productVariantStockStatus = (variant) => {
  var _a;
  const stock = ((_a = variant == null ? void 0 : variant.inventoryStock) == null ? void 0 : _a.length) ? variant.inventoryStock[0].quantity : 0;
  const isOutOfStock = stock === 0;
  const isStockLow = stock <= (variant == null ? void 0 : variant.lowStockThreshold);
  const isInStock = !isOutOfStock && !isStockLow;
  const statusConst = isOutOfStock ? "OUT_OF_STOCK" : isStockLow ? "LOW_STOCK" : "IN_STOCK";
  const statusName = statusConst.replace(/_/g, " ").toUpperCase();
  return { isOutOfStock, isStockLow, isInStock, statusName };
};

// src/time/get-type-orm-formatted-date-range.ts
var import_luxon = require("luxon");
var getTypeORMFormattedDateRange = (params) => {
  const { timezone, startDate, endDate, unit = "day" } = params;
  const time = startDate != null ? startDate : (/* @__PURE__ */ new Date()).toISOString();
  const startTime = import_luxon.DateTime.fromISO(time);
  const startTimeWithTimeZone = startTime.setZone(timezone);
  const startTimeStartOfTheDay = startTimeWithTimeZone.startOf(unit);
  const endTime = endDate ? import_luxon.DateTime.fromISO(endDate).setZone(timezone).endOf(unit) : startTimeStartOfTheDay.endOf(unit);
  const startTimeIso = startTimeStartOfTheDay.toISO();
  const endTimeIso = endTime.toISO();
  return {
    startTime: startTimeIso != null ? startTimeIso : void 0,
    endTime: endTimeIso != null ? endTimeIso : void 0
  };
};

// src/toTitleCase.ts
var toTitleCase = (str) => {
  return str.replace(/([A-Z])/g, " $1");
};

// src/uuidv4.ts
var uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    const r = Math.random() * 16 | 0, v = c === "x" ? r : r & 3 | 8;
    return v.toString(16);
  });
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  AUTH0_API,
  AWS_KEYS,
  CALC_CONST,
  CATEGORY_CARD_GRID_PROPS,
  COA_CARD_GRID_PROPS,
  COGNITO_API,
  COMPANIES_CARD_GRID_PROPS,
  COMPANY_INFO_CONST,
  CUSTOMER_DIALOG_CARD_GRID_PROPS,
  CUSTOMER_MANAGE_CARD_GRID_PROPS,
  DB_CONST,
  FIREBASE_API,
  HEADER,
  ICON,
  INDEX_SCROLL_COM_STYLE,
  INVENTORY_CARD_GRID_PROPS,
  INVENTORY_HISTORY_CARD_GRID_PROPS,
  MAP_API,
  NAV,
  NAV_CARD_GRID_PROPS,
  ORDER_HISTORY_CARD_GRID_PROPS,
  PATH_AFTER_LOGIN,
  PATH_AUTH,
  PATH_DASHBOARD,
  PATH_PAGE,
  PRODUCT_CARD_GRID_PROPS,
  SUPPLIER_CARD_GRID_PROPS,
  SUPPLIER_PRODUCT_MANAGE_CARD_GRID_PROPS,
  VARIANT_CARD_GRID_PROPS,
  asyncDelay,
  bgBlur,
  bgGradient,
  calculateOrderPrice,
  calculateProfitFromGrossPrice,
  calculateReferenceDue,
  calculateTotalDiscountPrice,
  checkIfServableProduct,
  convertToDBDecimal,
  convertToFEDecimal,
  convertToFEFormDecimal,
  convertVatToFEFormat,
  copyTextToClipboard,
  countryToFlag,
  createDashboardPath,
  createEmotionCache,
  ellipsis,
  fCurrency,
  fData,
  fDate,
  fDateTime,
  fNumber,
  fPercent,
  fShortenNumber,
  fTimestamp,
  fToNow,
  filterStyles,
  findCountryCode,
  findProductById,
  findVariantById,
  flattenArray,
  formatAndFixDecimal,
  generateAwsS3Url,
  generateCategoryTree,
  generateInvoiceUrl,
  generateRandomId,
  getAllProductVariantImages,
  getCardColor,
  getCookie,
  getFullCategoriesById,
  getFullImagesByIds,
  getIndexScrollComStyle,
  getProductStockQuantity,
  getTypeORMFormattedDateRange,
  getVariantStockQuantity,
  groupPermission,
  hideScrollbarX,
  hideScrollbarY,
  isFloat,
  isInt,
  isProductVariantStockNotAvailable,
  localStorageAvailable,
  localizeNumber,
  mapDataToSelect,
  mapPeopleDataToSelect,
  mapPeopleDataToSelectSingle,
  path,
  productVariantStockStatus,
  removeCookie,
  separateCategoryId,
  separateImageId,
  setCookie,
  stockStatusColors,
  textGradient,
  toTitleCase,
  transformCountryFE,
  uuidv4
});
