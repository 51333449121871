import { primaryPresets as presets } from '@panshilab/theme';
import { ThemeColorPresetsValue } from './types';

export const defaultPreset = presets[0];
export const darkBluePreset = presets[1];
export const cyanPreset = presets[2];
export const purplePreset = presets[3];
export const bluePreset = presets[4];
export const pinkPreset = presets[5];

export const presetsOption = presets.map(color => ({
  name: color.name,
  value: color.main,
}));

export function getPresets(key: ThemeColorPresetsValue) {
  return {
    default: defaultPreset,
    'dark-blue': darkBluePreset,
    cyan: cyanPreset,
    purple: purplePreset,
    blue: bluePreset,
    pink: pinkPreset,
  }[key];
}
