import { atom } from 'jotai';
import { ReactElement } from 'react';

export interface IContextMenuAtom<T> {
  id: string | number;
  onClick: (context: T) => void;
  icon: ReactElement;
  label: string;
  context: T;
  disabled?: boolean;
  color?: 'primary' | 'secondary' | 'error' | 'success' | 'warning' | 'info';
}

export const contextMenuAtom = atom<IContextMenuAtom<any>[] | null>(null);
