'use client';

import merge from 'lodash/merge';
import { ReactNode, useMemo } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider as MuiThemeProvider, ThemeOptions } from '@mui/material/styles';
import { shadows } from '../../../theme/src/shadows';
import { typography } from '../../../theme/src/typography';
import { customShadows } from '../../../theme/src/custom-shadows';
import { componentsOverrides } from '../../../theme/src/overrides';
import { presets } from '../../../theme/src/options/presets';
import { darkMode } from '../../../theme/src/options/dark-mode';
import { contrast } from '../../../theme/src/options/contrast';
import RTL, { direction } from '../../../theme/src/options/right-to-left';
import { useSettingsContext } from '../settings/SettingsContext';
import { palette } from '@panshilab/theme';
import { useLocales } from '../locales';

type Props = {
  children: ReactNode;
};

export function ThemeProvider({ children }: Props) {
  const { currentLang } = useLocales();
  const settings = useSettingsContext();
  const darkModeOption = darkMode(settings.themeMode);
  const presetsOption = presets(settings.themeColorPresets);
  const contrastOption = contrast(settings.themeContrast === 'bold', settings.themeMode);
  const directionOption = direction(settings.themeDirection);

  const baseOption = useMemo(
    () => ({
      palette: palette('light'),
      shadows: shadows('light'),
      customShadows: customShadows('light'),
      typography,
      shape: { borderRadius: 8 },
    }),
    [],
  );

  const memoizedValue = useMemo(
    () =>
      merge(
        // Base
        baseOption,
        // Direction: remove if not in use
        directionOption,
        // Dark mode: remove if not in use
        darkModeOption,
        // Presets: remove if not in use
        presetsOption,
        // Contrast: remove if not in use
        contrastOption.theme,
      ),
    [baseOption, directionOption, darkModeOption, presetsOption, contrastOption.theme],
  );

  const theme = createTheme(memoizedValue as ThemeOptions);

  theme.components = merge(componentsOverrides(theme), contrastOption.components);

  const themeWithLocale = useMemo(() => createTheme(theme, currentLang.systemValue), [currentLang.systemValue, theme]);

  return (
    <MuiThemeProvider theme={themeWithLocale}>
      <RTL themeDirection={settings.themeDirection}>
        <CssBaseline />
        {children}
      </RTL>
    </MuiThemeProvider>
  );
}
