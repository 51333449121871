import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { FC, ReactNode } from 'react';
import { useGetDevice } from '../../hooks/useResponsive';

export interface IContextDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  children: ReactNode;
}

export const ContextMenu: FC<IContextDialogProps> = props => {
  const { open, onClose, title, children } = props;
  const { isMobile } = useGetDevice();

  return (
    <Dialog open={open} onClose={onClose} maxWidth={isMobile ? 'xs' : 'sm'}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ minWidth: isMobile ? undefined : '500px', mb: 3.5 }}>
        <Grid container spacing={2}>
          {children}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
