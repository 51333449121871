import { alpha, IconButtonProps } from '@mui/material';
import { Theme } from '@mui/material/styles';

const getStyleObj = (theme: Theme, color: string, primaryTextColor?: boolean) => ({
  backgroundColor: `${alpha(color, 0.16)} !important`,
  borderRadius: theme.shape.borderRadius,
  color: !primaryTextColor ? color : theme.palette.primary.main,
  '&:hover': {
    backgroundColor: alpha(color, 0.16),
    color,
  },
});

export default function IconButton(theme: Theme) {
  return {
    MuiIconButton: {
      styleOverrides: {
        root: ({ ownerState }: { ownerState: IconButtonProps }) => {
          const color = ownerState?.color;

          if (color === 'default' || !color) {
            return {};
          }

          if (color === 'inherit') {
            return getStyleObj(theme, theme.palette.grey[600], true);
          }

          if (ownerState.disabled) {
            return getStyleObj(theme, theme.palette.grey[600]);
          }

          return getStyleObj(theme, theme.palette[color].main);
        },
      },
    },
  };
}
