import { ContextMenu } from '../menu';
import { FC, ReactElement } from 'react';
import { useContextMenuService } from '../service';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';

export interface IContextMenuProps {
  children: ReactElement;
}

export const ContextMenuProvider: FC<IContextMenuProps> = props => {
  const { children } = props;
  const { contextMenuConfig, closeContextMenu } = useContextMenuService();
  const open = !!contextMenuConfig;
  const { t } = useTranslation();

  return (
    <>
      {children}

      <ContextMenu open={open} onClose={closeContextMenu} title={t('dialogs.context_menu_title')}>
        {contextMenuConfig &&
          contextMenuConfig.map(config => {
            const { id, icon, onClick, context, ...rest } = config;

            return (
              <Grid key={id} xs={12} sm={6} item>
                <Button
                  sx={{ padding: '20px 0px' }}
                  variant="soft"
                  fullWidth
                  startIcon={<>{icon}</>}
                  onClick={() => onClick(context)}
                  {...rest}
                >
                  {config.label}
                </Button>
              </Grid>
            );
          })}
      </ContextMenu>
    </>
  );
};
