const loading_error = 'loading error, please try again';
const common_filter = {
  to: 'To',
  from: 'From',
  time_range: 'Time Range',
  title: 'Filter Panel',
  today: 'Today',
  yesterday: 'Yesterday',
  days_7: '7 days',
  days_30: '30 days',
  days_90: '90 days',
  days_180: '180 days',
  all: 'All',
};

export const en = {
  common: {
    cancel_btn_label: 'Cancel',
    clear_btn_label: 'Clear',
    close_btn_label: 'Close',
    reset_btn_label: 'Reset',
    save_btn_label: 'Save Changes',
    save: 'Save',
    select: 'Select',
    update: 'Update',
    add: 'Add',
    edit: 'Edit',
    details: 'details',
    new: 'New',
    change: 'Change',
  },
  pages: {
    accounting: {
      title: 'Chart of Accounts',
      accounting: 'Accounting',
    },
    inventory: {
      title: 'Inventory',
    },
    company_details: {
      settings: 'Settings',
      supplier: 'Supplier',
      reports: 'Reports',
      order: 'Order',
    },
    chart_of_accounts: {
      title: 'Chart of Accounts',
      asset: 'Asset',
      expense: 'Expense',
      liability: 'Liability',
      revenue: 'Revenue',
      drawing: 'Drawing',
      add_account: 'Add an account',
      account_type: 'Account Type',
      all: 'All',
      alert: {
        title: 'Error',
        text: 'Request Failed',
        strong_text: 'Refetch',
      },
    },
    register: {
      head_title: 'Transactions',
      title: 'Register',
      card: 'Card',
      table: 'Table',
      add_register: 'Add',
    },
    expense: {
      title: 'Payment/Expense',
      add_expense: 'Register an Expense',
    },
    drawing: {
      title: 'Drawing',
      add_drawing: 'Register a drawing',
    },
    cash_transfer: { title: 'Cash Transfer', add_transfer: 'Register Cash Transfer' },
    register_id: {
      title: 'Transactions',
      add_register: 'register a transaction',
    },
    sale: {
      daily_title: 'Daily Sales Report',
      monthly_title: 'Monthly Sales Report',
      today_live_report: "Today's Live report",
      store_dropdown_label: 'Store',
      daily: 'Daily',
      monthly: 'Monthly',
      yearly: 'Yearly',
    },
    purchase: {
      title: 'Purchase Report',
      business_year: 'Business Year',
      total_purchase: 'Total Purchase',
      purchase_payable: 'Purchase with payable',
      january: 'January',
      february: 'February',
      march: 'March',
      april: 'April',
      may: 'May',
      june: 'June',
      july: 'July',
      august: 'August',
      september: 'September',
      october: 'October',
      november: 'November',
      december: 'December',
    },
    receivable: {
      title: 'Receivable Report',
      business_year: 'Business Year',
      total_receivables: 'Total Receivables',
      total_receivables_customer: 'Total Receivables Customer',
      receivables_products: 'Receivables products',
      Maximum_Receivables: 'Maximum Receivables',
      showing_result: 'Showing result for:',
      today: 'Today',
    },
    counter: {
      title: 'Counter',
      to_the_cart: 'To the cart',
      checkout: 'Checkout',
      search_label: 'Search products',
      search_placeholder: 'Search products with',
      product_type_label: 'Product Type',
      categories: 'Categories',
      kitchen: 'Kitchen',
      sell: 'Sell',
      business: 'Business',
      store: 'Store',
      starting_selling: 'starting selling',
      add_to_cart: 'Add to cart',
      out_of_stock: 'Out of stock!',
    },
    counter_checkout: {
      title: 'Checkout',
      order_details: 'Order Details',
    },
    order_history: {
      title: 'Order History',
      search_label: 'Search Order',
      search_placeholder: 'Search Order ID',
      refresh: 'Refresh',
      filter: 'Filter',
      business: 'Business',
      store: 'Store',
      accessing_order_history_page: 'accessing the order history page',
    },
    return: {
      title: 'Return Order #{{order_id}}',
    },
    customers: {
      title: 'Customers',
      search_label: 'Search customers',
      search_placeholder: 'Search with name email or phone number',
      customer_dropdown_label: 'Reference Customer',
      customer_dropdown_no_options_text: 'No referable customer found',
      add_customer: 'Add',
      paid: 'Paid',
      return: 'Return',
      due: 'DUE',
      reference: 'REFERENCE',
      all: 'All',
    },

    products: {
      title: 'Products',
      search_label: 'Search products',
      search_placeholder: 'Search products with',
      categories: 'Categories',
      product_type_label: 'Product Type',
      filter_btn_label: 'Filter',
      add_product: 'Add Product',
      business: 'Business',
      store: 'Store',
      accessing_product_page: 'accessing the Products page',
    },
    inventory_history: {
      title: 'Inventory History',
      filter_btn_label: 'Filter',
    },
    inventory_overview: {
      title: 'Inventory Overview',
      select_inventory: 'Select inventory',
    },
    category_management: {
      title: 'Category Management',
      add: 'Add Category',
      toggle: 'Toggle expand',
      category: 'Category',
    },
    purchase_orders: {
      title: 'Supply Order',
      mobile_title: 'Purchase',
      to_the_cart: 'To the cart',
      checkout: 'Checkout',
      search_label: 'Search products',
      search_placeholder: 'Search products with',
      stock_status_label: 'Stock Status',
      categories: 'Categories',
    },
    purchase_checkout: {
      title: 'Checkout',
      purchase_details: 'Purchase Details',
    },
    supply_history: {
      title: 'Purchase Order History',
      search_label: 'Search Order',
      search_placeholder: 'Search Order ID',
      filter_btn_label: 'Filter',
      business: 'Business',
      store: 'Store',
      accessing_supply_history_page: 'accessing the supply history page',
    },
    suppliers: {
      title: 'Suppliers',
      add: 'Add',
      search_label: 'Search Supplier',
      search_placeholder: 'Company name',
    },
    preference: {
      title: 'Preference',
    },
    profile: {
      title: 'Profile details',
    },
    user: {
      title: 'Company Users',
      loading_error,
    },
    roles: {
      title: 'Roles Management',
      loading_error,
    },
    debug: {
      title: 'Debugging Information',
      access_token: 'Access Token',
      active_context: {
        title: 'Active context',
        company_id: 'Company Id',
        store_id: 'Store Id',
      },
      viewport_window: {
        title: 'Viewport Window',
        width: 'Width',
        height: 'Height',
      },
      profile_info: {
        title: 'Profile Information',
        tableTitle: 'Title',
        tableValue: 'Value',
        name: 'Name',
        email: 'Email',
        active: 'Is Active',
        type: 'User Type',
        country: 'Country',
        phone: 'Phone',
        city: 'City',
        gender: 'Gender',
      },
      sentry_debug: 'Sentry Debugging',
      sentry_btn_label: 'Add a sentry error',
    },
    companies: {
      title: 'your companies',
      loading_error,
      not_found: 'company not found',
      details_of_company: 'Details of {{company_name}}',
      alert: {
        title: 'Warning!',
        description: 'Multiple Companies! Select in context to edit or use',
        select_btn_label: 'Select Context',
      },
    },
    troubleshooting: {
      title: 'Troubleshooting',
    },
    context: {
      title: 'Context settings',
      heading_title: 'Select what you would like to manage',
      your_companies: 'Your Companies',
      stores: 'Stores',
      search_label: 'Search Companies',
      search_placeholder: 'Abc Company',
    },
    login: {
      title: 'Login',
      welcome_title: ' Welcome to Posinic!',
      description:
        '   Step into seamless transactions with Posinic. Log in now to experience the simplicity of our POS system. Thank you for choosing Posinic.',
      contact_us_label: "If you don't have an account please contact us",
      login_btn_label: 'Login with email',
      request_btn_label: 'Request an account for free',
    },
    logout: {
      title: 'Logout',
    },
  },
  components: {
    //Tr < LoadingScreen />
    loading_screen_label: 'creating order',

    // Tr: <SettingsDrawer />
    settings_drawer: {
      dark_light_mode: 'dark light mode',
      color_presets: 'color presets',
      language: 'language',
    },
    // Tr: <Companies />
    companies: {
      edit: 'edit',
      details: 'details',
      add_companies: 'add companies',
    },
    // Tr: <Stores />
    stores: {
      stores: 'Stores',
      edit: 'edit',
      details: 'details',
      add_store: 'add store',
    },
    // Tr: <Inventories />
    inventories: {
      inventories: 'Inventories',
      edit: 'edit',
      details: 'details',
    },
    // Tr: <User />
    user: {
      add_user: 'invite',
    },
    // Tr: <Roles />
    roles: {
      add_role: 'Add',
    },
    // Tr: <Cart />
    cart: {
      subtotal: 'Subtotal',
      total_vat: 'Total VAT',
      total_discount: 'Total Discount',
      grand_total: 'Grand Total',
      title: 'Cart',
      no_items_label: 'No items in cart',
      checkout: 'Checkout',
    },
    // Tr: <CartItem />
    cart_item: {
      remove: 'Remove',
      decrease: 'Decrease',
      increase: 'Increase',
      add_discount: 'Add discount',
    },
    // Tr: <DashboardNumberCard />
    inventory_overview: {
      pur_value_title: 'Inventory Product Purchase Value',
      min_sale_price_title: 'Inventory Minimum Sale Price',
      reg_sale_price_title: 'Inventory Total Regular Sale Price',
    },
    // Tr: <ToggleCardTableView />
    card_table_view: {
      card: 'Card',
      table: 'Table',
    },

    // Tr: <DailyReport />
    daily_report: {
      facts_and_charts: 'Facts and Charts',
      card_view: 'Card view',
      business_year: 'Business Year',
      select_month: 'Select Month',
    },

    // Tr: <FileUpload />
    file_upload: {
      btn_label: 'Upload images',
    },

    // Tr: <ProductDetails />
    product_details: {
      variants_select_label: 'Variants',
      disabled_variants_label: 'Hide disabled',
      categories: 'Categories',
      pur_price: 'Purchase Price',
      min_sale: 'Min sale Price',
      reg_sale: 'Reg sale Price',
      min_sale_mobile: 'Min sale',
      reg_sale_mobile: 'Reg sale',
      vat: 'VAT',
      attributes: 'Attributes',
    },

    // Tr: <CheckoutSidebar />
    supply_checkout_sidebar: {
      title: 'Purchase Order Summary',
      supply_status: 'Supply status',
      select_payment: 'Select payment account',
      complete: 'Complete',
      subtotal: 'Subtotal',
      total_vat: 'Total VAT',
      total_discount: 'Total Discount',
      grand_total: 'Grand Total',
    },

    // Tr: <VariantItem />
    variant_item: {
      pur_title: 'Purchase Price',
      min_title: 'Minimum Sell Price (margin)',
      reg_title: 'Regular Sell Price (margin)',
    },

    // Tr: <OrderDetailsPanelReceiver />
    order_details: {
      change_order_status: 'Change order status',
      no_transactions: 'No transactions',
      order_items: 'Order Items',
      transactions: 'Transactions',
      inventory_history: 'Inventory History',
    },
    // Tr: <OrderQueryStateHandler />
    order_query_State_handler: {
      error_alert_title: 'Error fetching order',
      warning_alert_title: 'Currently you can only return full order, contact us for any different cases',
      select_items: 'Select all items',
    },
    // Tr: <ReturnOrderItemRow />
    return_order_item: {
      unit_price: 'Unit Price',
      vat: 'VAT',
      net_price: 'Net Price',
      gross_price: 'Gross Price',
    },
    // Tr: <ReturnInformation />
    return_info: {
      title: 'Return information',
      return_reason_label: 'Return reason',
      refund_info: 'Refund info',
      submit_return: 'Submit Return',
    },

    // Tr: <OrderItem />
    order_item: {
      unit_price: 'Unit Price',
      vat: 'VAT',
      net_price: 'Net Price',
      gross_price: 'Gross Price',
      quantity: 'Quantity',
    },
    // Tr: <SupplyItem />
    supply_item: {
      purchase_price: 'Purchase price',
      quantity: 'Quantity',
    },
    // Tr: <SupplyDetailsPanelReceiver />
    supply_order_details: {
      no_transactions: 'No transactions',
      order_items: 'Order Items',
      transactions: 'Transactions',
    },

    // Tr: <GlobalMenu />
    global_menu: {
      navigation_menu: 'navigation Menu',
      toggle_light_Dark: 'Toggle Light/Dark',
      change_theme_color: 'Change theme color',
    },

    // Tr: <LoadingError />
    loading_error: {
      customer_entity: 'Customer',
      customer_due_entity: 'Customer Due',
      transactions_entity: 'Transactions',
      inventory_history_entity: 'Order Inventory History',
      reload_btn_label: 'Reload',
      reports_entity: 'Reports',
      overview_entity: 'Overview',
      products_entity: 'Products',
      coa_entity: 'Chart of Account',
      stores_entity: 'stores',
      supplier_entity: 'suppliers',
    },
  },
  cards: {
    not_found: {
      title: 'No {{entity}} Found',
      type_title: 'No {{entity}} {{type}}',
      customer_entity: 'Customer',
      customer_due_entity: 'Customer Due',
      transactions_entity: 'Transactions',
      products_entity: 'products',
      product_entity: 'product',
      selected_type: 'selected',
      companies_entity: 'companies',
      category_entity: 'Category',
      stores_entity: 'stores',
      reports_entity: 'Reports',
      inventory_history_entity: 'inventory history',
      company_entity: 'company',
      image_entity: 'image',
      variants_entity: 'variants',
      created_type: 'created',
      supply_history_entity: 'supply history',
      users_entity: 'users',
      inventory_entity: 'inventory',
      order_history_entity: 'order history',
      bank_account_entity: 'bank account',
      coa_entity: 'chart of account ',
      categories_entity: 'categories',
      supplier_entity: 'suppliers',
    },
    supplier: { edit: 'Edit', orders: 'Orders', pay_due: 'Pay Due', added_on: 'Added on ' },
    supply_product: {
      stock_count: 'Stock count',
      variants: 'Variants',
    },
    select_categories: {
      title: 'Select Categories',
    },
    category_select: { title: 'ROOT', description: 'Root level of all categories' },
    category_manage: {},
    report_feed: {
      title: "Today's Live Report",
      total_sale_vat: 'Total Sale Amount (VAT Inc.):',
      total_sale: 'Total Sale (without VAT):',
      inventory_purchase_price: 'Inventory Purchase Price:',
      net_profit: 'Net Profit',
      payment_methods: 'Payment Methods',
      cash: 'Cash',
      other_methods: 'Other methods',
      receivable: 'Receivable',
    },
    customer: {
      change_label: 'change',
      remove_label: 'remove',
      joined_on: 'Joined on',
      pay_due_payment: 'Pay Due Payment',
      edit_profile: 'Edit Profile',
      reference_details: 'Reference details',
      total_referrals: 'Total referrals',
      total_due_amount: 'Total due amount',
      purchase_history: 'Purchase History',
      due_orders: 'Due Orders',
      reference_label: 'reference',
    },
    customer_receivable: {
      joined_on: 'Joined on',
    },
    user: {
      joined_on: 'Joined on',
    },
    price: {
      pur_title: 'Purchase Price',
      min_title: 'Minimum Sell Price',
      reg_title: 'Regular Sell Price',
      vat_inclusive: '(VAT inclusive)',
    },
    order: {
      quantity: 'Quantity',
      total_price: 'Total Price',
      seller: 'Seller',
      user: 'User',
      created_at: 'Created at',
      order_status: 'Order Status',
      payment_method: 'Payment method',
      payment_status: 'Payment Status',
    },
    supply: {
      quantity: 'Quantity',
      total_price: 'Total Price',
      seller: 'Seller',
      user: 'User',
      created_at: 'Created at',
      supply_status: 'Supply Status',
      payment_status: 'Payment Status',
      payment_info: 'Payment Information',
    },
    order_payment_info: {
      title: 'Payment',
      select_amount_text: 'Please select amount and payment method to complete the payment.',
      full_btn_label: 'Full',
      wrong_amount: 'wrong amount',
      payment_complete: 'Payment is complete.',
    },
    customer_info: {
      title: 'Customer info',
      name: 'Name',
      email: 'Email',
      phone: 'Phone',
      address: 'Address',
      no_customer_selected: 'No customer selected',
    },
    order_information: {
      title: 'Order information ',
      net_price: ' Net price',
      gross_price: 'Gross price',
      vat: 'VAT',
      due: 'Due',
      show_invoice: ' Show Invoice',
    },
    order_transaction: {
      due: 'Due',
      refunded: 'Refunded',
      payment: 'Payment',
      balance: 'Balance',
      date: 'Date',
    },
    transaction_compact: {
      expense: 'Expense',
      liability: 'Liability',
      drawing: 'Drawing',
      payment: 'Payment',
      amount: 'Amount',
      date: 'Date',
      cash_transfer: 'Cash Transfer',
      created_by: 'Created By',
    },
    purchase_order_transaction: {
      due: 'Due',
      refunded: 'Refunded',
      payment: 'Payment',
      balance: 'Balance',
      date: 'Date',
    },
    //card and table title translation here
    transactions: {
      description: 'Description',
      expense: 'expense',
      order: 'order',
      purchase: 'purchase',
      liability: 'liability',
      transfer: 'transfer',
      drawing: 'drawing',
      invest: 'invest',
      due_payment: 'due payment',
      transaction_date: 'Transaction date',
      created_at: 'Created At',
      account: 'Account',
      debit: 'Debit',
      credit: 'Credit',
      balance: 'Balance',
      amount: 'Amount',
      edit: 'Edit',
    },
    chart_of_account: {
      default: 'default',
      asset: 'asset',
      liability: 'liability',
      revenue: 'revenue',
      expense: 'expense',
      drawing: 'drawing',
      edit_label: 'Edit Info',
      register_label: 'Register',
    },
    company: {},
    counter_product: {
      variants: 'Variants',
    },
    inventory_compact: {
      linked: 'Linked',
    },
    inventory_details: {
      associated_stores: 'Associated Stores',
    },
    store_details: {
      payment_method: 'Payment method',
    },
    variant: {
      pur_price: 'Pur. Price',
      min_price: 'Min. Price',
      reg_price: 'Reg. Price',
      attributes: 'Attributes',
      edit_info: 'Edit Info',
      duplicate: 'Duplicate',
      chip_label: 'Inactive',
    },
    supply_info: {
      title: 'Summery',
      pur_price: 'Purchase price',
    },
    supplier_info: {
      title: 'Supplier info',
      name: 'Name',
      email: 'Email',
      phone: 'Phone',
      address: 'Address',
      no_customer_selected: 'No customer selected',
    },
    purchase_order_payment_info: {
      title: 'Payment',
      select_payment_account_text: 'Please select amount and payment method to complete the payment.',
      full_btn_label: 'Full',
      pay_btn_label: 'Pay',
      wrong_amount: 'wrong amount',
      payment_complete: 'Payment is complete.',
    },
  },
  dialogs: {
    context_menu_title: 'Select an option',
    category_title: '{{title_mode}} Category Dialog',
    chart_of_account_title: '{{title_mode}} Chart of Account Dialog',
    customer_title: '{{title_mode}} Customer Dialog',
    inventory_title: '{{title_mode}} Inventory Dialog',
    store_title: '{{title_mode}} Store Dialog',
    transaction_title: '{{title_mode}} Transaction Dialog',
    user_title: '{{title_mode}} user Dialog',
    add_product_inventory_title: 'Add product to inventory Dialog',
    company: { title: '{{title_mode}} Company Dialog', upload_logo: 'Upload Logo' },
    variant_select: {
      title: 'Select {{title}} variant to order',
      alert_label:
        '  The product currently has no supplier assigned. Please select from the existing list or add a new supplier',
      add_supplier: 'add new supplier',
      suppliers_select_label: 'Supplier',
      no_supplier_label: 'No supplier',
      no_product_label: 'No product selected',
      select_supplier_title: 'Select supplier',
      select_supplier_text: 'In order to add product you need to select supplier first',
      add_purchase_order: 'Add To Purchase Order',
    },
    prices_adjust: {
      title: 'Adjust prices',
      input_error: 'input error',
    },
    add_discount: {
      title: 'Add Discount Per Unit',
      error_input_label: 'ERROR: please check your input',
      wrong_input_label: 'ERROR: wrong input, may crossing limit!',
      amount: 'Amount',
      percent: 'Percent',
      max_discount: 'Max. Discount',
      discount: 'Discount',
      final_price: 'Final Price',
      add_discount: 'Add Discount',
    },
    cash_payment: {
      title: 'Cash Payment Dialog',
      input_error_label: 'input error, please check your input',
      summery: 'Summery',
      select_customer: 'Select a customer',
      complete: 'Complete',
      paid: 'Paid',
      return: 'Return',
      remaining_due: 'Remaining as due',
      remaining_due_customer: 'Remaining as due (select a customer)',
      send_invoice_label: 'Send invoice to customer',
      send_invoice_customer_label: 'Select a customer to send invoice',
      order_discount: 'Total Order Discount',
      subtotal: 'Subtotal',
      total_vat: 'Total VAT',
      total_discount: 'Total Discount',
      grand_total: 'Grand Total',
      no_email: 'No email',
    },
    select_products: {
      title: 'Select Products Dialog',
      select_btn_label: 'Select Products',
    },
    role: {
      title: '{{title_mode}} Role Dialog',
      import_existing_role: 'import from existing role',
    },
    select_role: {
      title: 'import existing role config',
      click_import_label: 'click/tap on a role to import ',
      alert_label: 'User Loading error',
    },
    select_categories: {
      title: '{{title_mode}} Categories Dialog',
      add_btn_label: '{{title}} Categories',
      select_btn_label: 'Select Categories',
      alert_label: 'no category found',
    },
    select_customer: {
      title: 'Select Customer',
      search_label: 'Search Customer',
      search_placeholder: 'Search with name email or phone number',
      add_customer: '{{text}} Customer',
    },
    product: {
      title: '{{title_mode}} a Product Dialog',
      categories_step_label: 'Category selection',
      variants_step_label: 'Variants Information',
      product_step_label: 'Product Information',
      summery_step_label: 'Summery',
      save_btn_label: 'Save {{title_mode}} Product',
    },
    variant: {
      title: '{{title_mode}} product variant Dialog',
      add_btn_label: '{{title_mode}} variant',
      add_barcode: 'Add Barcode',
      scan_btn_label: 'Scan',
    },
    supplier: {
      title: '{{title_mode}} a Supplier Dialog',
      supplier_products_label: 'Supplier Products',
      company_step_label: 'Information',
      product_step_label: 'Products',
      summery_step_label: 'Summery',
      add_product_btn_label: '{{title_mode}}  Product',
      save_btn_label: 'Save Supplier',
      contact_information: 'Contact Information',
      products: 'Products',
    },
    register_expense: {
      title: 'Register your expense',
      save_btn_label: 'Save Transaction',
      transaction_info: 'Transaction Information',
      expense: 'Expense',
      liability: 'Liability',
      amount: 'Amount',
      input_error_label: 'input error, please check your input',
      'account-helper-text': 'This is the account you are paying for',
      'step-1-instruction': 'Select what kind of expense you want to log',
      'step-2-instruction': 'Select cash account',
    },
    register_drawing: {
      title: 'Register Your Drawing',
      save_btn_label: 'Save Transaction',
      transaction_info: 'Transaction Information',
      input_error_label: 'input error, please check your input',
      drawing: 'Drawing',
      amount: 'Amount',
      'account-helper-text': 'This is the account you are paying for',
      'step-1-instruction': 'Select your withdraw account',
      'step-2-instruction': 'Select cash account',
    },
    cash_transfer: {
      title: 'Register Cash Transfer',
      amount: 'Amount',
      input_error_label: 'input error, please check your input',
      amount_error_label: 'input amount more than the {{coa_name}}',
      balance_zero_error_label: '{{coa_name}} account balance zero',
      save_btn_label: 'Save Transaction',
      no_balance: 'no balance',
    },
    update_number: {
      input_error_label: 'input error, please check your input',
      quantity_error_label: 'selected number is more than the stock quantity',
    },
    invoice_details: {
      title: 'Invoice details',
      purchase_date: 'Purchase Date',
      order: 'Order',
      payment_status: 'Payment Status',
      order_status: 'Order Status',
      unit_price: 'Unit price',
      table_cell_title: 'Title',
      table_cell_unit_price: 'Unit Price',
      table_cell_vat: 'Vat',
      table_cell_discount: 'Discount',
      table_cell_quantity: 'Quantity',
      table_cell_price: 'Price,',
      footer_title: 'NOTES',
      footer_description: 'This invoice is generated with',
      footer_description_link: 'Posinic POS System',
      have_question: 'Have a Question?',
      paid: 'Paid',
      due: 'Due',
      subtotal: 'Subtotal',
      total_vat: 'Total VAT',
      total_discount: 'Total Discount',
      grand_total: 'Grand Total',
    },
    invoice_share_details: {
      title: 'Share',
      send_email: 'Send Email',
      send_invoice_title: 'Send order invoice',
      email_label: 'Email Address',
      whatsapp: 'Whatsapp',
      email: 'Email',
    },
  },
  forms: {
    // forms react components and validation schemas both would be here
    email_submit: {
      email_label: 'Login with your email',
      no_account_found: 'No account found!',
    },
    otp_submit: {
      request_new_code: 'Request new code',
      try_another_email: ' Try another email',
    },
    transaction: {
      debit_account_label: 'Debit account',
      credit_account_label: 'Credit Account',
      description_label: 'Description',
      description_placeholder: 'this account is under SCB Kualampur. Mr John Doe is the account manager.',
      amount_label: 'Balance',
      amount_placeholder: '100.00',
      transaction_date_label: 'Transaction Date',
    },
    address_fields: {
      road_label: 'Road',
      road_placeholder: 'Reginhardstraße',
      house_number_label: 'House Number',
      house_number_placeholder: '65',
      zip_code_label: 'Zip Code',
      zip_code_placeholder: '13409',
      city_label: 'City',
      city_placeholder: 'Berlin',
      country_label: 'Country',
    },
    bar_code: {
      label: 'Barcode',
    },
    category: {
      title_label: 'Category Name',
      title_placeholder: 'T-Shirt',
      description_label: 'Description',
      description_placeholder: 'Category details',
    },
    chart_of_account: {
      account_name_label: 'Account Name',
      account_name_placeholder: 'SCB Kualampur #48270',
      account_type_label: 'Account Type',
      account_type_placeholder: 'Asset',
      description_label: 'Description',
      description_placeholder: 'This account is under SCB Kualampur. Mr John Doe is the account manager.',
      active_label: 'Do you want to activate the Account?',
      payment_possible_label: 'Do you want to use this account for payments?',
      cash_account_label: 'Do you want to use this account for a cash account?',
    },
    expanse_transaction: {
      select_account_label: 'Account you want to pay from',
      description_label: 'Description',
      description_placeholder: 'this account is under SCB Kualampur. Mr John Doe is the account manager.',
      balance_zero_error_label: '{{coa_name}} account balance zero',
      no_balance: 'no balance',
    },
    drawing_transaction: {
      select_account_label: 'Account you want to pay from',
      description_label: 'Description',
      description_placeholder: 'this account is under SCB Kualampur. Mr John Doe is the account manager.',
      balance_zero_error_label: '{{coa_name}} account balance zero',
      no_balance: 'no balance',
    },
    company: {
      title_label: 'Company Name',
      title_placeholder: 'Panshilab',
      currency_label: 'Currency',
    },
    customer: {
      first_name_label: 'First Name',
      first_name_placeholder: 'John',
      last_name_label: 'Last Name',
      last_name_placeholder: 'Doe',
      email_label: 'Email',
      email_placeholder: 'example@domain.com',
      phone_number_label: 'Phone Number',
      phone_number_placeholder: '01740837450',
      reference_label: 'Customer Reference',
      reference_id: 'Reference ID',
      is_referable_label: 'Use for reference?',
      is_referable_helperText: 'You can only remove the reference capability if not in use',
      reference_dropdown_no_options_text: 'No customer found',
      description_label: 'Description',
      description_placeholder: 'Customer description here',
    },
    fieldset: {
      user_info_title: 'Personal information',
      assignments_and_permissions_title: 'Assignments and permissions',
      address_info_title: 'Address',
      chart_of_account_title: 'Chart of Account Details',
      company_title: 'Company Info',
      customer_title: 'Personal Information',
      permissions_title: 'Permissions',
      role_title: 'Role info',
      store_title: 'Store Info',
      supplier_title: 'Company Information',
      core_info_title: 'Core Information',
      attributes_info_title: 'Attributes',
      images_info_title: 'Images',
      barcode_info_title: 'Barcodes',
    },
    inventory: {
      title_label: 'Inventory Name',
      title_placeholder: 'Berlin Inventory',
    },
    owner_details: {
      first_name_label: 'First Name',
      first_name_placeholder: 'John',
      last_name_label: 'Last Name',
      last_name_placeholder: 'Doe',
      phone_number_label: 'Phone Number',
      phone_number_placeholder: '01740837450',
      city_label: 'City',
      city_placeholder: 'Berlin',
      country_label: 'Country',
    },
    payment_method: {
      label: 'Method',
      add_btn_label: 'Add Method',
    },
    product: {
      title_label: 'Title',
      title_placeholder: 'Product Name',
      unit_label: 'Unit',
      vat_label: 'VAT',
      vat_placeholder: '10',
      product_type_label: 'Select product type',
      description_label: 'Description',
      description_placeholder: 'Product description here',
    },
    roles: {
      title_label: 'Title',
      title_placeholder: 'Manager',
      description_label: 'Description',
      description_placeholder: 'Role description here',
    },
    store: {
      title_label: 'Store Name',
      title_placeholder: 'Berlin Store',
    },
    supplier: {
      title_label: 'Company Name',
      title_placeholder: 'Panshilab',
      email_label: 'Email',
      email_placeholder: 'example@domain.com',
      phone_number_label: 'Phone Number',
      phone_number_placeholder: '01740837450',
    },
    user: {
      first_name_label: 'First Name',
      first_name_placeholder: 'John',
      last_name_label: 'Last Name',
      last_name_placeholder: 'Doe',
      email_label: 'Email',
      email_placeholder: 'example@domain.com',
      phone_number_label: 'Phone Number',
      phone_number_placeholder: '01740837450',
      role_label: 'Role',
      assigned_stores_label: 'Assigned Stores',
      assigned_inventories_label: 'Assigned Inventories',
      staff_btn_label: 'STAFF',
      partner_btn_label: 'PARTNER',
    },
    variant: {
      title_label: 'Title',
      title_placeholder: 'ex: iPhone 15 128 GB',
      stock_threshold_label: 'Low Stock Threshold',
      stock_threshold_placeholder: '10',
      ser_sale_price_label: 'Preparation Cost',
      pur_sale_price_label: 'Purchase Price',
      pur_sale_price_placeholder: 'Purchase Price',
      min_sale_price_label: 'Minimum Sale Price',
      min_sale_price_placeholder: 'Minimum Sale Price',
      reg_sale_price_label: 'Regular Sale Price',
      reg_sale_price_placeholder: 'Regular Sale Price',
      background_mode_label: 'Background Mode',
    },
    attribute: {
      title_label: 'Title',
      title_placeholder: 'Title',
      title_helper_text: 'Attribute title is required',
      title_exist_helper_text: 'Already exist',
      value_label: 'Value',
      value_placeholder: 'Value',
      value_helper_text: 'Attribute value is required',
    },
  },
  filter: {
    sale_reports: {
      ...common_filter,
      text: 'Select Filters to view specific sales report. Click on the reset button to set the default filters.',
      business_year: 'Business Year',
    },
    receivable_reports: {
      ...common_filter,
      text: 'Select Filters to view specific due reports. Click on the reset button to set the default filters.',
    },
    order_history: {
      ...common_filter,
      text: 'Select Filters to view specific order history. Click on the clear button to clear all filters.',
      order_status: 'Order status',
      payment_status: 'Payment status',
      seller: 'Seller',
      customer: 'Customer',
    },
    products: {
      ...common_filter,
      text: 'Select Filters to view specific inventory Products. Click on the clear button to clear all filters.',
      inventory: 'Inventory',
      stock_status: 'Stock status',
    },
    inventory_history: {
      ...common_filter,
      text: 'Select Filters to view specific inventory history. Click on the clear button to clear all filters.',
      reason: 'Reason',
      inventory: 'Inventory',
    },
    supply_history: {
      ...common_filter,
      text: 'Select Filters to view specific purchase Order history. Click on the clear button to clear all filters.',
      supply_status: 'Supply Status',
      payment_status: 'Payment Status',
      receiver: 'Receiver',
      supplier: 'Supplier',
    },
  },
  snackbar: {
    token_copy: 'The token has been copied to your clipboard.',
    invoice_link_copy: 'Invoice link copy successful.',
    discount_applied_successfully: 'Discount applied Successfully!',
  },
  navigation: {
    dashboard: 'Dashboard',
    chart_of_accounts: 'Chart of Accounts',
    register: 'Register',
    expense: 'Payment / Expense',
    drawing: 'Drawing',
    cash_transfer: 'Cash Transfer',
    transactions: 'Transactions',
    sale: 'Sale',
    daily: 'Daily',
    monthly: 'Monthly',
    yearly: 'Yearly',
    receivable: 'Receivable',
    profit_and_loss: 'Profit And Loss',
    purchase: 'Purchase',
    customer_due: 'Customer Due',
    payable: 'Payable',
    return: 'Return',
    history: 'History',
    invoice: 'Invoice',
    customers: 'Customers',
    company: 'Company',
    user: 'User',
    profile: 'Profile',
    roles: 'Roles',
    preference: 'Preference',
    debug: 'Debug',
    context: 'Context',
    troubleshooting: 'Troubleshooting',
    logout: 'Logout',
    purchase_order: 'purchase order',
    products: 'products',
    category_management: 'category management',
    overview: 'overview',
    checkout: 'checkout',
    suppliers: 'suppliers',
    settings: 'settings',
    accounting: 'accounting',
    reports: 'reports',
    counter: 'counter',
    order: 'order',
    inventory: 'inventory',
    supply: 'supply',
    supplier: 'supplier',
    operations: 'operations',
    companies: 'companies',
    customer: 'customer',
    loading_error: 'loading error',
    reload_app: 'Reload App',
    reset_app: 'Reset App',
    menu: 'Menu',
  },
  paymentMethods: {
    card: 'Card',
    cash: 'Cash',
    receivable: 'Receivable',
  },
  user: `user`,
  companies: `companies`,
  profile: `profile`,
  preference: 'theme preference',
  debug: `debug`,
  context: `context`,
  lock: `Lock App`,
  settings: `settings`,
  roles: `roles`,
  error: 'error',
  checkout: {
    add_order_discount: 'Add Order Discount',
    complete: 'Complete',
    grand_total: 'Grand Total',
    order_discount: 'Order Discount',
    pay: 'Pay',
    reset_order_discount: 'Reset Discount',
    select_customer: 'Select Customer',
    send_kitchen: 'Send to the kitchen',
    subtotal: 'Subtotal',
    title: 'Order Summery',
    total_discount: 'Total discount (VAT Inc.)',
    total_vat: 'Total VAT',
    update_order_discount: 'Update Order Discount',
    payment_methods: {
      receivable_warning: 'Select a customer to make receivable payment',
      card: 'Card',
      cash: 'Cash',
      receivable: 'Receivable',
    },
  },
  tooltip: {
    dialog_disabled_step_next_btn_title: 'Please complete the first steps',
    download_title: 'Download',
    share_title: 'Share',
    show_hide_category_title: 'Show/hide child of this category',
    edit_category_title: 'Edit of this category',
    move_category_title: 'Move under another parent category',
    copy_category_title: 'Copy this category',
    add_child_category_title: 'Add child of this category',
  },
  alert: {
    handle_no_stock: {
      title: 'Stock Unavailable!',
      description: 'No more items are available in stock',
    },
    dialog_incomplete_step: {
      title: 'Incomplete form!',
      description: 'This step is not accessible',
    },
    not_editable_role: {
      success_btn_text: 'I understand',
      title: 'Not editable',
      description: 'Default roles are not editable, you can create new roles to customize as your own',
    },
    multiple_companies: {
      title: 'Warning!',
      description: 'Multiple Companies! Select in context to edit or use',
      select_btn_label: 'Select Context',
    },
    successfully_logout: {
      title: 'You have successfully logged out',
      description: 'Login again to continue working on awesome business things!',
      login_btn_label: 'Go To Login',
    },
    otp_failed: {
      title: 'OTP Failed!',
      description: 'Please recheck your OTP code.',
    },
    otp_success: {
      title: 'Check email for a login code',
      description: "If you don't receive an email, please check your spam folder. Also you can request a new code",
    },
    handle_set_context: {
      title: 'You need to select a {{title}}  before {{description}}',
      description: 'do you want to select a {{title}}?',
      select_btn_label: 'Select {{title}}',
    },
    partially_returned: {
      title: 'Warning!',
      description: 'Partially returned order can not be returned again',
      confirm_btn_text: 'I understand',
    },
    already_returned: {
      title: 'Warning!',
      description: 'Already returned order can not be returned again',
      confirm_btn_text: 'I understand',
    },
    partially_paid: {
      title: 'Warning!',
      description: 'Partially paid order can not be returned at this moment. Please contact support to get help',
      confirm_btn_text: 'I understand',
    },
  },
  confirm: {
    troubleshooting: {
      title: 'Are you sure?',
      description: 'You will lose all your unsaved data!',
    },
    debug: {
      title: 'Confirm?',
      description: 'Are you sure you want to throw an error for Sentry check?',
    },
    add_category_management: {
      title: 'Move Products To The New Category?',
      description:
        'The current parent category has products assigned. This action will move existing products of the parent category under the newly created category.',
    },
    variants: {
      title: 'Are you sure to copy?',
      description: 'Are you sure you want to copy the current variant?',
    },
    order_success: {
      title: 'Successful!',
      description: 'Purchase Order creation successful',
      confirm_btn_text: 'To the counter',
      deny_btn_text: 'Show Invoice',
    },
    order_error: {
      title: 'Something wrong',
      description: 'Order creation failed!',
      confirm_btn_text: 'Ok, Try Again',
      deny_btn_text: 'Show Invoice',
    },
    order_timeout: {
      title: 'Timed out!',
      description:
        'Order creation timeout, the order might be created successfully but due to network interruption it is not confirmed, please check in order history before creating a new order.',
      confirm_btn_text: 'To Order History',
    },
    send_to_kitchen: {
      success_title: 'Successful!',
      error_title: 'Something wrong',
      success_description: 'Sent to the kitchen',
      error_description: 'Failed to send to the kitchen',
      success_btn_text: 'To the counter',
      error_btn_text: 'Ok, Try Again',
    },
    submit_due_payment: {
      title: 'Make due payment?',
      description: 'Are you sure you want to add due payment for the user {{name}}?',
    },
    submit_return: {
      title: 'Are you sure?',
      description: 'Are you sure you want to return those item?',
    },
    mark_as_completed: {
      title: 'Mark as Completed?',
      description: 'Are you sure you want to mark this order as Completed?',
    },
    mark_as_pending: {
      title: 'Mark as Pending?',
      description: 'Are you sure you want to mark this order as Pending?',
    },
    mark_as_refunded: {
      title: 'Mark as Refunded?',
      description: 'Are you sure you want to mark this order as Refunded?',
    },
    mark_as_cancelled: {
      title: 'Mark as Cancelled?',
      description: 'Are you sure you want to mark this order as Cancelled?',
    },
    mark_as_pending_delivery: {
      title: 'Mark as Pending Delivery?',
      description: 'Are you sure you want to mark this order as Pending Delivery?',
    },
    mark_as_delivery_in_progress: {
      title: 'Mark as Delivery In Progress?',
      description: 'Are you sure you want to mark this order as Delivery In Progress?',
    },
    mark_as_delivered: {
      title: 'Mark as Delivered?',
      description: 'Are you sure you want to mark this order as Delivered?',
    },
    process_payment_handler: { title: 'Make payment?', description: 'Are you sure you want to make payment?' },
    delete_order_item: { title: 'Delete item?', description: 'Are you sure you want to delete the order item?' },
    logout_alert: {
      title: 'Logout Confirmation?',
      description: 'Are you sure you want to log out?',
    },
  },
  swal: {
    agree: 'Yes, I agree',
    cancel: 'Cancel',
  },
};

export type TEnLocale = typeof en;
