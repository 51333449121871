import { FC, ReactNode } from 'react';
import { ThemeColorPresets } from './ThemeColorPresets';
import { ThemeContrast } from './ThemeContrast';
import { ThemeRtlLayout } from './ThemeRtlLayout';

type Props = {
  children: ReactNode;
};

export const ThemeSettings: FC<Props> = ({ children }) => {
  return (
    <ThemeColorPresets>
      <ThemeContrast>
        <ThemeRtlLayout>{children}</ThemeRtlLayout>
      </ThemeContrast>
    </ThemeColorPresets>
  );
};
