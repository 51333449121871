import { CacheProvider, EmotionCache } from '@emotion/react';
import NoSsr from '@mui/material/NoSsr';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  ContextMenuProvider,
  MotionLazyContainer,
  ProgressBar,
  SettingsProvider,
  SnackbarProvider,
  ThemeLocalization,
  ThemeProvider,
  ThemeSettings,
} from '@panshilab/components';
import i18n from '@panshilab/components/src/locales/i18n';
import { NextPageWithLayout } from '@utils/next-page-with-layout';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import createEmotionCache from '@utils/createEmotionCache';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { FC, useEffect } from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-quill/dist/quill.snow.css';
import 'simplebar-react/dist/simplebar.min.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import 'yet-another-react-lightbox/styles.css';
import './global.css';
import { useRouter } from 'next/router';

const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
  Component: NextPageWithLayout;
}

const client = new QueryClient();
const MyApp: FC<MyAppProps> = props => {
  const router = useRouter();
  const { Component, pageProps, emotionCache = clientSideEmotionCache } = props;
  const getLayout = Component.getLayout ?? (page => page);

  useEffect(() => {
    const lang = localStorage.getItem('i18nextLng');
    i18n.changeLanguage(lang || 'en');
  }, [router]);

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>

      <QueryClientProvider client={client}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SettingsProvider>
            <MotionLazyContainer>
              <ThemeProvider>
                <ThemeSettings>
                  <ThemeLocalization>
                    <SnackbarProvider>
                      <ContextMenuProvider>
                        <NoSsr>
                          <ProgressBar />
                          {getLayout(<Component {...pageProps} />)}
                        </NoSsr>
                      </ContextMenuProvider>
                    </SnackbarProvider>
                  </ThemeLocalization>
                </ThemeSettings>
              </ThemeProvider>
            </MotionLazyContainer>
          </SettingsProvider>
        </LocalizationProvider>

        <ReactQueryDevtools />
      </QueryClientProvider>
    </CacheProvider>
  );
};

export default MyApp;
