import { alpha } from '@mui/material/styles';
import { palette as themePalette } from '../palette';

export function presets(presetsColor: string) {
  const primary = primaryPresets.find(i => i.name === presetsColor);

  const theme = {
    palette: {
      primary,
    },
    customShadows: {
      primary: `0 8px 16px 0 ${alpha(`${primary?.main}`, 0.24)}`,
    },
  };

  return theme;
}

const palette = themePalette('light');

export const primaryPresets = [
  // DEFAULT
  {
    name: 'default',
    ...palette.primary,
  },
  // dark blue
  {
    name: 'dark-blue',
    lighter: '#b9c2e8',
    light: '#99a2c5',
    main: '#7e88ba',
    dark: '#616a98',
    darker: '#414868',
    contrastText: '#FFFFFF',
  },
  // CYAN
  {
    name: 'cyan',
    lighter: '#CCF4FE',
    light: '#68CDF9',
    main: '#078DEE',
    dark: '#0351AB',
    darker: '#012972',
    contrastText: '#FFFFFF',
  },
  // PURPLE
  {
    name: 'purple',
    lighter: '#EBD6FD',
    light: '#B985F4',
    main: '#7635dc',
    dark: '#431A9E',
    darker: '#200A69',
    contrastText: '#FFFFFF',
  },
  // BLUE
  {
    name: 'blue',
    lighter: '#D1E9FC',
    light: '#76B0F1',
    main: '#2065D1',
    dark: '#103996',
    darker: '#061B64',
    contrastText: '#FFFFFF',
  },
  {
    name: 'pink',
    lighter: '#FFE5EC',
    light: '#FFC2D1',
    main: '#ff8fab',
    dark: '#FB6F92',
    darker: '#e06180',
    contrastText: '#FFFFFF',
  },
];
